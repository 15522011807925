import { Component } from '@angular/core';

@Component({
    selector: 'app-parent-assistants',
    templateUrl: './parent-assistants.component.html',
    styleUrl: './parent-assistants.component.scss'
})
export class ParentAssistantsComponent {

}
