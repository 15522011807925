import { Component, OnDestroy, OnInit } from '@angular/core';
import { Clipboard } from "@angular/cdk/clipboard";
import { Subscription } from 'rxjs';

import { DesignModel } from "../../../core/models/misc/design.model";
import { DialogService } from "../../../core/services/dialog.service";
import { ConfigService } from "../../../core/services/config.service";
import { AssistantService } from "../../../core/services/assistant.service";

@Component({
    selector: 'app-designs-modal',
    templateUrl: './designs-modal.component.html',
    styleUrl: './designs-modal.component.scss'
})
export class DesignsModalComponent implements OnInit, OnDestroy {
    dialogTitle = 'Explore Different Types of Assistant ';
    dialogDescription = 'Go to link and try the assistant type that fits your needs.';
    goToLinkButton = 'Go to link';

    designs: DesignModel[] = [];

    isLinkCopied = [false, false, false, false];

    assistantId: string = '';
    assistantName: string = '';

    designsSubscription!: Subscription;

    constructor(
        private dialog: DialogService,
        private clipboard: Clipboard,
        private config: ConfigService,
        private assistant: AssistantService
    ) { }

    ngOnInit(): void {
        this.assistant.getSelectedAssistantResponse().subscribe(async assistant => {
            if (assistant != null && assistant.id) {
                this.assistantId = assistant.id;
                this.assistantName = assistant.name;
            }
        });

        this.designsSubscription = this.config.getAssistantDesigns(this.assistantId).subscribe(designs => {
            this.designs = designs
        });

        this.dialogTitle = 'Explore Designs of ' + this.assistantName;
    }

    copyTextMessage(content: string, index: number) {
        this.clipboard.copy(content);
        this.isLinkCopied[index] = true;

        setTimeout(() => { this.isLinkCopied[index] = false; }, 2000);
    }

    goToLink(link: string) {
        window.open(link, '_blank');
    }

    closeDialog() {
        this.dialog.close();
    }

    ngOnDestroy(): void {
        if (this.designsSubscription) { this.designsSubscription.unsubscribe(); }
    }
}
