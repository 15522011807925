import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Knowledge } from "../../models/knowledge.model";
import { DUMMY_ASSISTANTS } from "./dummy-datas/assistants";
import { DUMMY_KNOWLEDGES } from "./dummy-datas/knowledges";
import { Entity, Relation } from "../../models/triplet.component";
import { DUMMY_ENTITIES, DUMMY_RELATIONS } from "./dummy-datas/triplets";
import { DUMMY_ACTIONS } from "./dummy-datas/actions";
import {AgentModel} from "../../models/agent/agent.model";
import { ActionModel } from "../../models/action/action.model";

// Testing app without server support
// Intercepts HTTP requests and returns a predefined (mocked) response
@Injectable()
export class MockInterceptor implements HttpInterceptor {
    private assistants: AgentModel[] = DUMMY_ASSISTANTS;
    private knowledges: Knowledge[] = DUMMY_KNOWLEDGES;
    private relationships: Relation[] = DUMMY_RELATIONS;
    private entities: Entity[] = DUMMY_ENTITIES;
    private actions: ActionModel[] = DUMMY_ACTIONS;

    private assistant: AgentModel = {
        id: '2569874589',
        name: 'Pera Asistent',
        // role: 'Helper',
        knowledgeScope: 'Controlled',
        similarityThreshold: 75,
        communicationStyle: 'Informal',
        behavior: 'Creative',
        answerLength: 100,
        voices: [],
        shortcuts: [],
        languages: [],
        knowledge: [],
        actions: []
        // selectedLanguagesOrder: [{ name: 'English', locale: 'en-US', voice: 'Male' }, { name: 'German', locale: 'de-DE', voice: 'Female' }],
        // shortcuts: {
        //     first: 'Shortcut 1',
        //     second: 'Shortcut 2',
        //     third: ' Shortcut 3',
        //     fourth: 'Shortcut 4'
        // }
    }

    private knowledge: Knowledge = {
        id: 'id-knowledge-pera',
        name: 'Elephant Knowledge',
        knowledgeType: 'Text'
    }

    private action: ActionModel = {
        id: 'id-action-pera',
        name: 'Elephant Action',
        description: 'Elephant Action Description',
        statusCheck: true,
        actionUrl: 'https://example.com/action',
        actionMethod: 'POST',
        headers: [
            { id: 'header-1', headerKey: 'Content-Type', headerValue: 'application/json' },
            { id: 'header-2', headerKey: 'Authorization', headerValue: 'Bearer' }
        ],
        parameters: [
            { id: 'parameter-1', name: 'param1', description: 'param1 description', parameterType: 'String', parameterValues: [{ parameterValue: 'value1' }, { parameterValue: 'value2' }] },
            { id: 'parameter-2', name: 'param2', description: 'param2 description', parameterType: 'Number', parameterValues: [{ parameterValue: '1' }, { parameterValue: 'value2' }] }
        ]
    }

    constructor() { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        /*  AUTH ENDPOINTS  */

        // Intercept login request
        /*if (req.url.includes('/auth/login')) {
             const username = req.body.get('username');
             const password = req.body.get('password');

             if (username === 'milica' && password === '123321') {
                 const mockResponse = new HttpResponse({
                     status: 200,
                     body: {
                         token: 'dummy-token-12345'
                     }
                 });

                 return of(mockResponse).pipe(delay(2000));
             } else {
                 const mockResponse = new HttpResponse({
                     status: 404
                 });

                 return of(mockResponse).pipe(delay(2000));
             }

            return next.handle(req);
        }

        // Intercept forgot password request
        if (req.url.includes('/auth/forgot-password')) {
            const email = req.body.get('email');

            if (email === 'milica@gmail.com') {
                const mockResponse = new HttpResponse({
                    status: 200,
                    body: { message: 'Password reset link sent successfully.' }
                });

                return of(mockResponse).pipe(delay(2000));
            } else {
                const mockResponse = new HttpResponse({
                    status: 404,
                    statusText: 'Not Found'
                });

                return of(mockResponse).pipe(delay(2000));
            }
        }*/

        /*  ASSISTANT ENDPOINTS  */
        /*if (req.url.includes('/agent/all')) {
            // Vraćamo 403 Forbidden status
            const mockErrorResponse = new HttpResponse({
                status: 403,
                statusText: 'Forbidden',
                body: { message: 'Access denied' } // Ovdje možete dodati dodatne informacije
            });

            return of(mockErrorResponse).pipe(delay(1000)); // Dodajte kašnjenje ako želite
        }*/

        // Intercept assistant creation request
        if (req.url.includes('/assistant/create')) {
            // Request assistant
            const newAssistant: AgentModel = req.body;

            // Dummy assistant created
            this.assistants.push(this.assistant);

            const mockResponse = new HttpResponse({
                status: 200,
                body: {
                    message: 'Assistant successfully created.',
                    designs: [
                        { id: 'design-1', link: 'https://example.com/design1' },
                        { id: 'design-2', link: 'https://example.com/design2' },
                        { id: 'design-3', link: 'https://example.com/design3' },
                        { id: 'design-4', link: 'https://example.com/design4' }
                    ],
                    assistant: this.assistant
                }
            });

            return of(mockResponse).pipe(delay(3000));
        }

        if (req.url.includes('/assistant/get/all')) {
            // AssistantDTO list with just id and name of assistant

            const simplifiedAssistants = this.assistants.map(assistant => ({
                id: assistant.id,
                name: assistant.name
            }));

            const mockResponse = new HttpResponse({
                status: 200,
                body: simplifiedAssistants
            });

            return of(mockResponse).pipe(delay(2000));
        }

        const matchGetAssistant = req.url.match(/\/assistant\/(\d+)\/get/);
        if (matchGetAssistant) {
            const id = matchGetAssistant[1];
            const assistant = this.assistants.find(assistant => assistant.id === id);

            const mockResponse = new HttpResponse({
                status: 200,
                body: assistant
            });

            return of(mockResponse).pipe(delay(2000));
        }

        const matchUpdateAssistant = req.url.match(/\/assistant\/(\d+)\/update/);

        // Simulation getAssistant endpoint, its can update just Assistant 1 with id 5241
        if (matchUpdateAssistant) {

            const mockResponse = new HttpResponse({
                status: 200,
                body: { message: 'Assistant successfully updated.' }
            });

            return of(mockResponse).pipe(delay(2000));  // Simulate network delay
        }

        /*  KNOWLEDGE ENDPOINTS  */

        // if (req.url.includes('/knowledge/all')) {
        //     const mockResponse = new HttpResponse({
        //         status: 200,
        //         body: {
        //             knowledges: this.knowledges,
        //             currentPage: 1,
        //             perPage: 10
        //         }
        //     });
        //
        //     return of(mockResponse).pipe(delay(2000));
        // }

        // if (req.url.includes('/knowledge') && req.url.endsWith('/delete')) {
        //     const knowledgeId = req.url.split('/')[5];
        //     const index = this.knowledges.findIndex(k => k.id === knowledgeId);
        //
        //     if (index !== -1) {
        //         this.knowledges.splice(index, 1);
        //     }
        //
        //     const mockDeleteResponse = new HttpResponse({
        //         status: 200,
        //         body: { message: `Knowledge with id ${knowledgeId} deleted.` }
        //     });
        //
        //     return of(mockDeleteResponse).pipe(delay(2000));
        // }

        const matchGetKnowledge = req.url.match(/\/knowledge\/(\d+)\/get/);
        if (matchGetKnowledge) {
            const id = matchGetKnowledge[1];
            const knowledge = this.knowledges.find(knowledge => knowledge.id === id);

            const mockResponse = new HttpResponse({
                status: 200,
                body: knowledge
            });

            return of(mockResponse).pipe(delay(2000));
        }

        /*if (req.url.includes('/knowledge/') && req.url.endsWith('/attachment/create')) {
            const knowledgeId = req.url.split('/')[5];

            const mockResponse = new HttpResponse({
                status: 200,
                body: { message: `Attachments successfully uploaded to knowledge ${knowledgeId}.` }
            });

            return of(mockResponse).pipe(delay(2000));
        }

        if (req.url.includes('/knowledge/') && req.url.endsWith('/file/create')) {
            const knowledgeId = req.url.split('/')[5];

            const mockResponse = new HttpResponse({
                status: 200,
                body: { message: `File source successfully uploaded to knowledge ${knowledgeId}.` }
            });

            return of(mockResponse).pipe(delay(2000));
        }*/

        if (req.url.includes('/knowledge/') && req.url.endsWith('/text/create')) {
            const knowledgeId = req.url.split('/')[5];
            const knowledge = this.knowledges.find(k => k.id === knowledgeId);

            const mockResponse = new HttpResponse({
                status: 200,
                body: { message: `Text source successfully uploaded to knowledge ${knowledgeId}.` }
            });

            return of(mockResponse).pipe(delay(2000));
        }

        if (req.url.includes('/knowledge/create')) {
            const newKnowledge: Knowledge = req.body;
            this.knowledges.push(this.knowledge);

            const mockResponse = new HttpResponse({
                status: 200,
                body: {
                    message: 'Knowledge successfully created.',
                    knowledge: this.knowledge
                }
            });

            return of(mockResponse).pipe(delay(3000));
        }

        /*  TRIPLET ENDPOINTS  */
        if (req.url.includes('/knowledge/triplet/components/get/all')) {
            const mockResponse = new HttpResponse({
                status: 200,
                body: {
                    relationships: this.relationships,
                    entities: this.entities
                }
            });

            return of(mockResponse).pipe(delay(2000));
        }
        //Assign knowlege to assistant
        const matchAssignAssistant = req.url.match(/\/agent\/(\d+)\/assign/);
        if (matchAssignAssistant && req.method === 'POST') {
            const assistantId = matchAssignAssistant[1];
            const knowledgeIds: string[] = req.body;

            // Find the assistant by ID (mock logic)
            const assistant = this.assistants.find(assistant => assistant.id === assistantId);

            if (assistant) {
                const assignedKnowledges = this.knowledges.filter(knowledge => knowledgeIds.includes(knowledge.id));

                const mockResponse = new HttpResponse({
                    status: 200,
                    body: {
                        message: `Successfully assigned ${assignedKnowledges.length} knowledges to assistant with id ${assistantId}.`,
                        assignedKnowledges: assignedKnowledges.map(knowledge => ({ id: knowledge.id, name: knowledge.name }))
                    }
                });

                return of(mockResponse).pipe(delay(2000));
            }
        }

        /*  ACTION ENDPOINTS  */
        //get all actions
        /*if (req.url.includes('/action/all')) {
            const mockResponse = new HttpResponse({
                status: 200,
                body: {
                    actions: this.actions,
                    currentPage: 1,
                    perPage: 10
                }
            });

            return of(mockResponse).pipe(delay(2000));
        }
        // Get action by ID
        const matchGetActionById = req.url.match(/\/action\/(\d+)/);
        if (matchGetActionById) {
            const id = matchGetActionById[1];
            const action = this.actions.find(action => action.id === id);

            if (action) {
                const mockResponse = new HttpResponse({
                    status: 200,
                    body: action
                });
                return of(mockResponse).pipe(delay(2000));
            } else {
                const mockResponse = new HttpResponse({
                    status: 404,
                    body: { message: `Action with id ${id} not found.` }
                });
                return of(mockResponse).pipe(delay(2000));
            }
        }
        //Delete action by id
        if (req.url.includes('/action') && req.url.endsWith('/delete')) {
            const actionId = req.url.split('/')[5];
            const index = this.actions.findIndex(a => a.id === actionId);

            if (index !== -1) {
                this.actions.splice(index, 1);
            }

            const mockDeleteResponse = new HttpResponse({
                status: 200,
                body: { message: `Knowledge with id ${actionId} deleted.` }
            });

            return of(mockDeleteResponse).pipe(delay(2000));
        }
        //Create action
        if (req.url.includes('/action')) {
            const newAction: Action = req.body;
            this.actions.push(this.action);

            const mockResponse = new HttpResponse({
                status: 200,
                body: {
                    message: 'Action successfully created.',
                    action: this.action
                }
            });

            return of(mockResponse).pipe(delay(3000));
        }
        //Edit action
        // Mock service method for editing an action by id
        if (req.url.match(/\/action\/\w+\/update/) && req.method === 'PUT') {
            const id = req.url.split('/')[2];
            const updatedAction = req.body;

            const index = this.actions.findIndex(action => action.id === id);

            if (index !== -1) {
                this.actions[index] = {
                    ...this.actions[index],
                    ...updatedAction
                };

                const mockResponse = new HttpResponse({
                    status: 200,
                    body: {
                        message: 'Action successfully updated.',
                        action: this.actions[index]
                    }
                });

                return of(mockResponse).pipe(delay(3000));
            } else {
                return of(new HttpResponse({
                    status: 404,
                    body: {message: 'Action not found.'}
                }));
            }
        }*/

        return next.handle(req);
    }
}
