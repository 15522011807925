import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-go-to-assistant',
    template: `
        <svg [attr.width]="width" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.217705 12.2823C0.362866 12.4274 0.553127 12.5 0.743388 12.5C0.933648 12.5 1.12391 12.4274 1.26902 12.2823L10.5132 3.0381V6.5C10.5132 6.91055 10.846 7.2434 11.2566 7.2434C11.6672 7.2434 12 6.91055 12 6.5V1.2434C12 0.832843 11.6672 0.5 11.2566 0.5H6.00002C5.58946 0.5 5.25662 0.832843 5.25662 1.2434C5.25662 1.65395 5.58946 1.98679 6.00002 1.98679H9.46183L0.217705 11.231C-0.0725682 11.5213 -0.0725682 11.992 0.217705 12.2823Z" [attr.fill]="stroke"/>
        </svg>
    `,
    styles: [`
        :host {
            --go-to-assistant-color: #5598FE;
        }
        svg path {
            fill: var(--go-to-assistant-color);
        }
    `]
})
export class GoToAssistantComponent {
    @Input() stroke: string = 'var(--go-to-assistant-color)';
    @Input() width: string = '15';
}
