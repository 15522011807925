import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-delete',
    template: `
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="Union" fill-rule="evenodd" clip-rule="evenodd" d="M6.88239 3.17153C6.71135 3.33677 6.66675 3.49441 6.66675 3.55933V4.16948H11.3334V3.55933C11.3334 3.49441 11.2888 3.33677 11.1178 3.17153C10.9489 3.00838 10.7702 2.94916 10.6667 2.94916H7.33341C7.22998 2.94916 7.05126 3.00838 6.88239 3.17153ZM5.66675 6.1695L5.66035 6.16948H4.16675V16.4407C4.16675 16.5056 4.21135 16.6632 4.38239 16.8285C4.55126 16.9916 4.72998 17.0509 4.83342 17.0509H13.1667C13.2702 17.0509 13.4489 16.9916 13.6178 16.8285C13.7888 16.6632 13.8334 16.5056 13.8334 16.4407V6.16948H12.3398L12.3334 6.1695L12.327 6.16948H5.67314L5.66675 6.1695ZM15.8334 6.16948H16.5C17.0523 6.16948 17.5 5.72177 17.5 5.16948C17.5 4.6172 17.0523 4.16948 16.5 4.16948H14.8334H13.3334V3.55933C13.3334 2.81917 12.9613 2.17171 12.5074 1.73315C12.0513 1.29247 11.3967 0.949158 10.6667 0.949158H7.33341C6.60351 0.949158 5.9489 1.29247 5.49277 1.73315C5.03881 2.17171 4.66675 2.81917 4.66675 3.55933V4.16948H3.16675H1.5C0.947715 4.16948 0.5 4.6172 0.5 5.16948C0.5 5.72177 0.947715 6.16948 1.5 6.16948H2.16675V16.4407C2.16675 17.1808 2.53881 17.8283 2.99277 18.2669C3.4489 18.7075 4.10351 19.0509 4.83342 19.0509H13.1667C13.8967 19.0509 14.5513 18.7075 15.0074 18.2669C15.4614 17.8283 15.8334 17.1808 15.8334 16.4407V6.16948ZM7.33325 7.87291C7.88554 7.87291 8.33325 8.32062 8.33325 8.87291V13.7034C8.33325 14.2557 7.88554 14.7034 7.33325 14.7034C6.78097 14.7034 6.33325 14.2557 6.33325 13.7034V8.87291C6.33325 8.32062 6.78097 7.87291 7.33325 7.87291ZM11.6667 8.87291C11.6667 8.32062 11.219 7.87291 10.6667 7.87291C10.1145 7.87291 9.66675 8.32062 9.66675 8.87291V13.7034C9.66675 14.2557 10.1145 14.7034 10.6667 14.7034C11.219 14.7034 11.6667 14.2557 11.6667 13.7034V8.87291Z" [attr.fill]="stroke"/>
        </svg>
    `,
    styles: [`
        :host {
            --delete-color: #3B4754;
        }
        svg path {
            fill: var(--delete-color);
        }
    `]
})
export class DeleteComponent {
    @Input() stroke: string = 'var(--delete-color)';
}
