import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-select-arrow',
    template: `
        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L7 7L1 13" [attr.stroke]="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    `,
    styles: [`
        :host {
            --select-arrow-color: #31405A;
        }
        svg g path {
            fill: var(--select-arrow-color);
        }
    `]
})
export class SelectArrowComponent {
    @Input() stroke: string = 'var(--select-arrow-color)';
}
