<div class="parent-container" (keyup.escape)="closeDialog()">
    <div class="dialog-header">
        <h2>Attachment description</h2>
        <button id="id-edit-close-assistant-dialog" type="button" class="close-button" (click)="closeDialog()">
            <app-close></app-close>
        </button>
    </div>
    <mat-dialog-content class="dialog-content">
        <div class="form-container">
            <textarea
                id="id-attachment-description"
                placeholder="Here goes the description..."
                [(ngModel)]="description"></textarea>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="dialog-footer">
        <button id="id-send-button" (click)="ok()" class="send-button" [disabled]="!isFormValid">OK</button>
    </mat-dialog-actions>
</div>
