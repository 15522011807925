<div class="parent-container" (keyup.escape)="closeDialog()">
    <div class="dialog-header">
        <h2>{{ dialogTitle }}</h2>
        <button id="id-close-triplet-dialog" type="button" class="close-button" (click)="closeDialog()">
            <app-close></app-close>
        </button>
    </div>
    <mat-dialog-content>
        <div class="dialog-container">
            <div class="triplet-container">
                <div class="triplet-table">
                    <div class="table-header">
                        <div class="table-column start-entity">{{ startLabel }}</div>
                        <div class="table-column relation">{{ relationLabel }}</div>
                        <div class="table-column end-entity">{{ endLabel }}</div>
                    </div>
                    <div class="table-row">
                        <div class="table-column start-entity" (click)="toggleEditStartEntity()" appOutsideClick (outsideClick)="closeStartEntityDropdown()">
                            <div class="icon-container" *ngIf="!dropdownStartEntityOpen && !startEntity">
                                <app-plus></app-plus>
                            </div>
                            <div *ngIf="startEntity">
                                <span >{{ startEntity.name }}</span>
                            </div>
                            <!--<input *ngIf="startEntity" [(ngModel)]="searchStartEntity" (input)="filterEntitiesForStart()" placeholder="Search entities..." />-->

                            <ul class="dropdown-menu" *ngIf="dropdownStartEntityOpen">
                                <li (click)="openAddEntityDialog('start')">+ Add new Entity</li>
                                <li *ngFor="let entity of filteredEntitiesForStart" (click)="selectStartEntity(entity, $event)">
                                    {{ entity.name }}
                                </li>
                            </ul>
                        </div>
                        <div class="table-column relation" (click)="toggleEditRelation()" appOutsideClick (outsideClick)="closeRelationshipDropdown()">
                            <div class="icon-container" *ngIf="!dropdownRelationOpen && !relationship">
                                <app-plus></app-plus>
                            </div>
                            <div *ngIf="relationship">
                                <span >{{ relationship }}</span>
                            </div>
                            <ul class="dropdown-menu" *ngIf="dropdownRelationOpen">
                                <li (click)="openAddRelationDialog()">+ Add new Relation</li>
                                <li *ngFor="let rel of relationships" (click)="selectRelation(rel, $event)">
                                    {{ rel }}
                                </li>
                            </ul>
                        </div>
                        <div class="table-column end-entity" (click)="toggleEditEndEntity()" appOutsideClick (outsideClick)="closeEndEntityDropdown()">
                            <div class="icon-container" *ngIf="!dropdownEndEntityOpen && !endEntity">
                                <app-plus></app-plus>
                            </div>
                            <div *ngIf="endEntity">
                                <span >{{ endEntity.name }}</span>
                            </div>
                            <ul class="dropdown-menu" *ngIf="dropdownEndEntityOpen">
                                <li (click)="openAddEntityDialog('end')">+ Add new Entity</li>
                                <li *ngFor="let entity of filteredEntitiesForEnd" (click)="selectEndEntity(entity, $event)">
                                    {{ entity.name }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="dialog-footer">
        <button id="id-add-triplet" type="button" class="add-button" (click)="addTriplet()" [disabled]="!isFormValid">{{ addButton }}</button>
    </mat-dialog-actions>
</div>
