import { Knowledge } from "../../../models/knowledge.model";

export const DUMMY_KNOWLEDGES: Knowledge[] = [
    { id: '1-knowledge', name: 'Knowledge 1', knowledgeType: 'Triplets' },
    { id: '2-knowledge', name: 'Knowledge 2', knowledgeType: 'Text' },
    { id: '3-knowledge', name: 'Knowledge 3', knowledgeType: 'File'},
    { id: '4-knowledge', name: 'Knowledge 4', knowledgeType: 'File'},
    { id: '5-knowledge', name: 'Knowledge 5', knowledgeType: 'Triplets'},
    { id: '6-knowledge', name: 'Knowledge 6', knowledgeType: 'Text'},
    { id: '7-knowledge', name: 'Knowledge 7', knowledgeType: 'File'},
    { id: '8-knowledge', name: 'Knowledge 8', knowledgeType: 'Triplets'},
    { id: '9-knowledge', name: 'Knowledge 9', knowledgeType: 'Text'},
    { id: '10-knowledge', name: 'Knowledge 10', knowledgeType: 'File'},
    { id: '11-knowledge', name: 'Knowledge 11', knowledgeType: 'Triplets'},
    { id: '12-knowledge', name: 'Knowledge 12', knowledgeType: 'Text'},
    { id: '13-knowledge', name: 'Knowledge 13', knowledgeType: 'File'},
    { id: '14-knowledge', name: 'Knowledge 14', knowledgeType: 'Triplets'},
    { id: '15-knowledge', name: 'Knowledge 15', knowledgeType: 'File'},
    { id: '16-knowledge', name: 'Knowledge 16', knowledgeType: 'Text'},
    { id: '17-knowledge', name: 'Knowledge 17', knowledgeType: 'File'},
    { id: '18-knowledge', name: 'Knowledge 18', knowledgeType: 'Text'},
    { id: '19-knowledge', name: 'Knowledge 19', knowledgeType: 'Triplets'},
    { id: '20-knowledge', name: 'Knowledge 20', knowledgeType: 'File'}
]
