<div class="parent-container" (keyup.escape)="closeDialog()">
    <div class="dialog-header">
        <h2>Contact Us</h2>
        <button id="id-edit-close-assistant-dialog" type="button" class="close-button" (click)="closeDialog()">
            <app-close></app-close>
        </button>
    </div>
    <mat-dialog-content class="dialog-content">
        <div class="form-container">
            <div class="pair-container">
                <span>
                    <input type="text" id="id-first-name" name="first-name" [(ngModel)]="firstName" placeholder="First Name"/>
                </span>
                <span>
                    <input type="text" id="id-last-name" name="last-name" [(ngModel)]="lastName" placeholder="Last Name"/>
                </span>
            </div>
            <div class="pair-container">
                <span>
                    <input type="text" id="id-email-address" name="email-address" [(ngModel)]="email" placeholder="Email Adress"/>
                </span>
                <span>
                    <input type="text" id="id-phone-number" name="phone-number" [(ngModel)]="phoneNumber" placeholder="Phone Number"/>
                </span>
            </div>
            <textarea id="id-feedback-reason" placeholder="Here goes the message..." [(ngModel)]="contactUsMessage"></textarea>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="dialog-footer">
        <button id="id-send-button" (click)="send()" class="send-button" [disabled]="!isFormValid">Send</button>
    </mat-dialog-actions>
</div>
