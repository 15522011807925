import { Component, OnDestroy, OnInit } from '@angular/core';

import { DialogService } from "../../../core/services/dialog.service";
import { KnowledgeService } from "../../../core/services/knowledge.service";
import { AlertService } from "../../../core/services/alert.service";
import { KnowledgeModel, KnowledgeTripletModel } from "../../../core/models/knowledge/knowledge.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
    selector: 'app-create-knowledge-modal',
    templateUrl: './create-knowledge-modal.component.html',
    styleUrl: './create-knowledge-modal.component.scss'
})
export class CreateKnowledgeModalComponent implements OnInit {
    dialogTitle = 'Add new knowledge';
    nameLabel = 'Name';
    componentSelection: string[] = ['Add Text', 'Add File', 'Add Triplets'];
    componentLabel = 'Choose Component';
    knowledgeNameLabel = 'Enter knowledge name';

    selectedKnowledgeType: string = 'Text';

    knowledgeName: string = '';
    dropzoneAttachment: any;
    dropzoneFile: any;
    triplets: KnowledgeTripletModel[] = [];
    knowledgeForm!: FormGroup; // Using reactive forms
    errorNameRequiredMessage = 'Knowledge name is required.'
    errorTextRequiredMessage = 'Knowledge text is required.'
    errorMinLengthMessage: string = 'Knowledge name must contain more than 3 characters.'

    // Add Text
    textLabel = 'Text Input';
    textareaLabel = 'Enter knowledge text here...';
    knowledgeText: string = '';

    constructor(
        private dialog: DialogService,
        private knowledge: KnowledgeService,
        private alert: AlertService,
        private formBuilder: FormBuilder) { }

    ngOnInit(): void {
        this.initializeForm();
    }

    private initializeForm() {
        this.knowledgeForm = this.formBuilder.group({
            knowledgeName: ['', Validators.required],
            knowledgeText: ['', Validators.required]
        });
    }

    getKnowledgeFormElement(control: string) {
        return this.knowledgeForm.get(control);
    }

    isFieldInvalid(controlName: string): boolean {
        const control = this.knowledgeForm.get(controlName);
        return !!(control?.invalid && (control.dirty || this.knowledgeForm.get(controlName)?.touched)); // Parse into boolean type with !!
    }

    resetFields(){
        this.knowledgeForm.reset();
    }

    async addKnowledge() {
        this.alert.showLoading();

        const knowledge: KnowledgeModel = {} as KnowledgeModel;
        knowledge.name = this.knowledgeForm.get('knowledgeName')?.value;
        knowledge.knowledgeType = this.selectedKnowledgeType;

        // TODO refactor like in edit knowledge modal
        if (this.selectedKnowledgeType === 'Text' || this.selectedKnowledgeType === 'File') {
            const formData = new FormData();
            formData.append('config', JSON.stringify(knowledge));

            if (this.selectedKnowledgeType === 'Text') {
                formData.append('document', new File([new Blob([this.knowledgeForm.get('knowledgeText')?.value])], 'text.txt'));
            } else if (this.selectedKnowledgeType === 'File') {
                formData.append('document', this.dropzoneFile.files[0]);
            }

            if (this.dropzoneAttachment !== undefined && this.dropzoneAttachment.files) {
                this.dropzoneAttachment.files.forEach((file: any) => {
                    formData.append('attachments', file);
                });
            }

            const response = await this.knowledge.createDocumentKnowledge(formData);

            if (response.status !== 200) {
                this.alert.showError('Error', response.message);
                return;
            }
        } else if (this.selectedKnowledgeType === 'Triplets') {
            knowledge.knowledgeType = 'Triplet';
            knowledge.triplets = this.triplets;
            const response = await this.knowledge.createTripletKnowledge(knowledge);

            if (response.status !== 200) {
                this.alert.showError('Error', response.message);
                return;
            }
        } else {
            this.alert.showError('Error', 'Invalid knowledge type');
            return;
        }

        const response = await this.knowledge.getKnowledgePaginationList(1, 10, '');

        if (response.status !== 200) {
            this.alert.showError('Error', response.message);
            return;
        }

        this.knowledge.setKnowledgePaginationResponse(response.body);

        this.closeDialog();
        this.alert.close();
        this.alert.showSucess('Successfully added!', 'Check knowledge availability in Learning Hub.');
    }

    handleAttachmentDropzone(dropzone: any) {
        this.dropzoneAttachment = dropzone;
    }

    handleFileDropzone(dropzone: any) {
        this.dropzoneFile = dropzone;
    }

    handleTriplets(triplets: KnowledgeTripletModel[]) {
        console.log('tripleti:', triplets);
        this.triplets = triplets;
    }

    selectComponent(component: string) {
        this.selectedKnowledgeType = component;
    }

    get isFormValid(): boolean {
        const knowledgeNameControl = this.knowledgeForm.get('knowledgeName');
        const knowledgeTextControl = this.knowledgeForm.get('knowledgeText');

        if (!knowledgeNameControl || knowledgeNameControl.invalid) {
            return false;
        }

        if (this.selectedKnowledgeType === 'Text') {
            if (!knowledgeTextControl || knowledgeTextControl.invalid) {
                return false;
            }
        }

        if (this.selectedKnowledgeType === 'File') {
            return this.dropzoneFile && this.dropzoneFile.files.length > 0;
        }

        if (this.selectedKnowledgeType === 'Triplets') {
            return this.triplets.length > 0;
        }

        return true;
    }

    getClass() {
        return {
            'triplets-active': this.selectedKnowledgeType === 'Triplets'
        };
    }

    closeDialog() {
        if (this.triplets && this.triplets.length > 0) {
            this.alert.showSaveChanges(
                'Warning',
                'You have entered triplets, data will be lost if you close the dialog!',
                'Close'
            ).then((result: { isConfirmed: any; }) => {
                if (result.isConfirmed) {
                    this.resetFields();
                    this.dialog.close();
                }
            });
            return;
        } else {
            this.resetFields();
            this.dialog.close();
        }
    }
}
