import { Component, ElementRef, HostListener, OnInit } from '@angular/core';

import { ConfigService } from "../../../core/services/config.service";
import { AuthService } from "../../../core/services/auth.service";
import { RouterService } from "../../../core/services/router.service";
import { AlertService } from "../../../core/services/alert.service";

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrl: './account.component.scss'
})
export class AccountComponent implements OnInit{
    logoutImage: string = 'assets/icons/logout.svg';
    settingsImage: string = 'assets/icons/settings.svg';
    logoutLabel: string = 'Logout';
    settingsLabel: string = 'Settings';

    accountImage: string = 'assets/icons/account.svg';

    isVisibleMenu: boolean = false;
    userName: string = '';

    // Close dropdown when clicking outside
    @HostListener('document:click', ['$event'])
    clickOutAccountMenu(event: Event) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.isVisibleMenu = false;
        }
    }

    constructor(
        private elementRef: ElementRef,
        private config: ConfigService,
        private auth: AuthService,
        private route: RouterService,
        private alert: AlertService) { }

    ngOnInit(): void {
        const user = this.config.getUser();
        this.userName = user?.firstName + ' ' + user?.lastName;
    }

    toggleDropdown() {
        this.isVisibleMenu = !this.isVisibleMenu;
    }

    async logout() {
        // TODO change this to use guards
        this.alert.showLoading();
        this.auth.logout();
        this.route.goToLogin();
        this.alert.close();
    }

    settings() {

    }
}
