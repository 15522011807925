import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

import { AlertService } from "../../../core/services/alert.service";
import { RouterService } from "../../../core/services/router.service";
import { LoginModel } from "../../../core/models/auth/login.model";
import { AuthService } from "../../../core/services/auth.service";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss'
})
export class LoginComponent {
    welcomeText: string= 'Welcome!';
    loginText: string = 'Admin Panel Login';
    usernamePlaceholder: string = 'Username';
    passwordPlaceholder: string = 'Password';
    forgotPasswordText: string = 'Forgot password?';
    loginButton: string = 'Login';

    forgotTitle: string = 'Forgot Password?';
    forgotText: string = 'Enter your email address that you use with your account to continue.';
    emailPlaceholder: string = 'Email';
    sendButton: string = 'Send';
    signUpButton: string = 'Sign Up';

    logo: string = "assets/icons/exafyLogo.svg";

    username: string = '';
    password: string = '';
    email: string = '';
    isPasswordForgotten: boolean = false;

    constructor(
        private title: Title,
        private auth: AuthService,
        private alert: AlertService,
        private router: RouterService
    ) {
        this.title.setTitle('Login');
    }

    async login() {
        this.alert.showLoading();

        if (!this.username || !this.password) {
            return;
        }

        const loginModel: LoginModel = {
            email: this.username,
            password: this.password
        };

        const loginResponse = await this.auth.login(loginModel);

        this.alert.close();
        if (loginResponse.status == 200) {
            if (loginResponse.token) localStorage.setItem('token', loginResponse.token);
            this.router.goToLanding();
        } else if (loginResponse.status == 401) {
            this.alert.showError('Login failed!', 'Check if your username and password are correct. Please try again.');
        } else {
            this.alert.showError('Login failed!', 'An error occurred while trying to login. Please try again.');
        }
    }

    isFormFilled(): boolean {
        return !(!this.username || this.username.trim() === "" || !this.password);
    }

    async sendEmail() {
        this.alert.showLoading();

        if (!this.email) {
            return;
        }

        await this.auth.forgotPassword(this.email);
        this.openLogin();

        this.alert.close();
        return;
    }

    signUp() {
        this.openLogin();
    }

    forgotPassword() {
        this.openForgotPassword();
    }

    openLogin() {
        this.email = '';
        this.isPasswordForgotten = false;
    }

    openForgotPassword() {
        this.username = '';
        this.password = '';
        this.isPasswordForgotten = true;
    }

    isEmailFilled(): boolean {
        if (!this.email || this.email.trim() === "") return false;

        return true;
    }
}
