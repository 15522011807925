import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DialogService } from "../../../core/services/dialog.service";
import { KnowledgeTripletModel } from "../../../core/models/knowledge/knowledge.model";

@Component({
    selector: 'app-triplet-knowledge',
    templateUrl: './triplet-knowledge.component.html',
    styleUrl: './triplet-knowledge.component.scss'
})
export class TripletKnowledgeComponent implements OnInit {
    @Input() tripletsFromEditingKnowledge: KnowledgeTripletModel[] = [];
    @Output() uploadedTriplets: EventEmitter<KnowledgeTripletModel[]> = new EventEmitter<KnowledgeTripletModel[]>();

    descriptionSection: string[] = [
        `Welcome to Triplets!`,
        `Create new knowledge or enrich previously added knowledge through Triplets.`];
    addTripletButton: string = 'Add new Triplet';

    triplets: KnowledgeTripletModel[] = [];

    constructor(private dialog: DialogService) { }

    ngOnInit(): void {
        if (this.tripletsFromEditingKnowledge) {
            this.triplets = this.tripletsFromEditingKnowledge;
        }
    }

    addTriplet() {
        // Take datas from nested dialog
        this.dialog.openTripletDialog().afterClosed().subscribe((result) => {
            if (result) {
                this.triplets.push(result);
                this.uploadedTriplets.emit(this.triplets);
            }
        });

        console.log(this.triplets);
    }


    editTriplet(triplet: KnowledgeTripletModel, index: number) {
        this.dialog.openTripletDialog(triplet).afterClosed().subscribe((result) => {
            if (result) {
                this.triplets[index] = result;
            }
        });
    }

    deleteTriplet(index: number) {
        this.triplets.splice(index, 1);
    }

    isTripletsEmpty(): boolean {
        return !(this.triplets && this.triplets.length > 0);
    }
}
