import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-edit',
    template: `
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="Union" fill-rule="evenodd" clip-rule="evenodd" d="M14.045 2.8898C14.1879 2.8839 14.3303 2.90767 14.4632 2.95946C14.596 3.01124 14.7161 3.0898 14.816 3.18979C14.9159 3.28978 14.9934 3.40898 15.0438 3.53965C15.0943 3.6703 15.1168 3.80976 15.1099 3.94923C15.1031 4.0887 15.0672 4.22545 15.0041 4.35078C14.941 4.47613 14.8521 4.58749 14.7428 4.67753C14.7178 4.6981 14.6938 4.71988 14.671 4.74278L13.5257 5.88929L12.0985 4.46201L13.2461 3.3131C13.2657 3.2935 13.2844 3.27309 13.3023 3.25193C13.3933 3.14421 13.5063 3.05612 13.6342 2.99363C13.7622 2.93114 13.9021 2.8957 14.045 2.8898ZM10.685 5.87702L3.84898 12.7208L3.31375 14.6855L5.27408 14.1503L12.1123 7.3043L10.685 5.87702ZM15.1896 1.09602C14.7991 0.943809 14.3812 0.874205 13.9624 0.891503C13.5437 0.908801 13.1329 1.01264 12.7564 1.1966C12.3935 1.37388 12.0698 1.6219 11.8046 1.92614L2.2464 11.4951C2.12348 11.6182 2.03478 11.7712 1.98906 11.939L0.9241 15.8483C0.829778 16.1945 0.928212 16.5647 1.18203 16.8184C1.43586 17.0721 1.80615 17.1703 2.15233 17.0758L6.0572 16.0096C6.22517 15.9638 6.37826 15.8749 6.50131 15.7517L16.0551 6.18712C16.3601 5.9268 16.6103 5.60823 16.7907 5.24971C16.9791 4.87525 17.0871 4.46561 17.1076 4.04691C17.128 3.62822 17.0605 3.20999 16.9095 2.81896C16.7584 2.42794 16.5272 2.07293 16.231 1.77638C15.9348 1.47984 15.5801 1.24824 15.1896 1.09602Z" [attr.fill]="stroke"/>
        </svg>
    `,
    styles: [`
        :host {
            --edit-color: #3B4754;
        }
        svg path {
            fill: var(--edit-color);
        }
    `]
})
export class EditComponent {
    @Input() stroke: string = 'var(--edit-color)';
}
