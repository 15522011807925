<div class="parent-container" (keyup.escape)="closeDialog()">
    <div class="dialog-header">
        <h2>{{ dialogTitle }}</h2>
        <button id="id-close-entity-dialog" type="button" class="close-button" (click)="closeDialog()">
            <app-close></app-close>
        </button>
    </div>
    <mat-dialog-content>
        <div class="dialog-container">
            <div>
                <div class="form-container">
                    <label>{{ relationNameLabel }}</label>
                    <div class="single-container">
                        <input type="text" id="id-entity-name" name="entity-name" [(ngModel)]="relationName" [placeholder]="enterRelationNameLabel"/>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="dialog-footer">
        <button id="id-create-relation-button" type="button" (click)="addProperty()" class="create-button" [disabled]="!isFormValid">{{ saveButton }}</button>
    </mat-dialog-actions>
</div>
