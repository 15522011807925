import { Entity, Relation } from "../../../models/triplet.component";
import { KnowledgeTripletEntityModel, KnowledgeTripletModel } from "../../../models/knowledge/knowledge.model";

export const DUMMY_TRIPLETS: KnowledgeTripletModel[] = [
    {
        id: '1-triplet',
        startEntity: { id: '1-entity', name: 'Pera', type: 'person', properties: [{ id: 'property-s-1', name: 'age', value: '30' }, { id: 'property-s-2', name: 'occupation', value: 'Engineer' }] },
        relationship: 'works as',
        endEntity: { id: '81-entity', name: 'Programmer', type: 'profession', properties: [{ id: 'property-e-1', name: 'experience', value: '5 years' }, { id: 'property-e-2', name: 'specialization', value: 'Web Development' }] }
    },
    {
        id: '2-triplet',
        startEntity: { id: '2-entity', name: 'Ana', type: 'person', properties: [{ id: 'property-s-3', name: 'age', value: '28' }, { id: 'property-s-4', name: 'occupation', value: 'Designer' }] },
        relationship: 'is a friend of',
        endEntity: { id: '3-entity', name: 'Marko', type: 'person', properties: [{ id: 'property-e-3', name: 'age', value: '35' }, { id: 'property-e-4', name: 'occupation', value: 'Manager' }] }
    },
    {
        id: '3-triplet',
        startEntity: { id: '3-entity', name: 'Marko', type: 'person', properties: [{ id: 'property-s-5', name: 'age', value: '35' }, { id: 'property-s-6', name: 'occupation', value: 'Manager' }] },
        relationship: 'lives in',
        endEntity: { id: '9-entity', name: 'Belgrade', type: 'location', properties: [{ id: 'property-e-5', name: 'population', value: '1.7 million' }, { id: 'property-e-6', name: 'status', value: 'capital' }] }
    },
    {
        id: '4-triplet',
        startEntity: { id: '4-entity', name: 'Company X', type: 'organization', properties: [{ id: 'property-s-7', name: 'status', value: 'operating' }, { id: 'property-s-8', name: 'employees', value: '100' }] },
        relationship: 'manages',
        endEntity: { id: '7-entity', name: 'Task A', type: 'task', properties: [{ id: 'property-e-7', name: 'priority', value: 'high' }, { id: 'property-e-8', name: 'assignedTo', value: 'Pera' }] }
    },
    {
        id: '5-triplet',
        startEntity: { id: '7-entity', name: 'Pera', type: 'person', properties: [{ id: 'property-s-9', name: 'age', value: '30' }, { id: 'property-s-10', name: 'occupation', value: 'Engineer' }] },
        relationship: 'owns',
        endEntity: { id: '86-entity', name: 'Car', type: 'object', properties: [{ id: 'property-e-9', name: 'status', value: 'pending' }, { id: 'property-e-10', name: 'priority', value: 'high' }] }
    },
    {
        id: '6-triplet',
        startEntity: { id: '5-entity', name: 'University Y', type: 'institution', properties: [{ id: 'property-s-11', name: 'status', value: 'accredited' }, { id: 'property-s-12', name: 'students', value: '5000' }] },
        relationship: 'studied at',
        endEntity: { id: '1-entity', name: 'Pera', type: 'person', properties: [{ id: 'property-e-11', name: 'age', value: '30' }, { id: 'property-e-12', name: 'occupation', value: 'Engineer' }] }
    },
    {
        id: '7-triplet',
        startEntity: { id: '10-entity', name: 'Novi Sad', type: 'location', properties: [{ id: 'property-s-13', name: 'population', value: '400,000' }, { id: 'property-s-14', name: 'status', value: 'city' }] },
        relationship: 'is related to',
        endEntity: { id: '9-entity', name: 'Belgrade', type: 'location', properties: [{ id: 'property-e-13', name: 'population', value: '1.7 million' }, { id: 'property-e-14', name: 'status', value: 'capital' }] }
    },
    {
        id: '8-triplet',
        startEntity: { id: '11-entity', name: 'Website W', type: 'digital product', properties: [{ id: 'property-s-15', name: 'status', value: 'online' }, { id: 'property-s-16', name: 'visitors', value: '1000/day' }] },
        relationship: 'created',
        endEntity: { id: '8-entity', name: 'Moj Entity', type: 'custom-type', properties: [{ id: 'property-e-15', name: 'owner', value: 'User' }, { id: 'property-e-16', name: 'deadline', value: '2024-11-15' }] }
    },
    {
        id: '9-triplet',
        startEntity: { id: '12-entity', name: 'Application A', type: 'digital product', properties: [{ id: 'property-s-17', name: 'status', value: 'beta' }, { id: 'property-s-18', name: 'users', value: '500' }] },
        relationship: 'is the CEO of',
        endEntity: { id: '4-entity', name: 'Company X', type: 'organization', properties: [{ id: 'property-e-17', name: 'status', value: 'operating' }, { id: 'property-e-18', name: 'employees', value: '100' }] }
    },
    {
        id: '10-triplet',
        startEntity: { id: '8-entity', name: 'Moj Entity', type: 'custom-type', properties: [{ id: 'property-s-19', name: 'owner', value: 'User' }, { id: 'property-s-20', name: 'deadline', value: '2024-11-15' }] },
        relationship: 'has a degree in',
        endEntity: { id: '5-entity', name: 'University Y', type: 'institution', properties: [{ id: 'property-e-19', name: 'status', value: 'accredited' }, { id: 'property-e-20', name: 'students', value: '5000' }] }
    }
];


export const DUMMY_ENTITIES: KnowledgeTripletEntityModel[] = [
    { id: '1-entity', name: 'Pera', type: 'person',
        properties: [
            { id: 'property-1-1-entity', name: 'age', value: '30' },
            { id: 'property-2-1-entity', name: 'occupation', value: 'Engineer' }
        ]
    },
    { id: '2-entity', name: 'Ana', type: 'person',
        properties: [
            { id: 'property-1-2-entity', name: 'age', value: '28' },
            { id: 'property-2-2-entity', name: 'occupation', value: 'Designer' }
        ]
    },
    { id: '3-entity', name: 'Marko', type: 'person',
        properties: [
            { id: 'property-1-3-entity', name: 'age', value: '35' },
            { id: 'property-2-3-entity', name: 'occupation', value: 'Manager' }
        ]
    },
    { id: '4-entity', name: 'Company X', type: 'organization',
        properties: [
            { id: 'property-1-4-entity', name: 'status', value: 'operating' },
            { id: 'property-2-4-entity', name: 'employees', value: '100' }
        ]
    },
    { id: '5-entity', name: 'University Y', type: 'institution',
        properties: [
            { id: 'property-1-5-entity', name: 'status', value: 'accredited' },
            { id: 'property-2-5-entity', name: 'students', value: '5000' }
        ]
    },
    { id: '6-entity', name: 'Project Z', type: 'project',
        properties: [
            { id: 'property-1-6-entity', name: 'status', value: 'in progress' },
            { id: 'property-2-6-entity', name: 'deadline', value: '2024-12-01' }
        ]
    },
    { id: '7-entity', name: 'Task A', type: 'task',
        properties: [
            { id: 'property-1-7-entity', name: 'priority', value: 'high' },
            { id: 'property-2-7-entity', name: 'assignedTo', value: 'Pera' }
        ]
    },
    { id: '8-entity', name: 'Task B', type: 'task',
        properties: [
            { id: 'property-1-8-entity', name: 'priority', value: 'medium' },
            { id: 'property-2-8-entity', name: 'assignedTo', value: 'Ana' }
        ]
    },
    { id: '9-entity', name: 'Belgrade', type: 'location',
        properties: [
            { id: 'property-1-9-entity', name: 'population', value: '1.7 million' },
            { id: 'property-2-9-entity', name: 'status', value: 'capital' }
        ]
    },
    { id: '10-entity', name: 'Novi Sad', type: 'location',
        properties: [
            { id: 'property-1-10-entity', name: 'population', value: '400,000' },
            { id: 'property-2-10-entity', name: 'status', value: 'city' }
        ]
    },
    { id: '11-entity', name: 'Website W', type: 'digital product',
        properties: [
            { id: 'property-1-11-entity', name: 'status', value: 'online' },
            { id: 'property-2-11-entity', name: 'visitors', value: '1000/day' }
        ]
    },
    { id: '12-entity', name: 'Application A', type: 'digital product',
        properties: [
            { id: 'property-1-12-entity', name: 'status', value: 'beta' },
            { id: 'property-2-12-entity', name: 'users', value: '500' }
        ]
    },
    { id: '81-entity', name: 'Programmer', type: 'profession',
        properties: [
            { id: 'property-1-81-entity', name: 'experience', value: '5 years' },
            { id: 'property-2-81-entity', name: 'specialization', value: 'Web Development' }
        ]
    },
    { id: '82-entity', name: 'Mechanic', type: 'profession',
        properties: [
            { id: 'property-1-82-entity', name: 'experience', value: '10 years' }
        ]
    },
    { id: '83-entity', name: 'Plumber', type: 'profession',
        properties: [
            { id: 'property-1-83-entity', name: 'experience', value: '8 years' }
        ]
    },
    { id: '84-entity', name: 'Father', type: 'family-role',
        properties: [
            { id: 'property-1-84-entity', name: 'status', value: 'active' },
            { id: 'property-2-84-entity', name: 'children', value: '2' }
        ]
    },
    { id: '85-entity', name: 'Marko', type: 'person',
        properties: [
            { id: 'property-1-85-entity', name: 'age', value: '35' },
            { id: 'property-2-85-entity', name: 'status', value: 'inactive' }
        ]
    },
    { id: '86-entity', name: 'Car', type: 'object',
        properties: [
            { id: 'property-1-86-entity', name: 'status', value: 'pending' },
            { id: 'property-2-86-entity', name: 'priority', value: 'high' }
        ]
    },
    { id: '18-entity', name: 'Ana', type: 'person',
        properties: [
            { id: 'property-1-18-entity', name: 'age', value: '28' },
            { id: 'property-2-18-entity', name: 'profession', value: 'Designer' }
        ]
    },
    { id: '17-entity', name: 'Belgrade', type: 'location',
        properties: [
            { id: 'property-1-17-entity', name: 'population', value: '1.7 million' },
            { id: 'property-2-17-entity', name: 'status', value: 'capital' }
        ]
    }
];

export const DUMMY_RELATIONS: Relation[] = [
    { id: '1-relation', name: 'works as' },            // Pera works as a Programmer
    { id: '2-relation', name: 'is a friend of' },       // Ana is a friend of Marko
    { id: '3-relation', name: 'owns' },                 // Marko owns a Car
    { id: '4-relation', name: 'is married to' },        // Pera is married to Ana
    { id: '5-relation', name: 'lives in' },             // Ana lives in Belgrade
    { id: '6-relation', name: 'studied at' },           // Pera studied at University
    { id: '7-relation', name: 'is a colleague of' },    // Pera is a colleague of Marko
    { id: '8-relation', name: 'created' },              // Nikola created a Project
    { id: '9-relation', name: 'is the CEO of' },        // Pera is the CEO of Company X
    { id: '10-relation', name: 'has a degree in' },     // Ana has a degree in Engineering
    { id: '11-relation', name: 'manages' },             // Marko manages the Team
    { id: '12-relation', name: 'is related to' }        // Pera is related to Ana (family relation)
]
