import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as mammoth from 'mammoth';
import { renderAsync } from 'docx-preview';

import { DialogService } from "../../../core/services/dialog.service";

@Component({
    selector: 'app-attachment-preview-modal',
    templateUrl: './attachment-preview-modal.component.html',
    styleUrl: './attachment-preview-modal.component.scss'
})
export class AttachmentPreviewModalComponent implements OnInit {
    @ViewChild('videoModal') videoPlayer!: ElementRef;
    mediaType: string = '';
    mediaUrl: string = '';
    fileContent: string = '';

    constructor(private dialog: DialogService, @Inject(MAT_DIALOG_DATA) public data: File | null,
                private dialogRef: MatDialogRef<AttachmentPreviewModalComponent>){ }

    ngOnInit(): void {
        if (this.data) {
            this.mediaUrl = URL.createObjectURL(this.data);
            console.log(this.data.type);

            const fileExtension = this.data.name.split('.').pop()?.toLowerCase();

            if (fileExtension) {
                if (['png', 'jpg', 'jpeg'].includes(fileExtension)) {
                    this.mediaType = 'image';
                } else if (['mp4'].includes(fileExtension)) {
                    this.mediaType = 'video';
                } else if (['pdf'].includes(fileExtension)) {
                    this.mediaType = 'pdf';
                    this.loadPdfFile(this.data);
                } else if (['txt'].includes(fileExtension)) {
                    this.mediaType = 'text';
                    this.readTxtFile(this.data);
                } else if (['docx'].includes(fileExtension)) {
                    this.mediaType = 'word';
                    this.loadDocxPreview(this.data);
                }
                else {
                    this.mediaType = 'unsupported';
                }
            } else {
                this.mediaType = 'unknown';
            }
        }
    }

    readTxtFile(file: File): void {
        const reader = new FileReader();
        reader.onload = () => {
            this.mediaUrl = reader.result as string; // Sadržaj fajla
        };
        reader.readAsText(file);
    }

    loadPdfFile(file: File): void {
        const fileBlob = new Blob([file], {type: 'application/pdf'});
        const newFile = new File([fileBlob], file.name, {type: 'application/pdf'});
        this.mediaUrl = URL.createObjectURL(newFile);
    }

    loadDocxPreview(file: File): void {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            const arrayBuffer = event.target.result;
            const container = document.createElement('div');
            renderAsync(new Uint8Array(arrayBuffer), container)
            .then(() => {
                this.fileContent = container.innerHTML;
            })
            .catch(err => console.error('Error reading DOCX file:', err));
        };
        reader.readAsArrayBuffer(file);
    }

    /*readDocxFile(file: File): void {
        // @ts-ignore
        import('docx-preview').then(docx => {
            const container = document.createElement('div'); // Kreiranje HTML kontejnera
            const reader = new FileReader();

            reader.onload = () => {
                const arrayBuffer = reader.result as ArrayBuffer;
                const doc = new docx.default();
                doc.render(arrayBuffer, container);
                this.mediaUrl = container.innerHTML; // HTML za prikaz .docx sadržaja
            };

            reader.readAsArrayBuffer(file);
        }).catch(err => {
            console.error('Failed to load docx-preview:', err);
            this.mediaType = 'unsupported';
        });
    }*/
    showAttachment(file: File) {
        let content = '';
        const fileExtension = file.name.split('.').pop()?.toLowerCase();

        if (fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg') {
            const imageUrl = URL.createObjectURL(file);
            content = `<img src="${imageUrl}" alt="File Image" style="width:100%; max-height:400px; object-fit: contain;" />`;
        } else if (fileExtension === 'pdf') {
            content = `<iframe src="${URL.createObjectURL(file)}" width="100%" height="400px"></iframe>`;
        } else if (fileExtension === 'docx') {
            content = `<p>DOCX file: ${file.name}</p>`;
        } else if (fileExtension === 'pptx') {
            content = `<p>PPTX file: ${file.name}</p>`;
        } else if (fileExtension === 'xlsx') {
            content = `<p>XLSX file: ${file.name}</p>`;
        } else {
            content = `<p>Unsupported file type: ${file.name}</p>`;
        }
    }

    closeModal() {
        if (this.videoPlayer) {
            this.videoPlayer.nativeElement.pause();
            this.videoPlayer.nativeElement.currentTime = 0;
        }
    }

    closeDialog() {
        this.dialog.closeNestedDialog(this.dialogRef);
    }
}
