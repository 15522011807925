import { Component } from '@angular/core';

import { DialogService } from "../../../core/services/dialog.service";

@Component({
    selector: 'app-soon-modal',
    templateUrl: './soon-modal.component.html',
    styleUrl: './soon-modal.component.scss'
})
export class SoonModalComponent {
    dialogTitle = 'Coming soon!';
    dialogDescription = 'This feature is currently in development and will be available soon!';

    constructor(private dialog: DialogService) { }

    closeDialog() {
        this.dialog.close();
    }
}
