import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { map, Observable } from "rxjs";

import { RestResponseModel } from "../models/rest.model";

export interface HttpHeadersObject {
    [key: string]: string
}

@Injectable({
    providedIn: 'root'
})

export class RestService {
    host: string | null = null;
    method: string = "GET";
    headers: HttpHeaders | undefined = undefined;
    content: Object | FormData | null = null;

    requestHeaders: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json'
    });

    constructor(private http: HttpClient) {}

    // setters
    setHost(host: string): RestService {
        this.host = host;
        return this;
    }

    setMethod (method: string): RestService  {
        this.method = method;
        return this;
    }

    setHeaders (headers: HttpHeaders | HttpHeadersObject): RestService  {
        if (headers instanceof HttpHeaders) {
            this.headers = headers;
        } else {
            let httpHeaders = new HttpHeaders();
            Object.keys(headers).forEach(key => {
                httpHeaders = httpHeaders.set(key, headers[key]);
            });
            this.headers = httpHeaders;
        }

        return this;
    }

    setContent (content: Object | FormData): RestService  {
        this.content = !(content instanceof FormData) ? JSON.stringify(content) : content;
        return this
    }

    create<T>(): Observable<RestResponseModel<T>> {
        let response: Observable<HttpResponse<T>>;
        switch (this.method) {
            case "GET":
                response = this.http.get<T>(this.host ?? "", { headers: this.headers, observe: 'response' });
                break;
            case "POST":
                response = this.http.post<T>(this.host ?? "", this.content, { headers: this.headers, observe: 'response' });
                break;
            case "PUT":
                response = this.http.put<T>(this.host ?? "", this.content, { headers: this.headers, observe: 'response' });
                break;
            case "DELETE":
                response = this.http.delete<T>(this.host ?? "", { headers: this.headers, observe: 'response' });
                break;
            default:
                throw new Error("unsupported method");
        }

        return response.pipe(
            map(res => ({
                status: res.status,
                body: res.body as T,
                message: res.statusText
            }))
        );
    }

    createText(): Observable<RestResponseModel<string>> {
        return this.http.get(this.host ?? "", {
            headers: this.headers,
            observe: 'response',
            responseType: 'text'
        }).pipe(
            map((res: HttpResponse<string>) => ({
                status: res.status,
                body: res.body as string,
                message: res.statusText
            }))
        );
    }

    createFile(fileName: string): Observable<RestResponseModel<File>> {
        return this.http.get(this.host ?? "", {
            headers: this.headers,
            observe: 'response',
            responseType: 'blob'
        }).pipe(
            map((res: HttpResponse<Blob>) => {
                const file = new File([res.body as Blob], fileName, { type: res.body?.type });
                return {
                    status: res.status,
                    body: file,
                    message: res.statusText
                };
            })
        );
    }
}
