import { Component, OnInit } from '@angular/core';

import { DialogService } from "../../../core/services/dialog.service";
import { AlertService } from "../../../core/services/alert.service";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-comment-modal',
  templateUrl: './comment-modal.component.html',
  styleUrl: './comment-modal.component.scss'
})
export class CommentModalComponent implements OnInit {
    description: string = '';

    constructor(private dialog: DialogService,
                private alert: AlertService,
                private dialogRef: MatDialogRef<CommentModalComponent>) {}

    ngOnInit(): void {
        console.log('poz');
    }

    closeDialog() {
        this.dialog.closeNestedDialog(this.dialogRef);
    }

    ok() {
        this.dialogRef.close(this.description);
        this.alert.showSucess('Description successfully added.', `The description for the attachment has been added.`);
    }

    get isFormValid(): boolean {
        return this.description.trim() !== '';
    }
}
