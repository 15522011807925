import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";

import { ScreenDisplayFormat } from "../../../core/models/screenDisplay.model";
import { EventService } from "../../../core/services/event.service";
import { NavBarOptionModel } from "../../../core/models/navBarOption.model";
import { RouterService } from "../../../core/services/router.service";
import { ConfigService } from "../../../core/services/config.service";
import { DialogService } from "../../../core/services/dialog.service";

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrl: './landing.component.scss'
})
export class LandingComponent implements OnInit {
    blueLinesImage: string = "assets/images/blue-lines.png"
    greenLinesImage: string = "assets/images/green-lines.png"

    screenAssistant: ScreenDisplayFormat = {
        title: 'Assistants',
        description:
            `Digital assistant offers 24/7 support, personalized recommendations, and streamlined bookings. It reduces
            administrative workload and provides real-time data and analytics for informed decision-making.`
    };
    screenDataInsight: ScreenDisplayFormat = {
        title: 'Data Insight',
        description:
            `The Data Insights panel lets you track real-time queries, customer feedback, and assistant usage, offering
            a clear view of user interactions. It helps you monitor performance metrics and make data-driven improvements.`
    }

    constructor (
        private config: ConfigService,
        private title: Title,
        private event: EventService,
        private router: RouterService,
        private dialog: DialogService) {
        this.title.setTitle('Landing');
    }

    async ngOnInit(): Promise<void> {
        const option: NavBarOptionModel = { search: '', create: '' };
        this.event.setContentFromNavbarOption(option);
        this.event.setCurrentTab(''); // Clear current tab
        await this.config.fetchRequirements();
    }

    openAssistant() {
        this.router.goToAssistant();
    }

    openDataInsight() {
        this.dialog.openSoonDialog();
    }

    contactUs() {
        this.dialog.openContactUsDialog();
    }
}
