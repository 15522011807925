import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subscription } from "rxjs";

import { ActionId, ActionModel } from "../../../core/models/action/action.model";
import { AlertService } from "../../../core/services/alert.service";
import { DialogService } from "../../../core/services/dialog.service";
import { ActionService } from "../../../core/services/action.service";
import { AssistantService } from "../../../core/services/assistant.service";
import { NavBarOptionModel } from "../../../core/models/navBarOption.model";
import { EventService } from "../../../core/services/event.service";
import { KnowledgeId } from "../../../core/models/knowledge/knowledge.model";

@Component({
    selector: 'app-add-action-to-assistant-modal',
    templateUrl: './add-action-to-assistant-modal.component.html',
    styleUrl: './add-action-to-assistant-modal.component.scss'
})
export class AddActionToAssistantModalComponent implements OnInit, OnDestroy {
    dialogTitle = 'Manage action to ';
    saveButton = 'Save changes';
    noSearchResultSection: string[] = [
        `No search results found!`,
        `Try adjusting your search criteria or create new action if it doesn't exist.`
    ]
    //loupeImage: string = 'assets/icons/look.svg';

    assistantData: any
    actions: ActionModel[] = [ ];
    assignableActions: any[] = [ ];
    checkedActionIds: string[] = [];
    searchValue: string = '';
    checkedActionMap: { [key: string]: boolean } = {};

    assistantActionsSubscription!: Subscription;
    searchValueSubscription!: Subscription;

    @HostListener('document:keydown.escape', ['$event'])
    handleEscape(event: KeyboardEvent) {
        this.closeDialog();
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any | null,
        private alert: AlertService,
        private dialog: DialogService,
        private action: ActionService,
        private assistant: AssistantService,
        private event: EventService,
        private dialogRef: MatDialogRef<AddActionToAssistantModalComponent>) {
    }

    async ngOnInit() {
        this.assistantData = this.data;
        this.dialogTitle = this.dialogTitle + this.assistantData.name;

        const option: NavBarOptionModel = { search: 'Type here to search action...', create: 'Create new action' };
        this.event.setContentFromNavbarOption(option);

        await this.loadActions();

        this.assistantActionsSubscription = this.action.getAssistantActionsResponse().subscribe(actions => {
            if (actions != null) {
                this.actions = actions;
                this.checkAssistantActions();
            }
        })

        this.searchValueSubscription = this.event.getSearchValue().subscribe(search => {
            if (search != null) {
                this.searchValue = search;

                // Filter assignableActions based on search, but use state from map
                this.assignableActions = this.actions
                .filter(action => action.name.includes(search))
                .map(action => ({
                    ...action,
                    assignedToAssistant: this.checkedActionMap[action.id] || false
                }));
            }
        })
    }

    async loadActions() {
        this.alert.showLoading();
        const response = await this.action.getActionList('');
        this.actions = response.body;

        // Update checked items map
        this.actions.forEach(action => {
            this.checkedActionMap[action.id] = this.assistantData.actions
                ? this.assistantData.actions.some((a: ActionModel) => a.id === action.id)
                : false;
        });

        this.action.setAssistantActionsResponse(this.actions);
        this.checkAssistantActions();
        this.alert.close();
    }

    checkAssistantActions() {
        this.assignableActions = this.actions.map(action => ({
            ...action,
            assignedToAssistant: this.checkedActionMap[action.id] || false
        }));
    }

    toggleCheckbox(action: any, event: Event): void {
        action.assignedToAssistant = !action.assignedToAssistant;
        this.checkedActionMap[action.id] = action.assignedToAssistant;
    }

    addNewAction() {
        this.dialog.openActionDialog()
    }

    isAssignableActionListEmpty(): boolean {
        return !(this.assignableActions && this.assignableActions.length > 0);
    }

    async save() {
        this.alert.showLoading();

        try {
            const actionIds: ActionId[] = Object.keys(this.checkedActionMap)
            .filter(key => this.checkedActionMap[key])
            .map(id => ({ id }));

            const response = await this.action.assignAssistantActions(this.assistantData.id, actionIds);
            if (response.status !== 200) {
                this.alert.showError('Error', response.message);
                return;
            }

            const getAgent = await this.assistant.fetchAssistant(this.assistantData.id);
            if (getAgent.status !== 200) {
                this.alert.showError('Error', getAgent.message);
                return;
            }

            this.assistant.setSelectedAssistantResponse(getAgent.body);

            this.closeDialog();
            this.alert.close();
            this.alert.showSucess('Actions assigned successfully.', 'Check your action list in Assistant Actions tab.');
        } catch (error) {
            console.error('Error assigning knowledgeList:', error);
        }
    }

    closeDialog() {
        this.dialog.closeNestedDialog(this.dialogRef);
    }

    ngOnDestroy() {
        this.assistantActionsSubscription.unsubscribe();
        this.searchValueSubscription.unsubscribe();
    }
}
