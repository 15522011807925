import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable } from "rxjs";

import { ConfigService } from "./config.service";
import { RestService } from "./rest.service";
import { KnowledgeId, KnowledgeModel, KnowledgePaginationModel } from "../models/knowledge/knowledge.model";
import { RestResponseModel } from "../models/rest.model";
import { AgentModel } from "../models/agent/agent.model";
import { ErrorService } from "./error.service";

@Injectable({
    providedIn: 'root'
})
export class KnowledgeService {
    private knowledgePagination = new BehaviorSubject<KnowledgePaginationModel | null>(null);
    private assistantKnowledge = new BehaviorSubject<KnowledgeModel[] | null>(null);

    private selectedKnowledgeAttachments = new BehaviorSubject<File[] | null>(null);

    constructor(private config: ConfigService, private rest: RestService, private error: ErrorService) { }

    getKnowledgePaginationResponse(): Observable<KnowledgePaginationModel | null> {
        return this.knowledgePagination;
    }

    getSelectedKnowledgeAttachments(): Observable<File[] | null> {
        return this.selectedKnowledgeAttachments.asObservable();
    }

    getAssistantKnowledgeResponse(): Observable<KnowledgeModel[] | null> {
        return this.assistantKnowledge;
    }

    setKnowledgePaginationResponse(knowledgePagination: KnowledgePaginationModel | null) {
        this.knowledgePagination.next(knowledgePagination);
    }

    setSelectedKnowledgeAttachments(knowledgeAttachments: File[] | null) {
        this.selectedKnowledgeAttachments.next(knowledgeAttachments);
    }

    setAssistantKnowledgeResponse(assistantKnowledge: KnowledgeModel[] | any) {
        this.assistantKnowledge.next(assistantKnowledge);
    }

    async getKnowledgeList(search: string) {
        const url = `${this.config.getUrl()}/knowledge/all?search=${search}`;

        try {
            const response = await lastValueFrom(
                this.rest
                    .setHost(url)
                    .setMethod("GET")
                    .setHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.config.getToken()}`
                    })
                    .create<KnowledgeModel[]>()
            );

            return {
                status: response.status,
                message: response.message,
                body: response.body
            }
        } catch (e: any) {
            return this.error.setError(e, {} as KnowledgeModel[]);
        }
    }

    async getKnowledgePaginationList(page: number, size: number, search: string): Promise<RestResponseModel<KnowledgePaginationModel>> {
        const url = `${this.config.getUrl()}/knowledge/all/pagination?page=${page}&size=${size}&search=${search}`;

        try {
            const response = await lastValueFrom(
                this.rest
                .setHost(url)
                .setMethod("GET")
                .setHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.config.getToken()}`
                })
                .create<KnowledgePaginationModel>()
            );

            return {
                status: response.status,
                message: response.message,
                body: response.body
            }
        } catch (e: any) {
            return this.error.setError(e, {} as KnowledgePaginationModel);
        }
    }

    async getKnowledgeAttachment(knowledgeId: string, attachmentId: string): Promise<RestResponseModel<File>> {
        const url = `${this.config.getUrl()}/knowledge/${knowledgeId}/attachment/${attachmentId}`;

        try {
            const response = await lastValueFrom(
                this.rest
                .setHost(url)
                .setMethod("GET")
                .setHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.config.getToken()}`
                })
                .createFile(attachmentId)
            );

            return {
                status: response.status,
                message: response.message,
                body: response.body
            };
        } catch (e: any) {
            return this.error.setError(e, {} as File);
        }
    }

    async getKnowledgeDocument(knowledgeId: string): Promise<RestResponseModel<any>> {
        const url = `${this.config.getUrl()}/knowledge/${knowledgeId}/content`;

        try {
            const response = await lastValueFrom(
                this.rest
                .setHost(url)
                .setMethod("GET")
                .setHeaders({
                    'Content-Type': 'text/plain',
                    'Authorization': `Bearer ${this.config.getToken()}`
                })
                .createText()
            );

            return {
                status: response.status,
                message: response.message,
                body: response.body
            }
        } catch (e: any) {
            return this.error.setError(e, {} as string);
        }
    }

    async getKnowledge(id: string) {
        const url = `${this.config.getUrl()}/knowledge/${id}`;
        try {
            const response = await lastValueFrom(
                this.rest
                .setHost(url)
                .setMethod("GET")
                .setHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.config.getToken()}`
                })
                .create<KnowledgeModel>()
            );

            return {
                status: response.status,
                message: response.message,
                body: response.body
            }
        } catch (e: any) {
            return this.error.setError(e, {} as KnowledgeModel);
        }
    }

    async deleteKnowledge(id: string) {
        const url = `${this.config.getUrl()}/knowledge/${id}/delete`;
        try {
            const response = await lastValueFrom(
                this.rest
                .setHost(url)
                .setMethod("DELETE")
                .setHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.config.getToken()}`
                })
                .create()
            );

            return {
                status: response.status,
                message: response.message
            }
        } catch (e: any) {
            return this.error.setError(e, null);
        }
    }

    async updateDocumentKnowledge(knowledgeId: string, model: FormData) {
        const url = `${this.config.getUrl()}/knowledge/${knowledgeId}/document-type/update`;

        try {
            const response = await lastValueFrom(
                this.rest
                .setHost(url)
                .setMethod("POST")
                .setHeaders({
                    'Authorization': `Bearer ${this.config.getToken()}`
                })
                .setContent(model)
                .create<KnowledgeModel>()
            )

            return {
                status: response.status,
                message: response.message,
                body: response.body
            }
        } catch (e: any) {
            return this.error.setError(e, {} as KnowledgeModel);
        }
    }

    async updateTripletKnowledge(knowledgeId: string, knowledge: KnowledgeModel) {
        const url = `${this.config.getUrl()}/knowledge/${knowledgeId}/triplet-type/update`;

        try {
            const response = await lastValueFrom(
                this.rest
                .setHost(url)
                .setMethod("POST")
                .setHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.config.getToken()}`
                })
                .setContent(knowledge)
                .create<KnowledgeModel>()
            )

            return {
                status: response.status,
                message: response.message,
                body: response.body
            }
        } catch (e: any) {
            return this.error.setError(e, {} as KnowledgeModel);
        }
    }

    async createDocumentKnowledge(model: FormData) {
        const url = `${this.config.getUrl()}/knowledge/document-type/create`;

        try {
            const response = await lastValueFrom(
                this.rest
                .setHost(url)
                .setMethod("POST")
                .setHeaders({
                    'Authorization': `Bearer ${this.config.getToken()}`
                })
                .setContent(model)
                .create<KnowledgeModel>()
            )

            return {
                status: response.status,
                message: response.message,
                body: response.body
            }
        } catch (e: any) {
            return this.error.setError(e, {} as KnowledgeModel);
        }
    }

    async createTripletKnowledge(model: KnowledgeModel) {
        const url = `${this.config.getUrl()}/knowledge/triplet-type/create`;

        try {
            const response = await lastValueFrom(
                this.rest
                .setHost(url)
                .setMethod("POST")
                .setHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.config.getToken()}`
                })
                .setContent(model)
                .create<KnowledgeModel>()
            )

            return {
                status: response.status,
                message: response.message,
                body: response.body
            }
        } catch (e: any) {
            return this.error.setError(e, {} as KnowledgeModel);
        }
    }

    async assignAssistantKnowledge(assistantId: string, knowledge: KnowledgeId[]) {
        const url = `${this.config.getUrl()}/agent/${assistantId}/knowledge/assign`;

        try {
            const response = await lastValueFrom(
                this.rest
                .setHost(url)
                .setMethod("POST")
                .setHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.config.getToken()}`
                })
                .setContent(knowledge)
                .create()
            );

            return {
                status: response.status,
                message: response.message,
                body: {} as AgentModel
            };
        } catch (e: any) {
            return this.error.setError(e, {} as AgentModel);
        }
    }
}
