<div class="box-wrapper">
    <div class="box-container">
        <div class="box-header"><app-header></app-header></div>
        <div class="box-content">
            <div class="flex-box-content">
                <div class="box-navbar"><app-nav-bar></app-nav-bar></div>
                <div class="box-component">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>
