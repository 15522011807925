import { Component, OnDestroy, OnInit } from '@angular/core';

import { ConfigService } from "../../../core/services/config.service";
import { EventService } from "../../../core/services/event.service";
import { AssistantService } from "../../../core/services/assistant.service";
import { AlertService } from "../../../core/services/alert.service";
import { DialogService } from "../../../core/services/dialog.service";
import { LanguageModel } from "../../../core/models/misc/language.model";
import { ShortcutModel } from "../../../core/models/agent/shortcut.model";
import { VoiceModel } from "../../../core/models/misc/voice.model";

@Component({
    selector: 'app-create-assistant-modal',
    templateUrl: './create-assistant-modal.component.html',
    styleUrl: './create-assistant-modal.component.scss'
})
export class CreateAssistantModalComponent implements OnInit, OnDestroy {
    dialogTitle = 'Create new Assistant';
    createButton = 'Create';
    assistantLabels: string[] = ['Assistant name', 'Assistant role', 'Enter assistant name', 'Enter assistant role']
    knowledgeLabels: string[] = ['Knowledge Scope', 'Controlled', 'Utilizes only custom knowledge.', 'Mixed',
        'Combines both general and custom knowledge.'];
    similarityLabel: string = 'Similarity threshold for attachments (Higher percentage means fewer attachments shown)';
    communicationLabels: string[] = ['Communication style', 'Formal', 'Maintains professionalism, following social norms. ',
        'Informal', 'Keeps things casual and relaxed, using everyday language.'];
    behaviorLabels: string[] = ['Behavior', 'Friendly', 'Approaches interactions warmly, making others feel valued.', 'Curious',
        'Shows a strong interest in learning, often asking questions.', 'Creative',
        'Thinks innovatively, finding unique solutions to problems.', 'Humorous',
        'Injects humor into conversations, aiming to lighten the mood.'];
    answerLabel: string = 'Answer length (As the length of the answer increases, the response time also increases)';
    voiceLabels: string[] = ['Voices (First one selected is considered default)', 'Select language(s)'];
    shortcutLabels: string[] = ['Shortcuts', 'Type first shortcut', 'Type second shortcut', 'Type third shortcut', 'Type fourth shortcut']

    languages: LanguageModel[] = [];
    selectedLanguagesOrder: string[] = [];
    selectedLanguages: string = '';
    assistantName: string = '';
    assistantRole: string = '';
    shortcutFirst: string = '';
    shortcutSecond: string = '';
    shortcutThird: string = '';
    shortcutFourth: string = '';
    knowledgeScope: string = 'controlled';
    similarityThreshold: number = 0;
    answerLengthThreshold: number = 20;
    communicationStyle: string = 'formal';
    behavior: string = 'friendly';

    constructor(private config: ConfigService, private event: EventService, private assistant: AssistantService,
                private alert: AlertService, private dialog: DialogService) {}

    ngOnInit() {
        this.languages = this.config.getLanguages();
    }

    toggleVoices(language: LanguageModel) {
        this.addLanguageToOrder(language);
        this.setFemaleDefaultVoice(language);
    }

    addLanguageToOrder(language: LanguageModel) {
        if (language.selected) {
            if (!this.selectedLanguagesOrder.includes(language.name)) {
                this.selectedLanguagesOrder.push(language.name);
            }
        } else {
            this.selectedLanguagesOrder = this.selectedLanguagesOrder.filter(lang => lang !== language.name);
        }

        this.updateSelectedLanguages();
    }

    setFemaleDefaultVoice(language: LanguageModel) {
        if (language.selected) {
            language.selectedVoice = language.voices.length > 0 ? language.voices[0].gender : '';
        } else {
            language.selectedVoice = '';
        }
    }

    updateSelectedLanguages() {
        this.selectedLanguages = this.selectedLanguagesOrder.join(', ');
    }

    async createAssistant() {
        this.alert.showLoading();
        const assistant = this.createAssistantObject();

        const assistantResponse = await this.assistant.createAssistant(assistant);
        if (assistantResponse.status !== 200) {
            this.alert.showError('Error', assistantResponse.message);
            return;
        }

        const assistantsResponse = await this.assistant.fetchAssistants('');
        if (assistantsResponse.status !== 200) {
            this.alert.showError('Error', assistantsResponse.message);
            return;
        }

        const createdAssistantResponse = await this.assistant.fetchAssistant(assistantResponse.body.id);
        if (createdAssistantResponse.status !== 200) {
            this.alert.showError('Error', createdAssistantResponse.message);
            return;
        }

        this.assistant.setAssistantsResponse(assistantsResponse.body);
        this.assistant.setSelectedAssistantResponse(createdAssistantResponse.body);

        this.closeDialog();
        this.alert.close();

        if (assistantResponse.body.id) {
            this.openDialog(assistantResponse.body.id, assistant.name);
        }
    }

    openDialog(assistantId: string, assistantName: string) {
        this.dialog.openSuccessCreatedAssistantDialog(assistantId, assistantName);
    }

    closeDialog() {
        this.resetFieldValues();
        this.dialog.close();
    }

    // helpers
    get isFormValid(): boolean {
        return this.assistantName.trim() !== '' &&
            // this.assistantRole.trim() !== '' &&
            this.selectedLanguagesOrder.length > 0
    }

    createAssistantObject() {
        const shortcuts: ShortcutModel[] = [];
        if (this.shortcutFirst) {
            shortcuts.push({name: this.shortcutFirst});
        }

        if (this.shortcutSecond) {
            shortcuts.push({name: this.shortcutSecond});
        }

        if (this.shortcutThird) {
            shortcuts.push({name: this.shortcutThird});
        }

        if (this.shortcutFourth) {
            shortcuts.push({name: this.shortcutFourth});
        }

        const voices: VoiceModel[] = [];
        this.selectedLanguagesOrder.forEach(selectedLangName => {
            const selectedLang = this.languages.find(lang => lang.name === selectedLangName);
            const voice = selectedLang?.voices.find(voice => voice.gender === selectedLang?.selectedVoice);

            if (voice) {
                voices.push(voice);
            }
        });

        return {
            name: this.assistantName,
            // role: this.assistantRole,
            knowledgeScope: this.knowledgeScope,
            similarityThreshold: this.similarityThreshold / 100,
            communicationStyle: this.communicationStyle,
            behavior: this.behavior,
            answerLength: this.answerLengthThreshold,
            voices: voices,
            shortcuts: shortcuts,
            languages: [],
            knowledge: [],
            actions: []
        };
    }

    resetFieldValues() {
        this.languages.forEach(language => {
            language.selected = false;
            language.selectedVoice = '';
        });

        this.event.setSimilarityThrashold(0);
        this.event.setAnswerLength(20);
    }

    resetLanguages() {
        this.languages.forEach(language => {
            language.selected = false;
            language.selectedVoice = '';
        });
    }

    // TODO maybe this use on all modals
    ngOnDestroy(): void {
        this.resetLanguages();
    }
}
