import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

import { DialogService } from "../../../core/services/dialog.service";
import { EntityProperty } from "../../../core/models/triplet.component";

@Component({
    selector: 'app-entity-modal',
    templateUrl: './entity-modal.component.html',
    styleUrl: './entity-modal.component.scss'
})
export class EntityModalComponent implements OnDestroy {
    dialogTitle: string = 'Add new Entity';

    entityNameLabel: string = 'Entity Name';
    entityTypeLabel: string = 'Entity Type';
    enterEntityNameLabel: string = 'Enter entity name';
    enterEntityTypeLabel: string = 'Enter entity type';
    newPropertyLabel: string = 'Add Properties/Attributes';
    saveButton: string = 'Save new Entity';
    propertyButton: string = 'Add new Property/Attribute';
    enterPropertyNameLabel: string = 'Enter property name';
    enterPropertyValueLabel: string = 'Enter property value';

    entityName: string = '';
    entityType: string = '';
    properties: EntityProperty[] = [{ name: '', value: '' }];
    canAddProperty: boolean = false;

    constructor(private dialog: DialogService, private dialogRef: MatDialogRef<EntityModalComponent>) {}

    async addEntity() {
        const entity = {
            name: this.entityName,
            type: this.entityType,
            properties: this.properties
        }

        this.dialogRef.close(entity);
    }

    get checkPropertyFilled(): boolean {
        this.canAddProperty = this.properties.every(property => this.isPropertyValid(property));
        return this.canAddProperty;
    }

    removeProperty(index: number): void {
        this.properties.splice(index, 1);
        this.canAddProperty = true;
    }

    addProperty() {
        if (this.canAddProperty) {
            this.properties.push({ name: '', value: '' });
            this.canAddProperty = false;
        }
    }

    isPropertyValid(property: EntityProperty): boolean {
        return property.name.trim() !== '' && property.value.trim() !== '';
    }

    closeDialog() {
        this.dialog.closeNestedDialog(this.dialogRef);
    }

    get isFormValid(): boolean {
        return this.entityName.trim() !== '' &&
            this.entityType.trim() !== '' &&
            this.properties.length > 0;
            //&& this.properties.every(p => this.isPropertyValid(p));
    }

    resetFieldValues() {
        this.entityName = '';
        this.entityType = '';
        this.properties = [];
    }

    ngOnDestroy(): void {
        this.resetFieldValues();
    }
}
