import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";

import { EventService } from "../../../core/services/event.service";
import { AssistantService } from "../../../core/services/assistant.service";
import { DialogService } from "../../../core/services/dialog.service";
import { KnowledgeService } from "../../../core/services/knowledge.service";
import { ActionService } from "../../../core/services/action.service";
import { AlertService } from "../../../core/services/alert.service";

@Component({
    selector: 'app-search-area',
    templateUrl: './search-area.component.html',
    styleUrl: './search-area.component.scss'
})
export class SearchAreaComponent implements OnInit, OnDestroy {
    @Input() tab: string = '';
    @Input() disabled: boolean = false;

    loupeImage: string = 'assets/icons/loupe.svg';

    searchPlaceholder: string = '';
    createButton: string = '';
    searchValue: string = '';

    navBarOptionSubscription!: Subscription;
    currentTabSubscription!: Subscription;

    constructor(
        private dialog: DialogService,
        private event: EventService,
        private assistant: AssistantService,
        private knowledge: KnowledgeService,
        private action: ActionService,
        private alert: AlertService) {}

    ngOnInit(): void {
        this.navBarOptionSubscription = this.event.getContentFromNavbarOption().subscribe(navBarOption => {
            if (navBarOption) {
                this.searchValue = '';
                this.event.setSearchValue(this.searchValue);
                this.searchPlaceholder = navBarOption.search;
                this.createButton = navBarOption.create;
            }
        });
    }

    create() {
        if (this.tab === 'Assistants') { this.dialog.openCreateAssistantDialog(); }
        if (this.tab === 'Learning Hub' || this.tab === 'AssistantLearningHub') { this.dialog.openCreateKnowledgeDialog(); }
        if (this.tab === 'Actions' || this.tab === 'AssistantActions') { this.dialog.openActionDialog(); }
    }

    async search() {
        if (this.searchValue === null || this.searchValue === undefined) { return; }
        this.event.setSearchValue(this.searchValue);
        await this.show(this.searchValue);
    }

    async removeSearch() {
        this.searchValue = '';
        this.event.setSearchValue(this.searchValue);
        await this.show(this.searchValue);
    }

    async show(searchValue: string) {
        //this.alert.showLoading();

        if (this.tab === 'Assistants') {
            const response = await this.assistant.fetchAssistants(searchValue);
            if (response.status !== 200) {
                this.alert.showError('Error', response.message);
            } else {
                this.assistant.setAssistantsResponse(response.body);
            }
        }
        if (this.tab === 'Learning Hub') {
            const response = await this.knowledge.getKnowledgePaginationList(1, 10, searchValue); // TODO: Page, perPage should be considered to be inside config service
            this.alert.close();
            if (response.status !== 200) {
                this.alert.showError('Error', response.message);
            } else {
                this.knowledge.setKnowledgePaginationResponse(response.body);
            }
        }
        if (this.tab === 'AssistantLearningHub') {
            const response = await this.knowledge.getKnowledgeList(searchValue);

            this.alert.close();
            if (response.status !== 200) {
                this.alert.showError('Error', response.message);
            } else {
                //response.body.searchValue = searchValue;
                this.knowledge.setAssistantKnowledgeResponse(response.body);
            }
        }
        if (this.tab === 'Actions') {
            const response = await this.action.getActionPaginationList(1, 10, searchValue); // TODO: Page, perPage should be considered to be inside config service

            this.alert.close();
            if (response.status !== 200) {
                this.alert.showError('Error', response.message);
            } else {
                //response.body.searchValue = searchValue;
                this.action.setActionPaginationResponse(response.body);
            }
        }
        if (this.tab === 'AssistantActions') {
            const response = await this.action.getActionList(searchValue);

            this.alert.close();
            if (response.status !== 200) {
                this.alert.showError('Error', response.message);
            } else {
                //response.body.searchValue = searchValue;
                this.action.setAssistantActionsResponse(response.body);
            }
        }
    }

    ngOnDestroy() {
        if (this.navBarOptionSubscription) { this.navBarOptionSubscription.unsubscribe(); }
        if (this.currentTabSubscription) { this.currentTabSubscription.unsubscribe(); }
    }
}
