import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { inject } from "@angular/core";

import { AssistantService } from "../../core/services/assistant.service";

export const AuthDeactivateGuard: CanActivateFn = async (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const assistant = inject(AssistantService);

    assistant.setSelectedAssistantResponse(null);
    return true;
};
