<div class="content-container">
    <div *ngIf="isTripletsEmpty()" class="initial-container">
        <div class="button-container">
            <button id="id-add-triplet-button" type="button" class="add-button" (click)="addTriplet()">
                <app-plus></app-plus>
            </button>
        </div>
        <div class="description-container">
            <p>{{ descriptionSection[0] }}</p>
            <p>{{ descriptionSection[1] }}</p>
        </div>
    </div>
    <div *ngIf="!isTripletsEmpty()" class="triplets-container">
        <div class="triplet-table">
            <div class="table-header">
                <div class="table-column name">Name</div>
                <div class="table-column type">Type</div>
                <div class="table-column relation">Relation</div>
                <div class="table-column options"></div>
            </div>

            <div class="scroll-container">
                <div class="scroll">
                    <div *ngFor="let triplet of triplets; let i = index" class="table-row">
                        <div class="table-column name">{{ triplet.startEntity.name }}</div>
                        <div class="table-column type">{{ triplet.startEntity.type }}</div>
                        <div class="table-column relation">{{ triplet.relationship}}</div>
                        <div class="table-column options">
                            <button id="id-edit-triplet" class="edit-triplet" (click)="editTriplet(triplet, i)">
                                <app-edit></app-edit>
                            </button>
                            <button id="id-delete-triplet" class="delete-triplet" (click)="deleteTriplet(i)">
                                <app-delete></app-delete>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button id="id-add-new-triplet" type="button" class="add-new-triplet" (click)="addTriplet()">
            <app-plus></app-plus>
            <span>{{ addTripletButton }}</span>
        </button>
    </div>
</div>
