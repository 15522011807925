<div class="dialog-header">
    <h2>{{ dialogTitle }}</h2>
    <button id="id-edit-close-knowledge-dialog" type="button" class="close-button" (click)="closeDialog()">
        <app-close></app-close>
    </button>
</div>
<mat-dialog-content>
    <div class="dialog-container">
        <form [formGroup]="knowledgeForm">
            <div class="selection-container" [ngClass]="getClass()">
            <div class="name-column">
                <label for="id-knowledge-name">{{ nameLabel }}</label>
                <input id="id-knowledge-name"
                       class="knowledge-name"
                       [class.error]="isFieldInvalid('knowledgeName')"
                       type="text"
                       name="knowledge-name"
                       [placeholder]="knowledgeNameLabel"
                       formControlName="knowledgeName"
                       required
                       minlength="3">
                <div class="mat-error-container" *ngIf="isFieldInvalid('knowledgeName')">
                    <mat-error *ngIf="getKnowledgeFormElement('knowledgeName')?.hasError('required')">
                        {{ errorNameRequiredMessage }}
                    </mat-error>
                    <mat-error *ngIf="getKnowledgeFormElement('knowledgeName')?.hasError('minlength')">
                        {{ errorMinLengthMessage }}
                    </mat-error>
                </div>
            </div>

            <div class="component-container" [ngClass]="getClass()">
                <! -- UPDATE TEXT/FILE SOURCE -->
                <div *ngIf="knowledgeType === 'Text' || knowledgeType === 'File'" class="content-container">
                    <div class="textarea-container">
                        <label for="id-knowledge-text">{{ textLabel }}</label>
                        <textarea id="id-knowledge-text"
                                  class="knowledge-text"
                                  [class.error]="isFieldInvalid('knowledgeText')"
                                  name="knowledge-text"
                                  formControlName="knowledgeText"
                                  [placeholder]="textareaLabel"
                                  required></textarea>
                        <div class="mat-error-container" *ngIf="isFieldInvalid('knowledgeText')">
                            <mat-error *ngIf="getKnowledgeFormElement('knowledgeText')?.hasError('required')">
                                {{ errorTextRequiredMessage }}
                            </mat-error>
                        </div>
                    </div>
                    <app-attachment-upload (uploadedDropzone)="handleAttachmentDropzone($event)"></app-attachment-upload>
                </div>
                <! -- UPDATE TRIPLET SOURCE -->
                <app-triplet-knowledge *ngIf="knowledgeType === 'Triplet'" [tripletsFromEditingKnowledge]="triplets" (uploadedTriplets)="handleTriplets($event)"></app-triplet-knowledge>
            </div>
        </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="dialog-footer">
    <button id="id-update-knowledge" type="button" class="add-button" (click)="updateKnowledge()" [disabled]="!isFormValid">{{ buttonLabel }}</button>
</mat-dialog-actions>
