import {
    Component,
    OnInit,
    OnDestroy,
    AfterViewInit,
    AfterViewChecked,
    ElementRef,
    ViewChild,
    SimpleChanges, Input, OnChanges
} from '@angular/core';
import { Title } from "@angular/platform-browser";
import { Subscription } from 'rxjs';

import { EventService } from "../../../core/services/event.service";
import { DialogService } from "../../../core/services/dialog.service";
import { AssistantService } from "../../../core/services/assistant.service";
import { AlertService } from "../../../core/services/alert.service";
import { ConfigService } from "../../../core/services/config.service";
import { AgentModel } from "../../../core/models/agent/agent.model";
import { DesignModel } from "../../../core/models/misc/design.model";

@Component({
    selector: 'app-assistant',
    templateUrl: './assistant.component.html',
    styleUrl: './assistant.component.scss',
})
export class AssistantComponent implements OnInit, OnDestroy {
    cursorImage: string = 'assets/icons/cursor.svg';

    selectAssistantLabel: string = 'Select Assistant';
    assistantKnowledgeLabel: string = 'Assistant Knowledge';
    assistantActionLabel: string = 'Assistant Actions';
    assistantAnalyticsLabel: string = 'Analytics';
    assistantConfigurationLabel: string = 'Configuration';

    knowledgeSection: string = 'Welcome to Assistant Knowledge board! Select desired knowledge for Assistant.'
    actionSection: string = 'Welcome to Assistant Actions board! Select desired actions for Assistant.'
    descriptionSection: string[] = [
        `Welcome to Assistant dashboard!`,
        `Create new Assistant, assign knowledge, add actions and review analytics to
         enhance your assistant's performance.`];

    addKnowledgeToAssistantButton: string = 'Manage Knowledge to Assistant';
    addActionToAssistantButton: string = 'Manage Action to Assistant';
    createAssistantButton: string = 'Create new assistant';

    assistants: AgentModel[] = [];
    activeTab: string = 'knowledge'; // Default tab
    dropdownAssistantMenu = false;
    selectedAssistant: AgentModel | null = null;
    searchValue: string = '';
    designs: DesignModel[] = [];

    private assistantsSubscription!: Subscription;
    private selectedAssistantSubscription!: Subscription;
    private designsSubscription!: Subscription;

    constructor (
        private title: Title,
        private event: EventService,
        private dialog: DialogService,
        private assistantService: AssistantService,
        private alert: AlertService,
        private config: ConfigService) {
        this.title.setTitle('Assistants');
    }

    async ngOnInit() {
        console.log('token')
        console.log(this.config.getToken());

        this.alert.showLoading();
        this.event.setCurrentTab('Assistants');

        await this.fetchRequirements();
        await this.loadAssistantList();

        this.assistantsSubscription = this.assistantService.getAssistantsResponse().subscribe(assistants => {
            this.assistants = assistants ?? [];
            this.selectedAssistant = null;
        });

        this.selectedAssistantSubscription = this.assistantService.getSelectedAssistantResponse().subscribe(selectedAssistant => {
            this.selectedAssistant = selectedAssistant;

            if (this.selectedAssistant && this.selectedAssistant.id) {
                this.designsSubscription = this.config.getAssistantDesigns(this.selectedAssistant.id).subscribe(designs => {
                    this.designs = designs
                    console.log(this.designs);
                });
            }
        });
    }

    async fetchRequirements() {
        await this.config.fetchRequirements();
    }

    async loadAssistantList() {
        const response = await this.assistantService.fetchAssistants(this.searchValue);

        if (response.status !== 200) {
            this.alert.showError('Error', response.message);
            return;
        }

        this.alert.close();
        this.assistantService.setAssistantsResponse(response.body);
    }

    editSelectedAssistant() {
        this.dialog.openEditAssistantDialog();
    }

    async deleteSelectedAssistant() {
        this.alert.showDelete('Delete Assistant?', 'Assistant will be permanently deleted.')
        .then(async (result: { isConfirmed: any; }) => {
            if (result.isConfirmed) {
                if (this.selectedAssistant != null && this.selectedAssistant.id) {
                    this.alert.showLoading();
                    const response = await this.assistantService.deleteAssistant(this.selectedAssistant.id);

                    if (response.status !== 200) {
                        this.alert.showError('Error', response.message);
                        return;
                    }

                    await this.loadAssistantList();
                    this.assistantService.setSelectedAssistantResponse(null);
                }
            }
        });
    }

    openAssistantDesigns() {
        this.dialog.openAssistantDesignsDialog();
    }

    goToAssistant() {
        //this.isGoToAssistant = true;
        const design4 = this.designs[0];
        window.open(design4.url, '_blank');
    }

    toggleAssistantMenu() {
        this.dropdownAssistantMenu = !this.dropdownAssistantMenu;
    }

    closeAssistantMenu() {
        this.dropdownAssistantMenu = false;
    }

    createNewAssistant() {
        this.dialog.openCreateAssistantDialog();
    }

    assignKnowledge() {
        if (this.selectedAssistant){
            const assistantData = { id: this.selectedAssistant.id, name: this.selectedAssistant.name, knowledge: this.selectedAssistant.knowledge };
            this.dialog.openAddKnowledgeToAssistantDialog(assistantData);
        }
    }

    assignAction() {
        if (this.selectedAssistant){
            const assistantData = { id: this.selectedAssistant.id, name: this.selectedAssistant.name, actions: this.selectedAssistant.actions };
            this.dialog.openAddActionToAssistantDialog(assistantData);
        }
    }

    isAssistantsEmpty(): boolean {
        return !(this.assistants && this.assistants.length > 0);
    }

    isAssistantKnowledgeListEmpty(): boolean {
        return !(this.selectedAssistant?.knowledge && this.selectedAssistant.knowledge.length > 0);
    }

    isAssistantActionListEmpty(): boolean {
        return !(this.selectedAssistant?.actions && this.selectedAssistant.actions.length > 0);
    }

    selectTab(tab: string) {
        this.activeTab = tab;
    }

    toggleDropdown() {
        this.dialog.openSelectAssistantDialog();
    }

    getSelectedAssistantLabel(): string {
        if (this.selectedAssistant !== null) {
            return this.selectedAssistant.name;
        } else {
            return this.selectAssistantLabel;
        }
    }

    isSelectAssistant(): boolean {
        return this.selectedAssistant !== null;
    }

    ngOnDestroy() {
        if (this.assistantsSubscription) { this.assistantsSubscription.unsubscribe(); }
        if (this.selectedAssistantSubscription) { this.selectedAssistantSubscription.unsubscribe(); }
        if (this.designsSubscription) { this.designsSubscription.unsubscribe(); }
    }
}
