import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

import { DialogService } from "../../../core/services/dialog.service";

@Component({
    selector: 'app-entity-modal',
    templateUrl: './relation-modal.component.html',
    styleUrl: './relation-modal.component.scss'
})
export class RelationModalComponent implements OnDestroy {
    dialogTitle: string = 'Add new Relation';

    relationNameLabel: string = 'Relation Name';
    enterRelationNameLabel: string = 'Enter relation name';
    saveButton: string = 'Save new Relation';

    relationName: string = '';

    constructor(private dialog: DialogService, private dialogRef: MatDialogRef<RelationModalComponent>) {}

    async addProperty() {
        this.dialogRef.close(this.relationName);
    }

    closeDialog() {
        this.dialog.closeNestedDialog(this.dialogRef);
    }

    get isFormValid(): boolean {
        return this.relationName.trim() !== '';
    }

    resetFieldValues() {
        this.relationName = '';
    }

    ngOnDestroy(): void {
        this.resetFieldValues();
    }
}
