<div class="file-container">
    <label for="fileupload">{{ fileLabel }}</label>
    <form #fileupload id="fileupload" class="dropzone">
        <div class="dz-default dz-message">
            <div class="dz-button">
                <img [src]="attachmentDropImage" alt="Dropzone Icon" />
                <p class="source-browse">Drag and drop your file here<br> or use <b>Browse</b> button to upload.</p>
                <p class="source-extensions">Files supported: TXT, PDF, DOCX, PPTX, CSV, MP4.<br /> Maximum file size: 50MB</p>
            </div>
        </div>
    </form>
</div>
