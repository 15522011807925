<div class="action-container">
    <div class="box-search">
        <app-search-area [tab]="'Actions'" [disabled]="!searchValue && isActionListEmpty()"></app-search-area>
    </div>
    <div *ngIf="searchValue && isActionListEmpty()" class="initial-container">
        <div class="description-container">
            <p>{{ noSearchResultSection[0] }}</p>
            <p>{{ noSearchResultSection[1] }}</p>
        </div>
    </div>
    <div *ngIf="!searchValue && isActionListEmpty()" class="initial-container">
        <div class="button-container">
            <button id="id-add-action-button" type="button" class="add-button" (click)="addAction()">
                <app-plus></app-plus>
            </button>
        </div>
        <div class="description-container">
            <p>{{ descriptionSection[0] }}</p>
            <p>{{ descriptionSection[1] }}</p>
        </div>
    </div>
    <div *ngIf="!isActionListEmpty()" class="actions-container">
        <div class="action-table">
            <div *ngFor="let action of paginatedActionList" class="table-row">
                <div class="table-column name">{{ action.name }}</div>
                <div class="table-column options">
                    <button id="id-edit-action" class="edit-action" (click)="updateAction(action)">
                        <app-edit></app-edit>
                    </button>
                    <button id="id-delete-action" class="delete-action" (click)="deleteAction(action)">
                        <app-delete></app-delete>
                    </button>
                </div>
            </div>
        </div>
        <div class="bottom-container">
            <button id="id-create-new-action" type="button" class="create-new-action" (click)="addAction()">
                <app-plus></app-plus>
                <span>{{ createActionButton }}</span>
            </button>
            <div class="pagination">
                <button *ngFor="let page of paginationRange"
                        [class.active]="currentPage === page"
                        (click)="changePage(page)"
                        [disabled]="page === '...'">
                    {{ page }}
                </button>
            </div>
        </div>
    </div>
</div>
