import {AgentModel} from "../../../models/agent/agent.model";


export const DUMMY_ASSISTANTS: AgentModel[] = [
    // {
    //     id: '5241',
    //     name: 'Pera Asistent',
    //     role: 'Helper',
    //     knowledgeScope: 'Controlled',
    //     similarityThreshold: 75,
    //     communicationStyle: 'Formal',
    //     behavior: 'Friendly',
    //     answerLength: 100,
    //     shortcuts: [],
    //     voices: []
    // },
    // {
    //     id: '5741',
    //     name: 'M Asistent',
    //     role: 'Advisor',
    //     knowledgeScope: 'Mixed',
    //     similarityThreshold: 60,
    //     communicationStyle: 'Informal',
    //     behavior: 'Curious',
    //     answerLength: 130,
    //     shortcuts: [],
    //     voices: []
    // },
    // {
    //     id: '527741',
    //     name: 'J Asistent',
    //     role: 'Guide',
    //     knowledgeScope: 'Controlled',
    //     similarityThreshold: 80,
    //     communicationStyle: 'Formal',
    //     behavior: 'Creative',
    //     answerLength: 120,
    //     shortcuts: [],
    //     voices: []
    // },
    // {
    //     id: '529941',
    //     name: 'N Asistent',
    //     role: 'Sef',
    //     knowledgeScope: 'Controlled',
    //     similarityThreshold: 80,
    //     communicationStyle: 'Formal',
    //     behavior: 'Creative',
    //     answerLength: 80,
    //     shortcuts: [],
    //     voices: []
    // },
    // {
    //     id: '588241',
    //     name: 'V Asistent',
    //     role: 'Guide',
    //     knowledgeScope: 'Controlled',
    //     similarityThreshold: 80,
    //     communicationStyle: 'Formal',
    //     behavior: 'Creative',
    //     answerLength: 90,
    //     shortcuts: [],
    //     voices: []
    // },
    // {
    //     id: '5245551',
    //     name: 'S Asistent',
    //     role: 'Guide',
    //     knowledgeScope: 'Controlled',
    //     similarityThreshold: 80,
    //     communicationStyle: 'Formal',
    //     behavior: 'Creative',
    //     answerLength: 60,
    //     shortcuts: [],
    //     voices: []
    // },
    // {
    //     id: '544241',
    //     name: 'L Asistent',
    //     role: 'Guide',
    //     knowledgeScope: 'Controlled',
    //     similarityThreshold: 80,
    //     communicationStyle: 'Formal',
    //     behavior: 'Creative',
    //     answerLength: 40,
    //     shortcuts: [],
    //     voices: []
    // },
    // {
    //     id: '577777241',
    //     name: 'Z Asistent',
    //     role: 'Guide',
    //     knowledgeScope: 'Controlled',
    //     similarityThreshold: 80,
    //     communicationStyle: 'Formal',
    //     behavior: 'Creative',
    //     answerLength: 150,
    //     shortcuts: [],
    //     voices: []
    // }
];
