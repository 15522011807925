import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { Subscription } from "rxjs";

import { EventService } from "../../../core/services/event.service";
import { NavBarOptionModel } from "../../../core/models/navBarOption.model";
import { DialogService } from "../../../core/services/dialog.service";
import { KnowledgeService } from "../../../core/services/knowledge.service";
import { AlertService } from "../../../core/services/alert.service";
import { ConfigService } from "../../../core/services/config.service";
import { KnowledgeModel } from "../../../core/models/knowledge/knowledge.model";

@Component({
    selector: 'app-knowledge',
    templateUrl: './knowledge.component.html',
    styleUrl: './knowledge.component.scss'
})
export class KnowledgeComponent implements OnInit, OnDestroy {
    preparingKnowledgeImage: string = 'assets/icons/preparing.svg';
    warningKnowledgeImage: string = 'assets/icons/warning.svg';

    createKnowledgeButton: string = 'Create new knowledge';

    descriptionSection: string[] = [
        `Welcome to the Learning Hub!`,
        `Create knowledge through multiple channels, which can then be assigned to the desired Assistant.`];

    noSearchResultSection: string[] = [
        `No search results found!`,
        `Try adjusting your search criteria or create new knowledge if it doesn't exist.`
    ]

    knowledgeList: KnowledgeModel[] = [ ];
    perPage = 10; // Number of knowledgeList per page
    currentPage = 1;
    total = 0;
    searchValue: string = '';

    selectedKnowledgeSubscription!: Subscription;
    searchValueSubscription!: Subscription;

    constructor (
        private title: Title,
        private event: EventService,
        private dialog: DialogService,
        private knowledgeService: KnowledgeService,
        private alert: AlertService,
        private config: ConfigService) {
        this.title.setTitle('Knowledge');
    }

    async ngOnInit() {
        this.alert.showLoading();
        this.event.setCurrentTab('Learning Hub');

        const option: NavBarOptionModel = { search: 'Type here to search knowledge...', create: 'Create new knowledge' };
        this.event.setContentFromNavbarOption(option)

        await this.config.fetchRequirements();
        await this.loadKnowledgeList();

        this.selectedKnowledgeSubscription = this.knowledgeService.getKnowledgePaginationResponse().subscribe(knowledge => {
            if (knowledge != null) {
                this.total = knowledge.total;
                this.perPage = knowledge.size;
                this.currentPage = knowledge.page;
                this.knowledgeList = knowledge.knowledge;
            }
        })

        this.searchValueSubscription = this.event.getSearchValue().subscribe(search => {
            if (search != null) {
                this.searchValue = search;
            }
        })
    }

    async loadKnowledgeList() {
        const response = await this.knowledgeService.getKnowledgePaginationList(this.currentPage, this.perPage, this.searchValue);

        if (response.status !== 200) {
            this.alert.showError('Error', response.message);
            return;
        }

        if (response.body && response.body.knowledge && response.body.knowledge.length === 0 && this.currentPage > 1) {
            this.currentPage--;
            await this.loadKnowledgeList();
        } else {
            this.alert.close();
            this.knowledgeService.setKnowledgePaginationResponse(response.body);
        }
    }

    addKnowledge() {
        this.dialog.openCreateKnowledgeDialog()
    }

    async updateKnowledge(knowledge: KnowledgeModel) {
        this.dialog.openEditKnowledgeDialog(knowledge);
    }

    async deleteKnowledge(knowledge: KnowledgeModel) {
        this.alert.showDelete('Delete Knowledge?', 'Knowledge will be permanently deleted.')
        .then(async (result: { isConfirmed: any; }) => {
            if (result.isConfirmed) {
                if (knowledge != null && knowledge.id) {
                    this.alert.showLoading();
                    const response = await this.knowledgeService.deleteKnowledge(knowledge.id);

                    if (response.status !== 200) {
                        this.alert.showError('Error', response.message);
                        return;
                    }

                    await this.loadKnowledgeList();
                }
            }
        });
    }

    get totalPages() {
        return Math.ceil(this.total / this.perPage);
    }

    get paginatedKnowledgeList() {
        return this.knowledgeList;
    }

    changePage(page: number | string) {
        if (typeof page === 'number') {
            this.currentPage = page;
            this.loadKnowledgeList();
        }
    }

    isKnowledgeListEmpty(): boolean {
        return !(this.knowledgeList && this.knowledgeList.length > 0);
    }

    get paginationRange() {
        const totalPages = this.totalPages;
        const currentPage = this.currentPage;
        const delta = 2; // Number of pages to show before and after the current page
        const range = [];

        for (let i = Math.max(2, currentPage - delta); i <= Math.min(totalPages - 1, currentPage + delta); i++) {
            range.push(i);
        }

        if (currentPage - delta > 2) {
            range.unshift('...');
        }
        if (currentPage + delta < totalPages - 1) {
            range.push('...');
        }

        range.unshift(1);
        if (totalPages > 1) {
            range.push(totalPages);
        }

        return range;
    }

    ngOnDestroy(): void {
        this.selectedKnowledgeSubscription.unsubscribe();
        this.searchValueSubscription.unsubscribe();
    }
}
