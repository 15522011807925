import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";

import { CreatedAssistantModalComponent } from "../../shared/components/created-assistant-modal/created-assistant-modal.component";
import { AddKnowledgeToAssistantModalComponent } from "../../shared/components/add-knowledge-to-assistant-modal/add-knowledge-to-assistant-modal.component";
import { EntityModalComponent } from "../../shared/components/entity-modal/entity-modal.component";
import { TripletModalComponent } from "../../shared/components/triplet-modal/triplet-modal.component";
import { ActionModalComponent } from "../../shared/components/action-modal/action-modal.component";
import { CreateAssistantModalComponent } from "../../shared/components/create-assistant-modal/create-assistant-modal.component";
import { EditAssistantModalComponent } from "../../shared/components/edit-assistant-modal/edit-assistant-modal.component";
import { CreateKnowledgeModalComponent } from "../../shared/components/create-knowledge-modal/create-knowledge-modal.component";
import { KnowledgeModel, KnowledgeTripletModel } from "../models/knowledge/knowledge.model";
import { RelationModalComponent } from "../../shared/components/relation-modal/relation-modal.component";
import { EditKnowledgeModalComponent } from "../../shared/components/edit-knowledge-modal/edit-knowledge-modal.component";
import { ActionModel } from "../models/action/action.model";
import { AddActionToAssistantModalComponent } from "../../shared/components/add-action-to-assistant-modal/add-action-to-assistant-modal.component";
import { DesignsModalComponent } from "../../shared/components/designs-modal/designs-modal.component";
import { SoonModalComponent } from "../../shared/components/soon-modal/soon-modal.component";
import {
    SelectAssistantModalComponent
} from "../../shared/components/select-assistant-modal/select-assistant-modal.component";
import { ContactUsModalComponent } from "../../shared/components/contact-us-modal/contact-us-modal.component";
import {
    AttachmentPreviewModalComponent
} from "../../shared/components/attachment-preview-modal/attachment-preview-modal.component";
import { CommentModalComponent } from "../../shared/components/comment-modal/comment-modal.component";

// MatDialog - automatically handles the instantiation of new dialog instances.
// When u call dialog.open() method, MatDialog always create a new instance of MatDialogRef for that dialog.
// This ensures that each dialog operates independently, even if multiple dialogs are opened at the same time.

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    private dialogRef: MatDialogRef<any> | null = null;
    private assignKnowledgedialogRef: MatDialogRef<any> | null = null;
    private assignActiondialogRef: MatDialogRef<any> | null = null;

    constructor(private dialog: MatDialog) {}

    open(component: any, width: string, height: string, maxWidth?: string, maxHeight?: string) {
        if (!this.dialogRef) {
            const dialogConfig = this.createDialogConfig(width, height, maxWidth, maxHeight);
            this.dialogRef = this.dialog.open(component, dialogConfig);
        }

        return this.dialogRef;
    }

    createDialogConfig(width: string, height: string, maxWidth?: string, maxHeight?: string) {
        const dialogConfig = new MatDialogConfig();

        if (maxWidth) {
            dialogConfig.maxWidth = maxWidth;
        }

        if (maxHeight) {
            dialogConfig.maxHeight = maxHeight;
        }

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = width;
        dialogConfig.height = height;
        dialogConfig.backdropClass = 'blurBackground';
        return dialogConfig;
    }

    close(): void {
        if (this.dialogRef) {
            this.dialogRef.close();
            this.dialogRef = null;
        }
    }

    closeNestedDialog(dialogRef: any): void {
        if (dialogRef) {
            dialogRef.close();
            dialogRef = null;
        }
    }

    // TODO create one dialog for create and edit
    openCreateAssistantDialog(): void {
        if (this.dialogRef) {
            this.close();
        }

        this.open(CreateAssistantModalComponent, '750px', 'unset', '750px', '100%');
    }

    openEditAssistantDialog(): void {
        this.open(EditAssistantModalComponent, '750px', 'unset', '750px', '100%');
    }

    openSelectAssistantDialog(): void {
        this.open(SelectAssistantModalComponent, '80%', '100%', '1250px', '890px');
    }

    openAddKnowledgeToAssistantDialog(assistantData: any): MatDialogRef<any> {
        const dialogConfig = this.createDialogConfig('80%', '100%', '1250px', '890px');
        dialogConfig.data = assistantData;
        this.assignKnowledgedialogRef = this.dialog.open(AddKnowledgeToAssistantModalComponent, dialogConfig);
        return this.assignKnowledgedialogRef;
    }

    openAddActionToAssistantDialog(assistantData: any): MatDialogRef<any> {
        const dialogConfig = this.createDialogConfig('80%', '100%', '1250px', '890px');
        dialogConfig.data = assistantData;
        this.assignActiondialogRef = this.dialog.open(AddActionToAssistantModalComponent, dialogConfig);
        return this.assignActiondialogRef;

        /*const dialogConfig = this.createDialogConfig('80%', '90%', '1250px', '850px');
        dialogConfig.data = assistantData;
        return this.dialog.open(AddActionToAssistantModalComponent, dialogConfig);*/
    }

    openSuccessCreatedAssistantDialog(assistantId: string, agentName: string): MatDialogRef<any> {
        const dialogConfig = this.createDialogConfig('650px', 'auto');
        dialogConfig.data = { id: assistantId, name: agentName };
        return this.dialog.open(CreatedAssistantModalComponent, dialogConfig);
    }

    openAssistantDesignsDialog(): void {
        this.open(DesignsModalComponent, '650px', 'auto');
    }

    openCreateKnowledgeDialog(): void {
        if (this.assignKnowledgedialogRef) {
            this.closeNestedDialog(this.assignKnowledgedialogRef);
        }

        this.open(CreateKnowledgeModalComponent, '80%', 'unset', '1250px', '100%');
    }

    openSoonDialog(): void {
        this.open(SoonModalComponent, '800px', 'auto');
    }

    openContactUsDialog(): void {
        this.open(ContactUsModalComponent, '840px', 'auto');
    }

    openShowCommentDialog(): MatDialogRef<any> {
        const dialogConfig = this.createDialogConfig('720px', 'auto');
        return this.dialog.open(CommentModalComponent, dialogConfig);
    }

    openEditKnowledgeDialog(knowledge: KnowledgeModel): MatDialogRef<any> {
        //this.open(EditKnowledgeModalComponent, '80%', '90%');

        const dialogConfig = this.createDialogConfig('80%', 'unset', '1250px', '100%');
        dialogConfig.data = knowledge;
        return this.dialog.open(EditKnowledgeModalComponent, dialogConfig);
    }

    openActionDialog(action?: ActionModel): MatDialogRef<any> { // create action and edit action dialog
        if (this.assignActiondialogRef) {
            this.closeNestedDialog(this.assignActiondialogRef);
        }

        const dialogConfig = this.createDialogConfig('80%', 'unset', '1250px', '100%');
        dialogConfig.data = action;
        return this.dialog.open(ActionModalComponent, dialogConfig);
    }

    openTripletDialog(triplet?: KnowledgeTripletModel): MatDialogRef<any> { // create triplet and edit triplet dialog
        const dialogConfig = this.createDialogConfig('80%', '100%', '1250px', '650px');
        dialogConfig.data = triplet;
        return this.dialog.open(TripletModalComponent, dialogConfig);
    }

    openCreateEntityDialog(entityType: string): MatDialogRef<any> { // create and edit dialog
        const dialogConfig = this.createDialogConfig('720px', 'auto', '720px', '700px');
        //dialogConfig.maxHeight = '90%';
        dialogConfig.data = entityType;
        return this.dialog.open(EntityModalComponent, dialogConfig);
    }

    openCreateRelationDialog(): MatDialogRef<any> { // create and edit dialog
        const dialogConfig = this.createDialogConfig('720px', 'auto');
        dialogConfig.maxHeight = '90%';
        return this.dialog.open(RelationModalComponent, dialogConfig);
    }

    openPreviewAttachmentDialog(attachment: File): MatDialogRef<any> { // create and edit dialog
        const dialogConfig = this.createDialogConfig('auto', 'auto', 'unset', '700px');
        dialogConfig.data = attachment;
        return this.dialog.open(AttachmentPreviewModalComponent, dialogConfig);
    }
}
