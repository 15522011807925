<div class="parent-container" (keyup.escape)="closeDialog()">
    <div class="dialog-header">
        <h2>{{ dialogTitle }}</h2>
        <button id="id-edit-close-assistant-dialog" type="button" class="close-button" (click)="closeDialog()">
            <app-close></app-close>
        </button>
    </div>
    <mat-dialog-content>
        <div class="dialog-container">
            <div>
                <div class="form-container">
                    <label>{{ assistantLabels[0] }}</label>
                    <div class="single-container">
                        <input type="text" id="id-edit-assistant-name" name="edit-assistant-name" [(ngModel)]="assistantName" [placeholder]="assistantLabels[2]"/>
                    </div>
                </div>
    <!--            <div class="form-container">-->
    <!--                <label>{{ assistantLabels[1] }}</label>-->
    <!--                <div class="single-container">-->
    <!--                    <input type="text" id="id-edit-assistant-role" name="edit-assistant-role" [(ngModel)]="assistantRole" [placeholder]="assistantLabels[3]"/>-->
    <!--                </div>-->
    <!--            </div>-->
                <div class="form-container">
                    <label>{{ knowledgeLabels[0] }}</label>
                    <div class="common-container">
                        <div class="common-inside-container">
                            <span class="center-content-container">
                                <input type="radio" role="switch" id="edit-knowledge-scope-controlled" name="edit-knowledge-scope-option" [(ngModel)]="knowledgeScope" value="controlled" checked>
                                <label class="radio-option" for="edit-knowledge-scope-controlled">{{ knowledgeLabels[1] }}</label>
                                <span class="radio-description">{{ knowledgeLabels[2] }}</span>
                            </span>
                        </div>
                        <hr>
                        <div class="common-inside-container">
                            <span class="center-content-container">
                                <input type="radio" role="switch" id="edit-knowledge-scope-mixed" name="edit-knowledge-scope-option" [(ngModel)]="knowledgeScope" value="mixed">
                                <label class="radio-option" for="edit-knowledge-scope-mixed">{{ knowledgeLabels[3] }}</label>
                                <span class="radio-description">{{ knowledgeLabels[4] }}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="form-container">
                    <label>{{ communicationLabels[0] }}</label>
                    <div class="common-container">
                        <div class="common-inside-container">
                            <span class="center-content-container">
                                <input type="radio" role="switch" id="edit-communication-style-formal" name="edit-communication-style-option" [(ngModel)]="communicationStyle" value="formal" checked>
                                <label class="radio-option" for="edit-communication-style-formal">{{ communicationLabels[1] }}</label>
                                <span class="radio-description">{{ communicationLabels[2] }}</span>
                            </span>
                        </div>
                        <hr>
                        <div class="common-inside-container">
                            <span class="center-content-container">
                                <input type="radio" role="switch" id="edit-communication-style-informal" name="edit-communication-style-option" [(ngModel)]="communicationStyle" value="informal">
                                <label class="radio-option" for="edit-communication-style-informal">{{ communicationLabels[3] }}</label>
                                <span class="radio-description">{{ communicationLabels[4] }}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="form-container">
                    <label>{{ similarityLabel }}</label>
                    <div class="similarity-range-container">
                        <app-similarity-threshold-range [(ngModel)]="similarityThreshold"></app-similarity-threshold-range>
                    </div>
                    <div class="labels-similarity-range">
                        <span class="label min-label">0%</span>
                        <span class="label max-label">100%</span>
                    </div>
                </div>
                <div class="form-container">
                    <label>{{ behaviorLabels[0] }}</label>
                    <div class="single-margin-container">
                        <span class="center-content-container">
                            <input type="radio" role="switch" id="edit-behavior-friendly" name="edit-behavior-option" [(ngModel)]="behavior" value="friendly">
                            <label class="radio-option" for="edit-behavior-friendly">{{ behaviorLabels[1] }}</label>
                            <span class="radio-description">{{ behaviorLabels[2] }}</span>
                        </span>
                    </div>
                    <div class="single-margin-container">
                        <span class="center-content-container">
                            <input type="radio" role="switch" id="edit-behavior-curious" name="edit-behavior-option" [(ngModel)]="behavior" value="curious">
                            <label class="radio-option" for="edit-behavior-curious">{{ behaviorLabels[3] }}</label>
                            <span class="radio-description">{{ behaviorLabels[4] }}</span>
                        </span>
                    </div>
                    <div class="single-margin-container">
                        <span class="center-content-container">
                            <input type="radio" role="switch" id="edit-behavior-creative" name="edit-behavior-option" [(ngModel)]="behavior" value="creative">
                            <label class="radio-option" for="edit-behavior-creative">{{ behaviorLabels[5] }}</label>
                            <span class="radio-description">{{ behaviorLabels[6] }}</span>
                        </span>
                    </div>
                    <div class="single-margin-container">
                        <span class="center-content-container">
                            <input type="radio" role="switch" id="edit-behavior-humorous" name="edit-behavior-option" [(ngModel)]="behavior" value="humorous">
                            <label class="radio-option" for="edit-behavior-humorous">{{ behaviorLabels[7] }}</label>
                            <span class="radio-description">{{ behaviorLabels[8] }}</span>
                        </span>
                    </div>
                </div>
                <div class="form-container">
                    <label>{{ answerLabel }}</label>
                    <div class="answer-range-container">
                        <app-answer-threshold-range [(ngModel)]="answerLengthThreshold"></app-answer-threshold-range>
                    </div>
                    <div class="labels-answer-range">
                        <span class="label min-label">Short (20 words)</span>
                        <span class="label max-label">Long (150 words)</span>
                    </div>
                </div>
                <div class="form-container">
                    <label>{{ voiceLabels[0] }}</label>
                    <div class="single-container languages-container">
                        <div class="language-list-title">
                            <input type="text" id="id-edit-selected-languages" name="edit-selected-languages" placeholder="{{ voiceLabels[1] }}" [value]="selectedLanguages" readonly multiple />
                        </div>

                        <ul class="available-items">
                            <li *ngFor="let language of languages">
                                <div class="language-list-item" data-id="{{ language.id }}">
                                    <input type="checkbox" [value]="language.name" [id]="'edit-language-' + language.name" [(ngModel)]="language.selected" (change)="toggleVoices(language)">
                                    <label [for]="'edit-language-' + language.name">{{ language.name }}</label>
                                </div>
                                <div class="language-list-item">
                                    <span class="language-voice-item" *ngFor="let voice of language.voices">
                                        <input type="radio" [id]="'edit-voice-' + language.id + '-' + voice.id" [name]="'voice-group-' + language.id"
                                               [disabled]="!language.selected" [(ngModel)]="language.selectedVoice" [value]="voice.gender">
                                        <label [for]="'edit-voice-' + language.id + '-' + voice.id"> {{ voice.gender }} </label>
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="form-container">
                    <label>{{ shortcutLabels[0] }}</label>
                    <div class="single-margin-container">
                        <span class="center-content-container">
                            <input type="text" id="id-edit-shortcut-first" name="edit-shortcut-first-name" [(ngModel)]="shortcutFirst" [placeholder]="shortcutLabels[1]" maxlength="47"/>
                            <label for="id-edit-shortcut-first" class="shortcut-lang">{{ shortcutFirst.length }}/47</label>
                        </span>
                    </div>
                    <div class="single-margin-container">
                        <span class="center-content-container">
                            <input type="text" id="id-edit-shortcut-second" name="edit-shortcut-second-name" [(ngModel)]="shortcutSecond" [placeholder]="shortcutLabels[2]" maxlength="47"/>
                            <label for="id-edit-shortcut-second" class="shortcut-lang">{{ shortcutSecond.length }}/47</label>
                        </span>
                    </div>
                    <div class="single-margin-container">
                        <span class="center-content-container">
                            <input type="text" id="id-edit-shortcut-third" name="edit-shortcut-third-name" [(ngModel)]="shortcutThird" [placeholder]="shortcutLabels[3]" maxlength="47"/>
                            <label for="id-edit-shortcut-third" class="shortcut-lang">{{ shortcutThird.length }}/47</label>
                        </span>
                    </div>
                    <div class="single-margin-container">
                        <span class="center-content-container">
                            <input type="text" id="id-edit-shortcut-fourth" name="edit-shortcut-fourth-name" [(ngModel)]="shortcutFourth" [placeholder]="shortcutLabels[4]" maxlength="47"/>
                            <label for="id-edit-shortcut-fourth" class="shortcut-lang">{{ shortcutFourth.length }}/47</label>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="dialog-footer">
        <button id="id-edit-assistant-button" (click)="editAssistant()" class="create-button" [disabled]="!isFormValid">{{ changesButton }}</button>
    </mat-dialog-actions>
</div>
