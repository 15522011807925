<div class="parent-container" (keyup.escape)="closeDialog()">
    <div class="dialog-header" >
        <h2>{{ dialogTitle }}</h2>
        <button id="id-close-action-dialog" type="button" class="close-button" (click)="closeDialog()">
            <app-close></app-close>
        </button>
    </div>
    <mat-dialog-content>
        <div class="dialog-container">
            <div class="action-content">
                <form [formGroup]="actionForm">
                    <div class="form-container">
                        <label for="id-action-name">{{ actionLabels[0] }}</label>
                        <div class="action-name" [class.error]="isFieldInvalid('actionName')">
                            <input id="id-action-name"
                                   type="text"
                                   name="action-name"
                                   formControlName="actionName"
                                   [placeholder]="actionLabels[2]"
                                   required
                                   minlength="3" />
                        </div>
                        <div class="mat-error-container" *ngIf="isFieldInvalid('actionName')">
                            <mat-error *ngIf="getActionFormElement('actionName')?.hasError('required')">
                                {{ errorNameRequiredMessage }}
                            </mat-error>
                            <mat-error *ngIf="getActionFormElement('actionName')?.hasError('minlength')">
                                {{ errorMinLengthMessage }}
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-container">
                        <label for="id-action-description">{{ actionLabels[1] }}</label>
                        <div class="action-description" [class.error]="isFieldInvalid('actionDescription')">
                            <input id="id-action-description"
                                   type="text"
                                   name="action-description"
                                   [placeholder]="actionLabels[3]"
                                   formControlName="actionDescription"
                                   required />
                        </div>
                        <div class="mat-error-container" *ngIf="isFieldInvalid('actionDescription')">
                            <mat-error *ngIf="getActionFormElement('actionDescription')?.hasError('required')">
                                {{ errorDescriptionRequiredMessage }}
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-container">
                        <label>{{ componentLabel }}</label>
                        <div class="components-container">
                            <button id="id-header-component" type="button" (click)="selectComponent('Header')"
                                    class="component-button" [class.active]="selectedComponent === 'Header'">{{ componentSelection[0] }}</button>
                            <button id="id-parameter-component" type="button" (click)="selectComponent('Parameter')"
                                    class="component-button" [class.active]="selectedComponent === 'Parameter'">{{ componentSelection[1] }}</button>
                         </div>
                    </div>
                    <div *ngIf="selectedComponent === 'Header'">
                        <div class="form-container">
                            <label>{{ methodLabel }}</label>
                            <div class="method-container" [class.error]="isFieldInvalid('actionUrl')">
                                <div class="custom-dropdown">
                                    <button class="dropdown-button" (click)="toggleDropdown()">
                                        <app-arrow></app-arrow>
                                        <span>{{ actionMethod }}</span>
                                    </button>
                                    <div class="dropdown-content" *ngIf="isDropdownOpen">
                                        <div class="dropdown-item" *ngFor="let method of methods" (click)="selectMethod(method)" >{{ method }}</div>
                                    </div>
                                </div>
                                <input id="id-action-url"
                                       type="text"
                                       name="action-url"
                                       [placeholder]="methodEnterLabel"
                                       formControlName="actionUrl"/>
                            </div>
                            <div class="mat-error-container" *ngIf="isFieldInvalid('actionUrl')">
                                <mat-error *ngIf="getActionFormElement('actionUrl')?.hasError('invalidUrl')">
                                    {{ errorUrlMessage }}
                                </mat-error>
                            </div>
                        </div>
                    </div>
                </form>
                <div *ngIf="selectedComponent === 'Header'">
                    <div class="form-container">
                        <div class="header-title-container">
                            <label>{{ headerLabels[0] }}</label>
                            <label>{{ headerLabels[1] }}</label>
                        </div>
                        <!-- Add headers -->
                        <div class="header-container"
                             *ngFor="let header of headers; let i = index"
                             [ngClass]="{'hover-active': i !== 0}"
                        >
                            <div class="custom-header-container">
                                <div class="single-container">
                                    <input type="text" id="id-header-name-{{i}}" name="header-name-{{i}}"
                                           [(ngModel)]="header.headerKey" [placeholder]="headerLabels[2]" />
                                </div>
                            </div>
                            <div class="custom-header-container">
                                <div class="single-container">
                                    <input type="text" id="id-header-value-{{i}}" name="header-value-{{i}}"
                                           [(ngModel)]="header.headerValue" [placeholder]="headerLabels[3]" />
                                </div>
                            </div>
                            <div class="delete-container" *ngIf="i !== 0">
                                <button type="button" class="delete-button" (click)="removeHeader(i)">
                                    <app-delete></app-delete>
                                </button>
                            </div>
                        </div>
                    </div>
                    <button id="id-add-header-button" type="button" (click)="addHeader()" class="header-button" [disabled]="!checkHeaderFilled">
                        <app-plus></app-plus>
                        <span>{{ addNewHeaderButton }}</span>
                    </button>
                    <div class="form-container">
                        <label>{{ statusLabel[0] }}</label>
                        <div class="status-check" (click)="toggleCheck()">
                            <span [ngClass]="isChecked ? 'checked-icon' : 'unchecked-icon'"></span>
                            <label>{{ statusLabel[1] }}</label>
                        </div>
                    </div>
                </div>
                <!-- Add parameters -->
                <div *ngIf="selectedComponent === 'Parameter'" class="parameter-container">
                    <div *ngFor="let parameter of parameters; let parameter_i = index"
                        class="param-new-container">
                        <div class="border-parameter-container" [ngClass]="{'hover-active': parameter_i !== 0}">
                            <div class="form-container">
                                <label>{{ parameterLabels[0] }}</label>
                                <div class="single-container">
                                    <input type="text" id="id-parameter-name" name="parameter-name"
                                           [(ngModel)]="parameter.name" [placeholder]="parameterLabels[3]"/>
                                </div>
                            </div>
                            <div class="form-container">
                                <label>{{ parameterLabels[1] }}</label>
                                <div class="single-container">
                                    <input type="text" id="id-parameter-description" name="parameter-description"
                                           [(ngModel)]="parameter.description" [placeholder]="parameterLabels[4]"/>
                                </div>
                            </div>
                            <div class="form-container">
                                <label>{{ parameterLabels[2] }}</label>
                                <div class="single-container">
                                    <input type="text" id="id-parameter-type" name="parameter-type"
                                           [(ngModel)]="parameter.parameterType" [placeholder]="parameterLabels[5]"/>
                                </div>
                            </div>
                            <!-- Add values -->
                            <div class="form-container">
                                <label>{{ parameterValues[0] }}</label>
                                <div class="value-wrap-container">
                                    <div class="values-container">
                                        <div class="value-container"
                                             *ngFor="let value of parameter.parameterValues; let i = index"
                                             [ngClass]="{'hover-active': i !== 0}"
                                        >
                                            <div class="single-container">
                                                <input type="text" id="id-parameter-value-{{i}}" name="parameter-value-{{i}}"
                                                       [(ngModel)]="value.parameterValue" [placeholder]="parameterValues[1]" />
                                            </div>
                                            <div class="delete-container" *ngIf="i !== 0">
                                                <button type="button" class="delete-button" (click)="removeParameterValue(parameter, i)">
                                                    <app-delete></app-delete>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="button-value-container">
                                        <button id="id-add-value" type="button" (click)="addValue(parameter)" class="value-button" [disabled]="!checkValueFilled(parameter, parameter_i)">{{ addValueButton }}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="close-container" *ngIf="parameter_i !== 0">
                                <button type="button" class="close-param-button" (click)="removeParameter(parameter_i)">
                                    <app-close width="14"></app-close>
                                </button>
                            </div>
                        </div>
                    </div>
                    <button id="id-add-parameter" type="button" (click)="addParameter()" class="header-button" [disabled]="!checkParameterFilled">{{ addParameterButton }}</button>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="dialog-footer">
        <button id="id-create-action-button" type="button" (click)="submitAction()" class="create-button" [disabled]="!isFormValid">{{ buttonLabel }}</button>
    </mat-dialog-actions>
</div>
