import { Component } from '@angular/core';

import { NavBarElementDisplayFormat } from "../../../core/models/navBarElement.model";
import { RouterService } from "../../../core/services/router.service";
import { Subscription } from "rxjs";
import { EventService } from "../../../core/services/event.service";

@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrl: './nav-bar.component.scss'
})
export class NavBarComponent {
    links: NavBarElementDisplayFormat[] = [
        { name: 'Assistants', href: 'assistants' },
        { name: 'Learning Hub', href: 'knowledge' },
        { name: 'Actions', href: 'action' }
    ];

    backButton: string = 'Back';
    backButtonImage: string = 'assets/icons/back.svg';

    activeIndex: number = 0;

    currentTabSubscription!: Subscription;

    constructor(private router: RouterService, private event: EventService) {
        this.currentTabSubscription = this.event.getCurrentTab().subscribe(tab => {
            const index = this.links.findIndex(link => link.name === tab);

            if (index) {
                this.activeIndex = index;
            } else {
                this.activeIndex = 0;
            }
        });
    }

    back() {
        this.router.goToLanding();
    }

    setActiveLink(index: number, name: string) {
        this.activeIndex = index;
    }
}
