import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subscription } from "rxjs";

import { AlertService } from "../../../core/services/alert.service";
import { DialogService } from "../../../core/services/dialog.service";
import { KnowledgeService } from "../../../core/services/knowledge.service";
import { KnowledgeId, KnowledgeModel } from "../../../core/models/knowledge/knowledge.model";
import { AssistantService } from "../../../core/services/assistant.service";
import { EventService } from "../../../core/services/event.service";
import { NavBarOptionModel } from "../../../core/models/navBarOption.model";

@Component({
    selector: 'app-createNewAssistant-knowledge-to-assistant-modal',
    templateUrl: './add-knowledge-to-assistant-modal.component.html',
    styleUrl: './add-knowledge-to-assistant-modal.component.scss'
})
export class AddKnowledgeToAssistantModalComponent implements OnInit, OnDestroy {
    dialogTitle = 'Manage knowledge to ';
    saveButton = 'Save changes';
    //loupeImage: string = 'assets/icons/look.svg';
    noSearchResultSection: string[] = [
        `No search results found!`,
        `Try adjusting your search criteria or create new knowledge if it doesn't exist.`
    ]

    assistantData: any
    knowledges: KnowledgeModel[] = [ ];
    assignableKnowledge: any[] = [ ];
    checkedKnowledgeIds: string[] = [];
    //isFormValid: boolean = false;
    searchValue: string = '';
    checkedKnowledgeMap: { [key: string]: boolean } = {};

    assistantKnowledgeSubscription!: Subscription;
    searchValueSubscription!: Subscription;

    @HostListener('document:keydown.escape', ['$event'])
    handleEscape(event: KeyboardEvent) {
        this.closeDialog();
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any | null,
        private alert: AlertService,
        private dialog: DialogService,
        private knowledge: KnowledgeService,
        private assistant: AssistantService,
        private event: EventService,
        private dialogRef: MatDialogRef<AddKnowledgeToAssistantModalComponent>) {
    }

    async ngOnInit() {
        this.assistantData = this.data;
        this.dialogTitle = this.dialogTitle + this.assistantData.name;

        const option: NavBarOptionModel = { search: 'Type here to search knowledge...', create: 'Create new knowledge' };
        this.event.setContentFromNavbarOption(option);

        await this.loadKnowledge();

        this.assistantKnowledgeSubscription = this.knowledge.getAssistantKnowledgeResponse().subscribe(knowledges => {
            if (knowledges != null) {
                this.knowledges = knowledges;
                this.checkAssistantKnowledge();
            }
        })

        this.searchValueSubscription = this.event.getSearchValue().subscribe(search => {
            if (search != null) {
                this.searchValue = search;

                // Filter assignableKnowledge based on search, but use state from map
                this.assignableKnowledge = this.knowledges
                .filter(knowledge => knowledge.name.includes(search))
                .map(knowledge => ({
                    ...knowledge,
                    assignedToAssistant: this.checkedKnowledgeMap[knowledge.id] || false
                }));
            }
        });
    }

    async loadKnowledge() {
        this.alert.showLoading();
        const response = await this.knowledge.getKnowledgeList('');
        this.knowledges = response.body;

        // Update checked items map
        this.knowledges.forEach(knowledge => {
            this.checkedKnowledgeMap[knowledge.id] = this.assistantData.knowledge
                ? this.assistantData.knowledge.some((k: KnowledgeModel) => k.id === knowledge.id)
                : false;
        });

        this.knowledge.setAssistantKnowledgeResponse(this.knowledges);
        this.checkAssistantKnowledge();
        this.alert.close();
    }

    checkAssistantKnowledge() {
        this.assignableKnowledge = this.knowledges.map(knowledge => ({
            ...knowledge,
            assignedToAssistant: this.checkedKnowledgeMap[knowledge.id] || false
        }));
    }

    toggleCheckbox(knowledge: any, event: Event): void {
        knowledge.assignedToAssistant = !knowledge.assignedToAssistant;
        this.checkedKnowledgeMap[knowledge.id] = knowledge.assignedToAssistant;
    }

    addNewKnowledge() {
        this.dialog.openCreateKnowledgeDialog()
    }

    isAssignableKnowledgeListEmpty(): boolean {
        return !(this.assignableKnowledge && this.assignableKnowledge.length > 0);
    }

    async save() {
        this.alert.showLoading();

        try {
            const knowledgeIds: KnowledgeId[] = Object.keys(this.checkedKnowledgeMap)
            .filter(key => this.checkedKnowledgeMap[key])
            .map(id => ({ id }));

            const response = await this.knowledge.assignAssistantKnowledge(this.assistantData.id, knowledgeIds);
            if (response.status !== 200) {
                this.alert.showError('Error', response.message);
                return;
            }

            const getAgent = await this.assistant.fetchAssistant(this.assistantData.id);
            if (getAgent.status !== 200) {
                this.alert.showError('Error', getAgent.message);
                return;
            }

            this.assistant.setSelectedAssistantResponse(getAgent.body);

            this.closeDialog();
            this.alert.close();
            this.alert.showSucess('Knowledge assigned successfully.', 'Check your knowledge list in Assistant Knowledge tab.');
        } catch (error) {
            console.error('Error assigning knowledgeList:', error);
        }
    }


    closeDialog() {
        this.dialog.closeNestedDialog(this.dialogRef);
    }

    ngOnDestroy() {
        this.assistantKnowledgeSubscription.unsubscribe();
        this.searchValueSubscription.unsubscribe();
    }
}
