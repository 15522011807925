<div class="attachments-container">
    <label for="attachmentupload">{{ attachmentLabel }}</label>
    <form #attachmentupload id="attachmentupload" class="dropzone">
        <div class="dz-default dz-message">
            <div class="dz-button">
                <img [src]="attachmentDropImage" alt="Dropzone Icon" />
                <p class="attachment-browse">Drag and drop your file here<br> or use <b>Browse</b> button to upload.</p>
                <p class="attachments-extensions">Files supported: JPG, PNG, PDF, TXT, DOCX, PPTX, XLSX, CSV, MP4.<br /> Maximum file size: 50MB</p>
            </div>
        </div>
    </form>
</div>
