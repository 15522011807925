import { Component } from '@angular/core';

import { DialogService } from "../../../core/services/dialog.service";
import { AlertService } from "../../../core/services/alert.service";

@Component({
    selector: 'app-contact-us-modal',
    templateUrl: './contact-us-modal.component.html',
    styleUrl: './contact-us-modal.component.scss'
})
export class ContactUsModalComponent {
    firstName: string = '';
    lastName: string = '';
    contactUsMessage = '';
    email: string = '';
    phoneNumber: string = '';

    constructor(private dialog: DialogService, private alert: AlertService) {}

    closeDialog() {
        this.dialog.close();
    }

    send() {
        this.alert.showLoading();

        /*const response = await this.action.assignAssistantActions(this.assistantData.id, actionIds);
        if (response.status !== 200) {
            this.alert.showError('Error', response.message);
            return;
        }*/

        setTimeout(() => {
            this.closeDialog();
            this.alert.close();
            this.alert.showSucess('Successfully sent.', `Please check if you've received a confirmation email in your inbox.`);
        }, 2000);
    }

    get isFormValid(): boolean {
        return this.firstName.trim() !== '' &&
            this.lastName.trim() !== '' &&
            this.email.trim() !== '' &&
            this.phoneNumber.trim() !== '' &&
            this.contactUsMessage.trim() !== '';
    }
}
