<div class="box-landing">
    <app-header></app-header>
    <div class="landing-container">
        <div class="blue-lines-container">
            <img [src]="blueLinesImage" alt="Blue lines image" />
        </div>

       <h1 class="welcome-title">Welcome to ExaControl.</h1>
       <p class="welcome-description">Manage Assistants and their<br> knowledge or track Data Insights.</p>

        <div class="screens-container">
            <ul>
                <li id="id-assistant" (click)="openAssistant()">
                    <div class="screen-title-container">
                        <h3>{{ screenAssistant.title }}</h3>
                        <app-assistant-icon></app-assistant-icon>
                    </div>
                    <p>{{ screenAssistant.description }}</p>
                </li>
                <li id="id-data-insight" (click)="openDataInsight()">
                <div class="screen-title-container">
                        <h3>{{ screenDataInsight.title }}</h3>
                        <app-data-insight></app-data-insight>
                    </div>
                    <p>{{ screenDataInsight.description }}</p>
                </li>
            </ul>
        </div>

        <div class="help-container">
            <p>Need help?<br>Contact our support service to resolve any app-related problems.</p>
            <div class="button-container">
                <button id="id-contact-us" type="button" class="contact-us-button" (click)="contactUs()">Contact Us</button>
            </div>
        </div>

        <div class="green-lines-container">
            <img [src]="greenLinesImage" alt="Green lines image" />
        </div>
    </div>
</div>
