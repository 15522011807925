import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";

import { LoginComponent } from "./pages/no-auth-pages/login/login.component";
import { NotFoundComponent } from "./pages/no-auth-pages/not-found/not-found.component";
import { LostConnectionComponent } from "./pages/no-auth-pages/lost-connection/lost-connection.component";
import { AuthGuard } from "./shared/guards/auth.guard";
import { AuthDeactivateGuard } from "./shared/guards/auth-deactivate.guard";

export const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' }, // Is LoadingComponent needed?
    { path: 'lost-connection', component: LostConnectionComponent },
    { path: 'login', component: LoginComponent},
    {
        path: 'landing',
        loadChildren: () => import('././pages/auth-pages/auth-pages.module').then(m => m.AuthPagesModule),
        canActivate: [AuthGuard],
        canDeactivate: [AuthDeactivateGuard]
    },
    { path: '**', component: NotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
