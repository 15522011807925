<mat-dialog-content>
    <div class="dialog-container">
        <div class="dialog-soon-header">
            <h2>{{ dialogTitle }}</h2>
            <p>{{ dialogDescription }}</p>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="dialog-footer">
    <button id="id-soon-ok-button" type="button" (click)="closeDialog()" class="create-button">OK</button>
</mat-dialog-actions>
