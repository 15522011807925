import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import CodeMirror from "codemirror";
import 'codemirror/mode/yaml/yaml';
import * as yaml from 'js-yaml';

import { AssistantService } from "../../../core/services/assistant.service";
import { AgentConfigurationModel } from "../../../core/models/agent/agent-configuration.model";
import { AgentModel } from "../../../core/models/agent/agent.model";
import { AlertService } from "../../../core/services/alert.service";

@Component({
    selector: 'app-assistant-configuration',
    templateUrl: './assistant-configuration.component.html',
    styleUrl: './assistant-configuration.component.scss'
})
export class AssistantConfigurationComponent implements AfterViewInit {
    @ViewChild('yamlTextarea', { static: false }) yamlTextarea!: ElementRef;

    myCodeMirror: CodeMirror.Editor | undefined;
    yamlData: string = '';
    editorOptions = {maxLines: 30, printMargin: false}
    assistantConfiguration: AgentConfigurationModel | null = null;
    selectedAssistant: AgentModel | null = null;

    constructor(private assistant: AssistantService, private alert: AlertService) {}

    ngAfterViewInit(): void {
        this.assistant.getSelectedAssistantResponse().subscribe(selectedAssistant => {
            this.selectedAssistant = selectedAssistant;
            this.setAssistantConfiguration(selectedAssistant);

            if (this.yamlData) {
                this.initializeCodeMirror();
            }
        });
    }

    setAssistantConfiguration(selectedAssistant: any) {
        if (selectedAssistant) {
            const modelInstance = new AgentConfigurationModel();

            Object.keys(modelInstance).forEach((key) => {
                if (key in selectedAssistant) {
                    (modelInstance as any)[key] = selectedAssistant[key];
                }
            });

            this.assistantConfiguration = modelInstance;
            this.yamlData = yaml.dump(this.assistantConfiguration);
        }
    }

    initializeCodeMirror(): void {
        if (this.yamlTextarea) {
            const textArea = this.yamlTextarea.nativeElement as HTMLTextAreaElement;

            if (this.myCodeMirror) {
                this.myCodeMirror.setValue(this.yamlData);
            } else {
                this.myCodeMirror = CodeMirror.fromTextArea(textArea, {
                    lineNumbers: true,
                    mode: 'yaml',
                    theme: 'lucario',
                    ...this.editorOptions
                });
                this.myCodeMirror.setSize('100%', '50%');

                this.myCodeMirror.setValue(this.yamlData);
            }
        }
    }

    async editAssistantConfiguration() {
        if (this.myCodeMirror) {
            try {
                this.yamlData = this.myCodeMirror.getValue();
                const yamlObject = yaml.load(this.yamlData);

                if (this.selectedAssistant && this.selectedAssistant.id) {
                    this.alert.showLoading();

                    const assistantResponse = await this.assistant.updateAssistantConfiguration(this.selectedAssistant.id, yamlObject);

                    if (assistantResponse.status !== 200) {
                        this.alert.showError('Error', assistantResponse.message);
                        return;
                    }

                    const newAssistantResponse = await this.assistant.fetchAssistant(this.selectedAssistant.id);

                    if (newAssistantResponse.status !== 200) {
                        this.alert.showError('Error', newAssistantResponse.message);
                        return;
                    }

                    this.assistant.setSelectedAssistantResponse(newAssistantResponse.body);

                    this.alert.close();
                    this.alert.showSucess('Successfully updated!', `Check the Assistant's Configuration and modify it again if necessary.`);
                }
            } catch (error) {
                if (error instanceof yaml.YAMLException) {
                    this.alert.showError('YAML Error', 'There was an error parsing the YAML data.');
                } else {
                    this.alert.showError('Error', 'An unexpected error occurred.');
                }
            }
        }
    }
}
