import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-link',
    template: `
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="XMLID_105_">
                <path id="XMLID_106_" d="M8.61418 11.0607L6.16548 13.5094C6.16548 13.5094 6.16548 13.5095 6.16542 13.5095C6.16542 13.5095 6.16542 13.5095 6.16536 13.5095C5.15275 14.5222 3.50501 14.5223 2.49228 13.5095C2.00167 13.0189 1.73155 12.3667 1.73155 11.6729C1.73155 10.9793 2.00167 10.3271 2.49211 9.83652C2.49216 9.83647 2.49222 9.83641 2.49228 9.83635L4.94098 7.38759C5.27904 7.04948 5.27904 6.50127 4.94092 6.16321C4.60287 5.82515 4.05466 5.82515 3.71654 6.16321L1.26784 8.61197C1.26767 8.61214 1.2675 8.61237 1.26732 8.61255C0.450088 9.43007 0 10.517 0 11.6729C0 12.8292 0.450261 13.9163 1.2679 14.7339C2.11186 15.5778 3.22034 15.9998 4.32888 15.9998C5.43742 15.9998 6.54596 15.5778 7.3898 14.7339C7.38986 14.7339 7.38986 14.7338 7.38986 14.7338L9.83856 12.2851C10.1766 11.947 10.1766 11.3988 9.8385 11.0607C9.5005 10.7226 8.95235 10.7226 8.61418 11.0607Z" [attr.fill]="stroke"/>
                <path id="XMLID_107_" d="M16.0039 4.32681C16.0039 3.17054 15.5536 2.08347 14.736 1.26583C13.0481 -0.421972 10.3018 -0.421914 8.61408 1.26583C8.61402 1.26594 8.61391 1.266 8.61385 1.26612L6.16521 3.7147C5.82709 4.05276 5.82709 4.60103 6.16521 4.93908C6.33432 5.1082 6.55584 5.1927 6.77743 5.1927C6.99895 5.1927 7.22059 5.10814 7.38959 4.93908L9.83823 2.4905C9.83829 2.49038 9.8384 2.49032 9.83852 2.49021C10.8511 1.4776 12.4989 1.47754 13.5116 2.49021C14.0021 2.98081 14.2724 3.63309 14.2724 4.32681C14.2724 5.02047 14.0023 5.67263 13.5118 6.16323L13.5116 6.16341L11.0629 8.61217C10.7248 8.95022 10.7248 9.49843 11.063 9.83655C11.232 10.0056 11.4536 10.0902 11.6751 10.0902C11.8967 10.0902 12.1183 10.0056 12.2873 9.83655L14.736 7.38779C14.7362 7.38761 14.7364 7.38738 14.7366 7.38721C15.5538 6.56969 16.0039 5.48279 16.0039 4.32681Z" [attr.fill]="stroke"/>
                <path id="XMLID_108_" d="M4.94109 11.061C5.11014 11.23 5.33172 11.3146 5.55325 11.3146C5.77483 11.3146 5.99641 11.23 6.16547 11.061L11.063 6.16347C11.4011 5.82541 11.4011 5.2772 11.063 4.93909C10.7249 4.60103 10.1767 4.60103 9.8386 4.93909L4.94109 9.83655C4.60297 10.1747 4.60297 10.7229 4.94109 11.061Z" [attr.fill]="stroke"/>
            </g>
        </svg>
    `,
    styles: [`
        :host {
            --link-color: #3B4754;
        }
        svg g path {
            fill: var(--link-color);
        }
    `]
})
export class LinkComponent {
    @Input() stroke: string = 'var(--link-color)';
}
