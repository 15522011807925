import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { EventService } from "../../../core/services/event.service";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit, OnDestroy {
    companyName: string = "ExaControl";

    titleSection: string = '';

    titleSubscription!: Subscription;

    constructor(private event: EventService) { }

    ngOnInit(): void {
        this.titleSubscription = this.event.getCurrentTab().subscribe(title => {
            if (title) { this.titleSection = title; }
        });
    }

    ngOnDestroy() {
        if (this.titleSubscription) { this.titleSubscription.unsubscribe(); }
    }
}
