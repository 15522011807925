<div class="parent-container" (keyup.escape)="closeDialog()">
    <div class="dialog-header">
        <h2>{{ dialogTitle }}</h2>
        <button id="id-close-knowledge-dialog" type="button" class="close-button" (click)="closeDialog()">
            <app-close></app-close>
        </button>
    </div>
    <mat-dialog-content>
        <div class="dialog-container">
            <form [formGroup]="knowledgeForm">
                <div class="selection-container" [ngClass]="getClass()">
                    <div class="name-column">
                        <label for="id-knowledge-name">{{ nameLabel }}</label>
                        <input id="id-knowledge-name"
                           class="knowledge-name"
                           [class.error]="isFieldInvalid('knowledgeName')"
                           type="text"
                           name="knowledge-name"
                           [placeholder]="knowledgeNameLabel"
                           formControlName="knowledgeName"
                           required
                           minlength="3">
                        <div class="mat-error-container" *ngIf="isFieldInvalid('knowledgeName')">
                            <mat-error *ngIf="getKnowledgeFormElement('knowledgeName')?.hasError('required')">
                                {{ errorNameRequiredMessage }}
                            </mat-error>
                            <mat-error *ngIf="getKnowledgeFormElement('knowledgeName')?.hasError('minlength')">
                                {{ errorMinLengthMessage }}
                            </mat-error>
                        </div>
                    </div>

                    <div class="selection-column">
                        <label>{{ componentLabel }}</label>
                        <div class="selection-buttons-container">
                            <button id="id-text-selection" type="button" (click)="selectComponent('Text')"
                                    class="selection-button" [class.active]="selectedKnowledgeType === 'Text'">{{ componentSelection[0] }}</button>
                            <button id="id-file-selection" type="button" (click)="selectComponent('File')"
                                    class="selection-button" [class.active]="selectedKnowledgeType === 'File'">{{ componentSelection[1] }}</button>
                            <button id="id-triplets-selection" type="button" (click)="selectComponent('Triplets')"
                                    class="selection-button" [class.active]="selectedKnowledgeType === 'Triplets'">{{ componentSelection[2] }}</button>
                        </div>
                    </div>

                    <div class="component-container" [ngClass]="getClass()">
                        <! -- ADD TEXT SOURCE -->
                        <div *ngIf="selectedKnowledgeType === 'Text'" class="content-container">
                            <div class="textarea-container">
                                <label for="id-knowledge-text">{{ textLabel }}</label>
                                <textarea id="id-knowledge-text"
                                      class="knowledge-text"
                                      [class.error]="isFieldInvalid('knowledgeText')"
                                      name="knowledge-text"
                                      formControlName="knowledgeText"
                                      [placeholder]="textareaLabel"
                                      required>
                                </textarea>
                                <div class="mat-error-container" *ngIf="isFieldInvalid('knowledgeText')">
                                    <mat-error *ngIf="getKnowledgeFormElement('knowledgeText')?.hasError('required')">
                                        {{ errorTextRequiredMessage }}
                                    </mat-error>
                                </div>
                            </div>
                            <app-attachment-upload (uploadedDropzone)="handleAttachmentDropzone($event)"></app-attachment-upload>
                        </div>
                        <! -- ADD FILE SOURCE -->
                        <div *ngIf="selectedKnowledgeType === 'File'" class="content-container">
                            <app-file-upload (uploadedDropzone)="handleFileDropzone($event)"></app-file-upload>
                            <app-attachment-upload (uploadedDropzone)="handleAttachmentDropzone($event)"></app-attachment-upload>
                        </div>
                        <! -- ADD TRIPLET SOURCE -->
                        <app-triplet-knowledge *ngIf="selectedKnowledgeType === 'Triplets'" (uploadedTriplets)="handleTriplets($event)"></app-triplet-knowledge>
                    </div>
                </div>
            </form>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="dialog-footer">
        <button id="id-add-knowledge" type="button" class="add-button" (click)="addKnowledge()" [disabled]="!isFormValid">Add</button>
    </mat-dialog-actions>
</div>
