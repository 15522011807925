export const environment = {
    type: 'staging',
    apiUrl: 'orchestrator-service.dev.exafysolutions.ae',
    orchestratorUrl: 'orchestrator-service.dev.exafysolutions.ae',
    wssScheme: 'wss',
    httpScheme: 'https',
    design1: 'https://design1.dev.exafysolutions.ae/',
    design2: 'https://design2.dev.exafysolutions.ae/',
    design3: 'https://design3.dev.exafysolutions.ae/',
    design4: 'https://design4.dev.exafysolutions.ae/'
};
