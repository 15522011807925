<mat-dialog-content>
    <div class="dialog-container">
        <div class="dialog-designs-header">
            <h2>{{ dialogTitle }}</h2>
            <p>{{ dialogDescription }}</p>
            <button id="id-close-dialog" type="button" class="close-designs-button" (click)="closeDialog()">
                <app-close></app-close>
            </button>
        </div>
        <div class="dialog-designs-footer">
            <div class="assistant-design-container">
                <div class="design-container" *ngFor="let design of designs; let i = index">
                    <label>{{ design.label }}</label>
                    <div class="design-link-container">
                        <div class="copy-link-container">
                            <input type="text" [id]="'id-design' + (i + 1) + '-link'" [name]="'design' + (i + 1) + '-link'" [placeholder]="design.url" [value]="design.url" readonly/>
                            <button [id]="'id-copy-design' + (i + 1) + '-link'" type="button" class="copy-button" (click)="copyTextMessage(design.url, i)">
                                <app-copy *ngIf="!isLinkCopied[i]"></app-copy>
                                <app-correct *ngIf="isLinkCopied[i]"></app-correct>
                            </button>
                        </div>
                        <button [id]="'id-go-to-link-design' + (i + 1)" type="button" class="go-to-link-button" (click)="goToLink(design.url)">
                            {{ goToLinkButton }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
