import { Component, OnDestroy, OnInit } from '@angular/core';

import { DialogService } from "../../../core/services/dialog.service";
import { AlertService } from "../../../core/services/alert.service";
import { AssistantService } from "../../../core/services/assistant.service";
import { ConfigService } from "../../../core/services/config.service";
import { LanguageModel } from "../../../core/models/misc/language.model";
import { AgentModel } from "../../../core/models/agent/agent.model";
import { VoiceModel } from "../../../core/models/misc/voice.model";

@Component({
    selector: 'app-edit-assistant-modal',
    templateUrl: './edit-assistant-modal.component.html',
    styleUrl: './edit-assistant-modal.component.scss'
})
export class EditAssistantModalComponent implements OnInit, OnDestroy {
    dialogTitle = 'Edit Assistant';
    changesButton = 'Save changes';

    assistantLabels: string[] = ['Assistant name', 'Assistant role', 'Enter assistant name', 'Enter assistant role']
    knowledgeLabels: string[] = ['Knowledge Scope', 'Controlled', 'Utilizes only custom knowledge.', 'Mixed',
        'Combines both general and custom knowledge.'];
    similarityLabel: string = 'Similarity threshold for attachments (Higher percentage means fewer attachments shown)';
    communicationLabels: string[] = ['Communication style', 'Formal', 'Maintains professionalism, following social norms. ',
        'Informal', 'Keeps things casual and relaxed, using everyday language.'];
    behaviorLabels: string[] = ['Behavior', 'Friendly', 'Approaches interactions warmly, making others feel valued.', 'Curious',
        'Shows a strong interest in learning, often asking questions.', 'Creative',
        'Thinks innovatively, finding unique solutions to problems.', 'Humorous',
        'Injects humor into conversations, aiming to lighten the mood.'];
    answerLabel: string = 'Answer length (As the length of the answer increases, the response time also increases)';
    voiceLabels: string[] = ['Voices (First one selected is considered default)', 'Select language(s)'];
    shortcutLabels: string[] = ['Shortcuts', 'Type first shortcut', 'Type second shortcut', 'Type third shortcut', 'Type fourth shortcut']

    languages: LanguageModel[] = [];
    selectedLanguagesOrder: string[] = [];
    selectedLanguages: string = '';
    assistantId: string | undefined = '';
    assistantName: string = '';
    assistantRole: string = '';
    shortcutFirst: string = '';
    shortcutSecond: string = '';
    shortcutThird: string = '';
    shortcutFourth: string = '';
    knowledgeScope: string = 'controlled';
    similarityThreshold: number = 0;
    answerLengthThreshold: number = 0;
    communicationStyle: string = 'formal';
    behavior: string = 'friendly';

    constructor(private dialog: DialogService, private alert: AlertService, private assistant: AssistantService, private config: ConfigService) {
    }

    ngOnInit() {
        this.languages = this.config.getLanguages();

        this.assistant.getSelectedAssistantResponse().subscribe(async assistant => {
            if (assistant != null) {
                this.loadAssistantValues(assistant);
            }
        });
    }

    loadAssistantValues(assistant: AgentModel) {
        this.assistantId = assistant.id;
        this.assistantName = assistant.name;
        this.knowledgeScope = assistant.knowledgeScope.toLowerCase();
        this.similarityThreshold = assistant.similarityThreshold * 100;
        this.answerLengthThreshold = assistant.answerLength;
        this.communicationStyle = assistant.communicationStyle.toLowerCase();
        this.behavior = assistant.behavior.toLowerCase();

        assistant.shortcuts?.filter(shortcut => shortcut?.language?.locale === "en-US").forEach((shortcut, index) => {
            if (index === 0) {
                this.shortcutFirst = shortcut.name;
            } else if (index === 1) {
                this.shortcutSecond = shortcut.name;
            } else if (index === 2) {
                this.shortcutThird = shortcut.name;
            } else if (index === 3) {
                this.shortcutFourth = shortcut.name;
            }
        });

        // Checking selected languages from assistant
        // const selectedAssLangs = assistant.selectedLanguagesOrder.map((selectedLang: AgentLanguage) => selectedLang.name);
        this.languages.forEach(language => {
            if (assistant.languages !== undefined) {
                assistant.languages.forEach((selectedLang: LanguageModel) => {
                    if (selectedLang.id === language.id) {
                        language.selected = true;
                        language.selectedVoice = assistant.voices.find(voice => voice.languageLocale === selectedLang.locale)?.gender || '';
                    }
                });
            }
        });

        // Filled selected languages order
        this.languages.forEach(language => {
            if (language.selected) {
                this.addLanguageToOrder(language);
            }
        });

        console.log(this.languages)
    }

    async editAssistant() {
        this.alert.showSaveChanges('Save changes?', 'Existing settings will be replaced.', 'Save')
        .then(async (result: { isConfirmed: any; }) => {
            if (result.isConfirmed) {

                if (this.assistantId) {
                    this.alert.showLoading();
                    const assistant = this.createAssistantObject();
                    const assistantResponse = await this.assistant.updateAssistant(this.assistantId, assistant);

                    if (assistantResponse.status !== 200) {
                        this.alert.showError('Error', assistantResponse.message);
                        return;
                    }

                    const assistantsResponse = await this.assistant.fetchAssistants('');
                    if (assistantsResponse.status !== 200) {
                        this.alert.showError('Error', assistantsResponse.message);
                        return;
                    }

                    const editedAssistantResponse = await this.assistant.fetchAssistant(this.assistantId);

                    if (editedAssistantResponse.status !== 200) {
                        this.alert.showError('Error', editedAssistantResponse.message);
                        return;
                    }

                    this.closeDialog();
                    this.alert.close();
                    this.assistant.setAssistantsResponse(assistantsResponse.body);
                    this.assistant.setSelectedAssistantResponse(editedAssistantResponse.body);
                    this.alert.showSucess('Successfully updated!', 'Check assistant availability in Assistants.');
                }
            }
        });
    }

    closeDialog() {
        this.dialog.close();
    }

    // TODO - Try to use this methods on one place, also with create-assistant-modal component
    toggleVoices(language: LanguageModel) {
        this.addLanguageToOrder(language);
        this.setFemaleDefaultVoice(language);
    }

    addLanguageToOrder(language: LanguageModel) {
        if (language.selected) {
            if (!this.selectedLanguagesOrder.includes(language.name)) {
                this.selectedLanguagesOrder.push(language.name);
            }
        } else {
            this.selectedLanguagesOrder = this.selectedLanguagesOrder.filter(lang => lang !== language.name);
        }

        this.updateSelectedLanguages();
    }

    updateSelectedLanguages() {
        this.selectedLanguages = this.selectedLanguagesOrder.join(', ');
    }

    setFemaleDefaultVoice(language: LanguageModel) {
        if (language.selected) {
            language.selectedVoice = language.voices.length > 0 ? language.voices[0].gender : '';
        } else {
            language.selectedVoice = '';
        }
    }

    get isFormValid(): boolean {
        return this.assistantName.trim() !== '' &&
            // this.assistantRole.trim() !== '' &&
            this.selectedLanguagesOrder.length > 0;
    }

    createAssistantObject(): AgentModel {
        const shortcuts = [];
        if (this.shortcutFirst.trim() !== '') {
            shortcuts.push({
                name: this.shortcutFirst,
            });
        }

        if (this.shortcutSecond.trim() !== '') {
            shortcuts.push({
                name: this.shortcutSecond
            });
        }

        if (this.shortcutThird.trim() !== '') {
            shortcuts.push({
                name: this.shortcutThird
            });
        }

        if (this.shortcutFourth.trim() !== '') {
            shortcuts.push({
                name: this.shortcutFourth
            });
        }

        const voices: VoiceModel[] = [];
        this.selectedLanguagesOrder.forEach(selectedLangName => {
            const selectedLang = this.languages.find(lang => lang.name === selectedLangName);
            const voice = selectedLang?.voices.find(voice => voice.gender === selectedLang?.selectedVoice);

            if (voice) {
                voices.push(voice);
            }
        });

        return {
            name: this.assistantName,
            // role: this.assistantRole,
            knowledgeScope: this.knowledgeScope,
            similarityThreshold: this.similarityThreshold / 100, // Convert to percentage
            communicationStyle: this.communicationStyle,
            behavior: this.behavior,
            answerLength: this.answerLengthThreshold,
            voices: voices,
            shortcuts: shortcuts
        } as AgentModel;
    }

    resetLangs() {
        this.languages.forEach(language => {
            language.selected = false;
            language.selectedVoice = '';
        });
    }

    ngOnDestroy(): void {
        this.resetLangs();
    }
}
