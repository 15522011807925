import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';

import { RouterService } from "../../core/services/router.service";
import { AuthService } from "../../core/services/auth.service";

export const AuthGuard: CanActivateFn = async (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const auth = inject(AuthService);
    const router = inject(RouterService);

    if (await auth.isLogged()) {
        return true;
    } else {
        router.goToLogin();
        return false;
    }
};


