import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import Dropzone from "dropzone";

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss'
})
export class FileUploadComponent implements AfterViewInit, OnDestroy {
    @ViewChild('fileupload') fileUpload!: ElementRef;
    @Output() uploadedDropzone: EventEmitter<File[]> = new EventEmitter<File[]>();

    attachmentDropImage: string = 'assets/icons/drop.png';
    fileLabel: string = 'Add File'
    dropzone: any;

    constructor() { }

    ngAfterViewInit(): void {
        this.initializeDropzone();
    }

    // TODO think about how to handle multiple dropzones
    // Dropzone service is probably a better place to handle this
    initializeDropzone() {
        Dropzone.autoDiscover = false;

        // TODO now is not supported csv, pptx, xlsx, mp4
        //acceptedFiles: '.txt,.pdf,.csv,.docx,.pptx,.mp4',
        if (this.fileUpload) {
            this.dropzone = new Dropzone("#fileupload", {
                url: 'uploadSource',
                paramName: "knowledgeFile",
                acceptedFiles: '.txt,.pdf,.docx,.pptx',
                maxFilesize: 50, // MB
                addRemoveLinks: true,
                autoProcessQueue: false, // TODO Auto upload files
                maxFiles: 1
            });

            this.dropzone.on("addedfile", (file: File) => {
                this.uploadedDropzone.emit(this.dropzone);
                const fileExtension = file.name.split('.').pop()?.toLowerCase();
                console.log('File extension:', fileExtension);

                let thumbnailUrl = '';
                if (fileExtension === 'docx') {
                    thumbnailUrl = 'assets/images/docx-thumbnail.png';
                } else if (fileExtension === 'pptx') {
                    thumbnailUrl = 'assets/images/pptx-thumbnail.png';
                } else if (fileExtension === 'pdf') {
                    thumbnailUrl = 'assets/images/pdf-thumbnail.png';
                } else if (fileExtension === 'csv') {
                    thumbnailUrl = 'assets/images/csv-thumbnail.png';
                } else if (fileExtension === 'txt') {
                    thumbnailUrl = 'assets/images/txt-thumbnail.png';
                }

                if (thumbnailUrl) {
                    this.dropzone.emit('thumbnail', file, thumbnailUrl);
                }
            });
            this.dropzone.on("removedfile", (file: File) => {
                this.uploadedDropzone.emit(this.dropzone);
            });
        }
    }

    resetDropzone() {
        this.dropzone.removeAllFiles();
    }

    ngOnDestroy(): void {
        this.resetDropzone();
    }
}
