import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable } from "rxjs";

import { ConfigService } from "./config.service";
import { RestService } from "./rest.service";
import { AgentModel } from "../models/agent/agent.model";
import { ErrorService } from "./error.service";

@Injectable({
    providedIn: 'root'
})
export class AssistantService {
    private assistants = new BehaviorSubject<AgentModel[] | null>(null);
    private selectedAssistant = new BehaviorSubject<AgentModel | null>(null);

    constructor(private rest: RestService, private config: ConfigService, private error: ErrorService) { }

    getAssistantsResponse(): Observable<AgentModel[] | null> {
        return this.assistants.asObservable();
    }

    getSelectedAssistantResponse(): Observable<AgentModel | null> {
        return this.selectedAssistant.asObservable();
    }

    setAssistantsResponse(assistants: AgentModel[] | null) {
        this.assistants.next(assistants);
    }

    setSelectedAssistantResponse(assistant: AgentModel | null) {
        this.selectedAssistant.next(assistant);
    }

    async fetchAssistants(search: string) {
        const url = `${this.config.getUrl()}/agent/all?search=${search}`;

        try {
            const response = await lastValueFrom(
                this.rest
                    .setHost(url)
                    .setMethod("GET")
                    .setHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.config.getToken()}`
                    })
                    .create<AgentModel[]>()
            );

            return {
                status: response.status,
                message: response.message,
                body: response.body
            }
        } catch (e: any) {
            return this.error.setError(e, []);
        }
    }

    async fetchAssistant(id: string) {
        const url = `${this.config.getUrl()}/agent/${id}`;

        try {
            const response = await lastValueFrom(
                this.rest
                    .setHost(url)
                    .setMethod("GET")
                    .setHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.config.getToken()}`
                    })
                    .create<AgentModel>()
            );

            return {
                status: response.status,
                message: response.message,
                body: response.body
            }
        } catch (e: any) {
            return this.error.setError(e, {} as AgentModel);
        }
    }

    async createAssistant(assistant: AgentModel) {
        const url = `${this.config.getUrl()}/agent/create`;

        try {
            const response = await lastValueFrom(
                this.rest
                    .setHost(url)
                    .setMethod("POST")
                    .setHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.config.getToken()}`
                    })
                    .setContent(assistant)
                    .create<AgentModel>()
            );

            return {
                status: response.status,
                message: response.message,
                body: response.body
            };
        } catch (e: any) {
            return this.error.setError(e, {} as AgentModel);
        }
    }

    async updateAssistant(assistantId: string, assistant: AgentModel) {
        const url = `${this.config.getUrl()}/agent/${assistantId}/update`;

        try {
            const response = await lastValueFrom(
                this.rest
                    .setHost(url)
                    .setMethod("POST")
                    .setHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.config.getToken()}`
                    })
                    .setContent(assistant)
                    .create<AgentModel>()
            );

            return {
                status: response.status,
                message: response.message,
                body: response.body
            };
        } catch (e: any) {
            return this.error.setError(e, {} as AgentModel);
        }
    }

    async updateAssistantConfiguration(assistantId: string, assistantConfiguration: any) {
        const url = `${this.config.getUrl()}/agent/${assistantId}/update`;

        try {
            const response = await lastValueFrom(
                this.rest
                .setHost(url)
                .setMethod("PUT")
                .setHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.config.getToken()}`
                })
                .setContent(assistantConfiguration)
                .create<any>()
            );

            return {
                status: response.status,
                message: response.message,
                body: response.body
            };
        } catch (e: any) {
            return this.error.setError(e, {} as any);
        }
    }

    async deleteAssistant(assistantId: string) {
        const url = `${this.config.getUrl()}/agent/${assistantId}/delete`;

        try {
            const response = await lastValueFrom(
                this.rest
                .setHost(url)
                .setMethod("DELETE")
                .setHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.config.getToken()}`
                })
                .create()
            );

            return {
                status: response.status,
                message: response.message
            }
        } catch (e: any) {
            return this.error.setError(e, null);
        }
    }
}
