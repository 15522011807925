import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from "@angular/material/dialog";

import { DialogService } from "../../../core/services/dialog.service";
import { KnowledgeTripletEntityModel, KnowledgeTripletModel } from "../../../core/models/knowledge/knowledge.model";
import { AlertService } from "../../../core/services/alert.service";

@Component({
    selector: 'app-triplet-modal',
    templateUrl: './triplet-modal.component.html',
    styleUrl: './triplet-modal.component.scss'
})
export class TripletModalComponent implements OnInit, OnDestroy {
    dialogTitle: string = 'Add new Triplet';
    startLabel: string = 'Start Entity';
    relationLabel: string = 'Relation between entities';
    endLabel: string = 'End Entity';
    addButton: string = 'Add';

    startEntity: KnowledgeTripletEntityModel | null = null;
    relationship: string | null = null;
    endEntity: KnowledgeTripletEntityModel | null = null;

    dropdownStartEntityOpen = false;
    dropdownEndEntityOpen = false;
    dropdownRelationOpen = false;

    entities: KnowledgeTripletEntityModel[] = [];
    relationships: string[] = [];

    filteredEntitiesForStart: KnowledgeTripletEntityModel[] = [];
    filteredEntitiesForEnd: KnowledgeTripletEntityModel[] = [];

    constructor(private dialog: DialogService, private alert: AlertService,
                @Inject(MAT_DIALOG_DATA) public data: KnowledgeTripletModel | null, // Take triplet datas from parent dialog
                private dialogRef: MatDialogRef<TripletModalComponent>) {}

    async ngOnInit() {
        await this.loadTripletComponents();

        if (this.data) {
            this.dialogTitle = 'Edit Triplet';
            this.addButton = 'Save';
            this.startEntity = this.data.startEntity;
            this.relationship = this.data.relationship;
            this.endEntity = this.data.endEntity;

            this.entities.push(this.startEntity, this.endEntity);
            this.relationships.push(this.relationship);
            this.filteredEntitiesForStart = this.getFilteredStartEntities();
            this.filteredEntitiesForEnd = this.getFilteredEndEntities();
        }
    }

    async loadTripletComponents() {
        /*
        this.alert.showLoading()
        const response = await this.knowledge.getTripletComponents();
        this.entities = response.body.entities;
        this.relationships = response.body.relationships;

        // Init filtered lists with all entities
        this.filteredEntitiesForStart = [...this.entities];
        this.filteredEntitiesForEnd = [...this.entities];
        */
    }

    /*searchStartEntity: string = '';
    entitiesForStart: KnowledgeTripletEntityModel[] = [];

    filterEntitiesForStart() {
        if (this.searchStartEntity) {
            this.filteredEntitiesForStart = this.entitiesForStart.filter(entity =>
                entity.name.toLowerCase().includes(this.searchStartEntity.toLowerCase())
            );
        } else {
            this.filteredEntitiesForStart = [...this.entitiesForStart]; // Vratite sve entitete kada je pretraga prazna
        }
    }*/

    async addTriplet() {
        const triplet = {
            startEntity: this.startEntity,
            relationship: this.relationship,
            endEntity: this.endEntity
        }

        this.dialogRef.close(triplet); // Send back datas to the parent dialog
    }

    openAddEntityDialog(entityType: string) {
        this.dialog.openCreateEntityDialog(entityType).afterClosed().subscribe((result) => {
            if (result) {
                this.entities.push(result);
                this.filteredEntitiesForStart = this.getFilteredStartEntities();
                this.filteredEntitiesForEnd = this.getFilteredEndEntities();
                if (entityType === 'start') {
                    this.selectStartEntity(result, new MouseEvent('click'));
                } else {
                    this.selectEndEntity(result, new MouseEvent('click'));
                }
            }
        });

        console.log(this.entities);
    }

    openAddRelationDialog() {
        this.dialog.openCreateRelationDialog().afterClosed().subscribe((result) => {
            if (result) {
                this.relationships.push(result);
                this.selectRelation(result, new MouseEvent('click'));
            }
        });

        console.log(this.relationships);
    }

    // TODO refactoring needed, also html parts
    toggleEditStartEntity() {
        this.dropdownStartEntityOpen = true; }
    closeStartEntityDropdown() { this.dropdownStartEntityOpen = false; }
    toggleEditRelation() { this.dropdownRelationOpen = !this.dropdownRelationOpen; }
    closeRelationshipDropdown() { this.dropdownRelationOpen = false; }
    toggleEditEndEntity() { this.dropdownEndEntityOpen = !this.dropdownEndEntityOpen; }
    closeEndEntityDropdown() { this.dropdownEndEntityOpen = false; }

    selectStartEntity(entity: KnowledgeTripletEntityModel, event: MouseEvent) {
        event.stopPropagation();
        this.startEntity = entity;
        //this.searchStartEntity = entity.name;

        this.dropdownStartEntityOpen = false;
        // Update filtered list for End Entity
        this.filteredEntitiesForEnd = this.getFilteredEndEntities();
    }

    /*selectStartEntity(entity: EntityModel, event: Event) {
        event.stopPropagation(); // Sprečava da se klik na stavku zatvori dropdown
        this.startEntity = entity; // Postavite izabrani entitet
        this.searchStartEntity = entity.name; // Popunite input polje sa imenom izabranog entiteta
        this.dropdownStartEntityOpen = false; // Zatvorite dropdown
    }*/

    selectRelation(relationship: string, event: MouseEvent) {
        event.stopPropagation();
        this.relationship = relationship;
        this.dropdownRelationOpen = false;
    }

    selectEndEntity(entity: KnowledgeTripletEntityModel, event: MouseEvent) {
        event.stopPropagation();
        this.endEntity = entity;
        this.dropdownEndEntityOpen = false;
        // Update filtered list for Start Entity
        this.filteredEntitiesForStart = this.getFilteredStartEntities();
    }

    closeDialog() {
        if (this.dialogTitle === 'Add new Triplet') {
            if (this.startEntity || this.relationship || this.endEntity) {
                this.alert.showSaveChanges(
                    'Warning',
                    'You have entered triplet info, data will be lost if you close the dialog!',
                    'Close'
                ).then((result: { isConfirmed: any; }) => {
                    if (result.isConfirmed) {
                        this.dialog.closeNestedDialog(this.dialogRef);
                    }
                });
                return;
            }
        }

        this.dialog.closeNestedDialog(this.dialogRef);
    }

    get isFormValid(): boolean {
        return this.startEntity !== null && this.relationship !== null && this.endEntity !== null;
    }

    resetListValues() {
        this.startEntity = null;
        this.relationship = null;
        this.endEntity = null;
        this.filteredEntitiesForStart = [...this.entities];
        this.filteredEntitiesForEnd = [...this.entities];
    }

    // TODO filter entities list for start and end entities
    getFilteredStartEntities(): KnowledgeTripletEntityModel[] {
        return this.entities.filter(entity => !this.endEntity || entity !== this.endEntity);
    }

    getFilteredEndEntities(): KnowledgeTripletEntityModel[] {
        return this.entities.filter(entity => !this.startEntity || entity !== this.startEntity);
    }

    ngOnDestroy(): void {
        this.resetListValues();
    }
}
