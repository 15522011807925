<div class="box-search">
    <div class="section-actions">
        <div class="search-container">
            <img [src]="loupeImage" alt="Loupe icon">
            <input type="text" name="search-placeholder" id="id-search-project"
                   [placeholder]="searchPlaceholder" [(ngModel)]="searchValue"
                   (keyup.enter)="search()" (input)="search()" [disabled]="disabled" />
            <button *ngIf="searchValue" id="id-remove-search" type="button" class="remove-search-button" (click)="removeSearch()">
                <app-close width="12"></app-close>
            </button>
        </div>
        <button id="id-create-option" type="button" class="create-option-button" (click)="create()">
            <app-plus></app-plus>
            <span>{{ createButton }}</span>
        </button>
    </div>
</div>
