import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";

import { AlertService } from "../../../core/services/alert.service";
import { DialogService } from "../../../core/services/dialog.service";
import { AssistantService } from "../../../core/services/assistant.service";
import { AgentModel } from "../../../core/models/agent/agent.model";
import { EventService } from "../../../core/services/event.service";
import { ConfigService } from "../../../core/services/config.service";

@Component({
    selector: 'app-select-assistant-modal',
    templateUrl: './select-assistant-modal.component.html',
    styleUrl: './select-assistant-modal.component.scss'
})
export class SelectAssistantModalComponent implements OnInit, OnDestroy {
    dialogTitle = 'Select Assistant';
    checkImage: string = 'assets/icons/checked.svg';
    hoverImage: string = 'assets/icons/hoverAssistant.svg';

    noSearchResultSection: string[] = [
        `No search results found!`,
        `Try adjusting your search criteria or create new assistant if it doesn't exist.`
    ]

    assistants: AgentModel[] = [];
    selectedAssistant: AgentModel | null = null;
    selectedAssistantIndex: number | null = null;
    searchValue: string = '';

    private assistantsSubscription!: Subscription;
    private selectedAssistantSubscription!: Subscription;
    private searchValueSubscription!: Subscription;

    @HostListener('document:keydown.escape', ['$event'])
    handleEscape(event: KeyboardEvent) {
        this.closeDialog();
    }

    constructor(
        private alert: AlertService,
        private event: EventService,
        private dialog: DialogService,
        private assistant: AssistantService,
        private config: ConfigService) {
    }

    async ngOnInit() {
        this.alert.showLoading();
        this.event.setContentFromNavbarOption({ search: 'Type here to search assistants...', create: 'Create new assistant' });

        await this.fetchRequirements();
        await this.loadAssistantList();

        this.assistantsSubscription = this.assistant.getAssistantsResponse().subscribe(assistants => {
            this.assistants = assistants ?? [];
            this.selectedAssistant = null;
            this.selectedAssistantIndex = null;
        });

        this.selectedAssistantSubscription = this.assistant.getSelectedAssistantResponse().subscribe(selectedAssistant => {
            this.selectedAssistant = selectedAssistant;

            this.selectedAssistantIndex = this.assistants.findIndex(
                assistant => assistant.id === selectedAssistant?.id
            );
        });

        this.searchValueSubscription = this.event.getSearchValue().subscribe(search => {
            if (search != null) {
                this.searchValue = search;
            }
        })
    }

    async fetchRequirements() {
        await this.config.fetchRequirements();
    }

    async loadAssistantList() {
        const response = await this.assistant.fetchAssistants(this.searchValue);

        if (response.status !== 200) {
            this.alert.showError('Error', response.message);
            return;
        }

        this.alert.close();
        this.assistant.setAssistantsResponse(response.body);
    }

    async selectAssistant(index: number, assistant: AgentModel) {
        this.selectedAssistant = assistant;
        this.selectedAssistantIndex = index;

        if (this.selectedAssistant && this.selectedAssistant.id) {
            this.alert.showLoading();
            const {status, message, body} = await this.assistant.fetchAssistant(this.selectedAssistant.id);

            if (status !== 200) {
                this.alert.showError('Error', message);
                return;
            }

            this.closeDialog();
            this.alert.close();
            this.assistant.setSelectedAssistantResponse(body);
        }
    }

    closeDialog() {
        this.dialog.close();
    }

    isAssistantListEmpty(): boolean {
        return !(this.assistants && this.assistants.length > 0);
    }

    ngOnDestroy() {
        if (this.assistantsSubscription) { this.assistantsSubscription.unsubscribe(); }
        if (this.selectedAssistantSubscription) { this.selectedAssistantSubscription.unsubscribe(); }
        if (this.searchValueSubscription) { this.searchValueSubscription.unsubscribe(); }
    }
}
