import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-menu',
    template: `
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M5.96875 8C5.96875 9.12183 6.87817 10.0312 8 10.0312C9.12183 10.0312 10.0312 9.12183 10.0312 8C10.0312 6.87817 9.12183 5.96875 8 5.96875C6.87817 5.96875 5.96875 6.87817 5.96875 8Z" [attr.fill]="stroke"/>
            <path d="M5.96875 13.9687C5.96875 15.0906 6.87817 16 8 16C9.12183 16 10.0312 15.0906 10.0312 13.9688C10.0312 12.8469 9.12183 11.9375 8 11.9375C6.87817 11.9375 5.96875 12.8469 5.96875 13.9687Z" [attr.fill]="stroke"/>
            <path d="M5.96875 2.03125C5.96875 3.15308 6.87817 4.0625 8 4.0625C9.12183 4.0625 10.0312 3.15308 10.0312 2.03125C10.0312 0.909422 9.12183 -3.97521e-08 8 -8.87888e-08C6.87817 -1.37825e-07 5.96875 0.909421 5.96875 2.03125Z" [attr.fill]="stroke"/>
        </svg>
    `,
    styles: [`
        :host {
            --menu-color: #3B4754;
        }
        svg path {
            fill: var(--menu-color);
        }
    `]
})
export class MenuComponent {
    @Input() stroke: string = 'var(--menu-color)';
}
