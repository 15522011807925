import { ActionModel } from "../../../models/action/action.model";

export const DUMMY_ACTIONS: ActionModel[] = [
    {
        id: 'action-1',
        name: 'CreateUser',
        description: 'Creates a new user in the system',
        statusCheck: true,
        actionUrl: '/api/user/create',
        actionMethod: 'POST',
        headers: [
            { id: 'header-1', headerKey: 'Content-Type', headerValue: 'application/json' }
        ],
        parameters: [
            {
                id: 'param-1',
                name: 'username',
                description: 'The username of the new user',
                parameterType: 'string',
                parameterValues: [{ parameterValue: '' }]
            },
            {
                id: 'param-2',
                name: 'email',
                description: 'The email address of the new user',
                parameterType: 'string',
                parameterValues: [{ parameterValue: '' }]
            }
        ]
    },
    {
        id: 'action-2',
        name: 'DeleteUser',
        description: 'Deletes an existing user',
        statusCheck: false,
        actionUrl: '/api/user/delete',
        actionMethod: 'GET',
        headers: [
            { id: 'header-2', headerKey: 'Authorization', headerValue: 'Bearer token' }
        ],
        parameters: [
            {
                id: 'param-3',
                name: 'userId',
                description: 'ID of the user to delete',
                parameterType: 'string',
                parameterValues: [{ parameterValue: '' }]
            }
        ]
    },
    {
        id: 'action-3',
        name: 'UpdateUser',
        description: 'Updates user details',
        statusCheck: true,
        actionUrl: '/api/user/update',
        actionMethod: 'POST',
        headers: [
            { id: 'header-3', headerKey: 'Content-Type', headerValue: 'application/json' }
        ],
        parameters: [
            {
                id: 'param-4',
                name: 'userId',
                description: 'ID of the user to update',
                parameterType: 'string',
                parameterValues: [{ parameterValue: '' }]
            },
            {
                id: 'param-5',
                name: 'email',
                description: 'New email address',
                parameterType: 'string',
                parameterValues: [{ parameterValue: '' }]
            }
        ]
    },
    {
        id: 'action-4',
        name: 'SendEmail',
        description: 'Sends an email to the user',
        statusCheck: false,
        actionUrl: '/api/email/send',
        actionMethod: 'DELETE',
        headers: [
            { id: 'header-4', headerKey: 'Authorization', headerValue: 'Bearer token' }
        ],
        parameters: [
            {
                id: 'param-6',
                name: 'recipient',
                description: 'The recipient email address',
                parameterType: 'string',
                parameterValues: [{ parameterValue: '' }]
            },
            {
                id: 'param-7',
                name: 'subject',
                description: 'The subject of the email',
                parameterType: 'string',
                parameterValues: [{ parameterValue: '' }]
            }
        ]
    },
    {
        id: 'action-5',
        name: 'ResetPassword',
        description: 'Resets a user\'s password',
        statusCheck: true,
        actionUrl: '/api/password/reset',
        actionMethod: 'POST',
        headers: [
            { id: 'header-5', headerKey: 'Authorization', headerValue: 'Bearer token' }
        ],
        parameters: [
            {
                id: 'param-8',
                name: 'userId',
                description: 'ID of the user whose password is being reset',
                parameterType: 'string',
                parameterValues: [{ parameterValue: '' }]
            }
        ]
    },
    {
        id: 'action-6',
        name: 'FetchUserProfile',
        description: 'Fetches the user profile by ID',
        statusCheck: false,
        actionUrl: '/api/user/profile',
        actionMethod: 'GET',
        headers: [
            { id: 'header-6', headerKey: 'Authorization', headerValue: 'Bearer token' }
        ],
        parameters: [
            {
                id: 'param-9',
                name: 'userId',
                description: 'ID of the user whose profile is being fetched',
                parameterType: 'string',
                parameterValues: [{ parameterValue: '' }]
            }
        ]
    },
    {
        id: 'action-7',
        name: 'ActivateAccount',
        description: 'Activates a new user account',
        statusCheck: true,
        actionUrl: '/api/account/activate',
        actionMethod: 'POST',
        headers: [
            { id: 'header-7', headerKey: 'Authorization', headerValue: 'Bearer token' }
        ],
        parameters: [
            {
                id: 'param-10',
                name: 'activationCode',
                description: 'Activation code for the account',
                parameterType: 'string',
                parameterValues: [{ parameterValue: '' }]
            }
        ]
    },
    {
        id: 'action-8',
        name: 'DeactivateAccount',
        description: 'Deactivates an existing account',
        statusCheck: true,
        actionUrl: '/api/account/deactivate',
        actionMethod: 'GET',
        headers: [
            { id: 'header-8', headerKey: 'Authorization', headerValue: 'Bearer token' }
        ],
        parameters: [
            {
                id: 'param-11',
                name: 'userId',
                description: 'ID of the account to deactivate',
                parameterType: 'string',
                parameterValues: [{ parameterValue: '' }]
            }
        ]
    },
    {
        id: 'action-9',
        name: 'UploadFile',
        description: 'Uploads a file to the server',
        statusCheck: false,
        actionMethod: 'POST',
        actionUrl: '/api/file/upload',
        headers: [
            { id: 'header-9', headerKey: 'Content-Type', headerValue: 'multipart/form-data' }
        ],
        parameters: [
            {
                id: 'param-12',
                name: 'file',
                description: 'File to upload',
                parameterType: 'file',
                parameterValues: []
            }
        ]
    },
    {
        id: 'action-10',
        name: 'DownloadFile',
        description: 'Downloads a file from the server',
        statusCheck: true,
        actionMethod: 'POST',
        actionUrl: '/api/file/download',
        headers: [
            { id: 'header-10', headerKey: 'Authorization', headerValue: 'Bearer token' }
        ],
        parameters: [
            {
                id: 'param-13',
                name: 'fileId',
                description: 'ID of the file to download',
                parameterType: 'string',
                parameterValues: [{ parameterValue: '' }]
            }
        ]
    },
    {
        id: 'action-11',
        name: 'GenerateReport',
        description: 'Generates a user report',
        statusCheck: true,
        actionMethod: 'POST',
        actionUrl: '/api/report/generate',
        headers: [
            { id: 'header-11', headerKey: 'Authorization', headerValue: 'Bearer token' }
        ],
        parameters: [
            {
                id: 'param-14',
                name: 'reportType',
                description: 'The type of report to generate',
                parameterType: 'string',
                parameterValues: [
                    { parameterValue: 'daily' },
                    { parameterValue: 'weekly' },
                    { parameterValue: 'monthly' }
                ]
            }
        ]
    },
    {
        id: 'action-12',
        name: 'CancelOrder',
        actionMethod: 'POST',
        description: 'Cancels a user order',
        statusCheck: false,
        actionUrl: '/api/order/cancel',
        headers: [
            { id: 'header-12', headerKey: 'Authorization', headerValue: 'Bearer token' }
        ],
        parameters: [
            {
                id: 'param-15',
                name: 'orderId',
                description: 'ID of the order to cancel',
                parameterType: 'string',
                parameterValues: [{ parameterValue: '' }]
            }
        ]
    },
    {
        id: 'action-13',
        name: 'CheckOrderStatus',
        description: 'Checks the status of a user order',
        statusCheck: true,
        actionMethod: 'POST',
        actionUrl: '/api/order/status',
        headers: [
            { id: 'header-13', headerKey: 'Authorization', headerValue: 'Bearer token' }
        ],
        parameters: [
            {
                id: 'param-16',
                name: 'orderId',
                description: 'ID of the order to check',
                parameterType: 'string',
                parameterValues: [{ parameterValue: '' }]
            }
        ]
    },
    {
        id: 'action-14',
        name: 'SendNotification',
        description: 'Sends a notification to a user',
        statusCheck: true,
        actionMethod: 'GET',
        actionUrl: '/api/notification/send',
        headers: [
            { id: 'header-14', headerKey: 'Authorization', headerValue: 'Bearer token' }
        ],
        parameters: [
            {
                id: 'param-17',
                name: 'message',
                description: 'Notification message to send',
                parameterType: 'string',
                parameterValues: [{ parameterValue: '' }]
            }
        ]
    },
    {
        id: 'action-15',
        name: 'VerifyPayment',
        description: 'Verifies a user payment',
        statusCheck: true,
        actionMethod: 'POST',
        actionUrl: '/api/payment/verify',
        headers: [
            { id: 'header-15', headerKey: 'Authorization', headerValue: 'Bearer token' }
        ],
        parameters: [
            {
                id: 'param-18',
                name: 'paymentId',
                description: 'ID of the payment to verify',
                parameterType: 'string',
                parameterValues: [{ parameterValue: '' }]
            }
        ]
    }
];
