<div class="parent-container" (keyup.escape)="closeDialog()">
    <div class="dialog-header">
        <h2>{{ dialogTitle }}</h2>
        <button id="id-close-entity-dialog" type="button" class="close-button" (click)="closeDialog()">
            <app-close></app-close>
        </button>
    </div>
    <mat-dialog-content>
        <div class="dialog-container">
            <div>
                <div class="form-container">
                    <label>{{ entityNameLabel }}</label>
                    <div class="single-container">
                        <input type="text" id="id-entity-name" name="entity-name" [(ngModel)]="entityName" [placeholder]="enterEntityNameLabel"/>
                    </div>
                </div>
                <div class="form-container">
                    <label>{{ entityTypeLabel }}</label>
                    <div class="single-container">
                        <input type="text" id="id-entity-type" name="entity-type" [(ngModel)]="entityType" [placeholder]="enterEntityTypeLabel"/>
                    </div>
                </div>
                <div class="form-container">
                    <label>{{ newPropertyLabel }}</label>
                    <div class="property-container"
                         *ngFor="let property of properties; let i = index"
                         [ngClass]="{'hover-active': i !== 0}"
                    >
                        <div class="single-container">
                            <input type="text" id="id-property-name-{{i}}" name="property-name-{{i}}"
                                   [(ngModel)]="property.name" [placeholder]="enterPropertyNameLabel" />
                        </div>
                        <div class="single-container">
                            <input type="text" id="id-property-value-{{i}}" name="property-value-{{i}}"
                                   [(ngModel)]="property.value" [placeholder]="enterPropertyValueLabel" />
                        </div>
                        <div class="delete-container" *ngIf="i !== 0">
                            <button type="button" class="delete-button" (click)="removeProperty(i)">
                                <app-delete></app-delete>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <button id="id-create-property-button" type="button" (click)="addProperty()" class="property-button" [disabled]="!checkPropertyFilled">
                <app-plus></app-plus>
                <span>{{ propertyButton }}</span>
            </button>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="dialog-footer">
        <button id="id-create-entity-button" type="button" (click)="addEntity()" class="create-button" [disabled]="!isFormValid">{{ saveButton }}</button>
    </mat-dialog-actions>
</div>
