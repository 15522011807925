<div class="parent-container" (keyup.escape)="closeDialog()">
    <button id="id-close-entity-dialog" type="button" class="close-button" (click)="closeDialog()">
        <app-close></app-close>
    </button>
    <img *ngIf="mediaType=='image'" [src]="mediaUrl" alt="File Image" />

    <iframe *ngIf="mediaType === 'pdf'" [src]="mediaUrl | safe" type="application/pdf"></iframe>

    <div *ngIf="mediaType === 'text'" class="text-container">
        <pre>{{ mediaUrl }}</pre>
    </div>

    <div *ngIf="mediaType === 'word'" class="word-container">
        <div class="docx-content" [innerHTML]="fileContent"></div>
    </div>


    <div *ngIf="mediaType=='video'" class="video-container">
        <video #videoModal *ngIf="mediaType=='video'" [src]="mediaUrl" playsinline controls></video>
    </div>

    <div *ngIf="mediaType === 'unsupported'" class="unsupported-container">
        <p>Unsupported file type: {{ data?.name }}</p>
    </div>
    <div *ngIf="mediaType === 'unknown'" class="unknown-container">
        <p>Unknown file type: {{ data?.name }}</p>
    </div>
</div>

