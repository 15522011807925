<div class="box-login">
    <div class="login-container">
        <div class="login-auth-wrapper">
            <div class="login-welcome-side">
                <div class="login-header">
                    <div class="company-logo">
                        <img [src]="logo" alt="Logo icon">
                    </div>
                </div>
                <div *ngIf="!isPasswordForgotten" class="login-form-container">
                    <div class="login-title">
                        <h3>{{ welcomeText }}</h3>
                        <h4>{{ loginText }}</h4>
                    </div>
                    <form action="" method="POST" class="login-auth-form">
                        <div class="login-form-group">
                            <label for="login-username">{{ usernamePlaceholder }}</label>
                            <input type="text" name="login-username" id="login-username" [placeholder]="usernamePlaceholder"
                                   [(ngModel)]="username"/>
                        </div>
                        <div class="login-form-group">
                            <label for="login-password">{{ passwordPlaceholder }}</label>
                            <input type="password" name="login-password" id="login-password" [placeholder]="passwordPlaceholder"
                                   [(ngModel)]="password" (keyup.enter)="login()"/>
                        </div>
                    </form>
                    <div class="login-footer">
                        <a id="id-forgot-password" (click)="forgotPassword()">{{ forgotPasswordText }}</a>
                        <button id="id-login" type="button" class="login-button" (click)="login()" [disabled]="!isFormFilled()">{{ loginButton }}</button>
                    </div>
                </div>
                <div *ngIf="isPasswordForgotten" class="forgot-password-form-container">
                    <div class="login-title">
                        <h3>{{ forgotTitle }}</h3>
                        <p>{{ forgotText }}</p>
                    </div>
                    <form action="" method="POST" class="login-auth-form">
                        <div class="login-form-group">
                            <label for="forgot-password-email">{{ emailPlaceholder }}</label>
                            <input type="text" name="forgot-password-email" id="forgot-password-email" [placeholder]="emailPlaceholder" [(ngModel)]="email" (keyup.enter)="sendEmail()"/>
                        </div>
                    </form>
                    <div class="login-footer">
                        <button id="id-send-email" type="button" class="login-button" (click)="sendEmail()" [disabled]="!isEmailFilled()">{{ sendButton }}</button>
                        <a id="id-sign-up" (click)="signUp()">{{ signUpButton }}</a>
                    </div>
                </div>
            </div>
            <div class="login-banner"></div>
        </div>
    </div>
</div>
