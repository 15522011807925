import { Injectable } from '@angular/core';
import { HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, lastValueFrom, Observable } from "rxjs";

import { environment } from "../../../environments/environment";
import { RestService } from "./rest.service";
import { UserModel } from "../models/user.model";
import { VoiceModel } from "../models/misc/voice.model";
import { LanguageModel } from "../models/misc/language.model";
import { DesignModel } from "../models/misc/design.model";

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    private url: string = `${this.getHttpScheme()}://${this.getHost()}/api`;
    private voices: VoiceModel[] = [];
    private languages: LanguageModel[] = [];
    private designs = new BehaviorSubject<DesignModel[]>([]);

    constructor(private rest: RestService) {}

    getAssistantDesigns(assistantId: string): Observable<DesignModel[]> {
        const updatedDesigns: DesignModel[] = [
            /*{
                id: 'design-1',
                url: environment.design1 + assistantId,
                label: 'Design 1 (Avatar and chat)'
            },
            {
                id: 'design-2',
                url: environment.design2 + assistantId,
                label: 'Design 2 (Chat)'
            },
            {
                id: 'design-3',
                url: environment.design3 + assistantId,
                label: 'Design 3 (Avatar)'
            },*/
            {
                id: 'design-4',
                url: environment.design4 + assistantId,
                label: 'Design 4 (Full page)'
            }
        ];

        this.designs.next(updatedDesigns);

        return this.designs;
    }

    public async fetchRequirements() {
        try {
            let [languages, voices] = await Promise.all([
                this.fetchLanguages(),
                this.fetchVoices()
            ]);

            if (voices.length === 0 || languages.length === 0) {
                console.error('No voices or languages found');
                return;
            }

            languages = languages.map(language => {
                language.voices = [];
                for (let voice of voices) {
                    if (voice.languageLocale === language.locale) {
                        language.voices.push(voice);
                    }
                }

                return language;
            })

            this.setVoices(voices);
            this.setLanguages(languages);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    setUser(user: UserModel) {
        localStorage.setItem("user", JSON.stringify(user));
    }

    setToken(token: string){
        localStorage.setItem("token", token);
    }

    setVoices(voices: VoiceModel[]) {
        this.voices = voices;
    }

    setLanguages(languages: LanguageModel[]) {
        this.languages = languages;
    }

    getUser(): UserModel | null {
        const user = localStorage.getItem("user");

        if (user) {
            return JSON.parse(user);
        }

        return null;
    }

    getHost () {
        return environment.apiUrl;
    }

    getOrchestrationHost () {
        return environment.orchestratorUrl;
    }

    getWssScheme () {
        return environment.wssScheme;
    }

    getHttpScheme () {
        return environment.httpScheme;
    }

    getUrl() {
        return this.url;
    }

    getLanguages () {
        return this.languages;
    }

    getVoices () {
        return this.voices;
    }

    getToken(){
        return localStorage.getItem("token");
    }

    async fetchLanguages(): Promise<LanguageModel[]> {
        const url = `${this.url}/languages`;
        try {
            const response = await lastValueFrom(
                this.rest
                    .setHost(url)
                    .setMethod("GET")
                    .setHeaders(new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Authorization' : 'Bearer ' + this.getToken()
                    }))
                    .create<LanguageModel[]>()
            );

            return response.body;
        } catch (e: any) {
            return [];
        }
    }

    async fetchVoices(): Promise<VoiceModel[]> {
        const url = `${this.url}/voices`;
        try {
            const response = await lastValueFrom(
                this.rest
                    .setHost(url)
                    .setMethod("GET")
                    .setHeaders(new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Authorization' : 'Bearer ' + this.getToken()
                    }))
                    .create<VoiceModel[]>()
            );

            return response.body;
        } catch (e: any) {
            return [];
        }
    }
}
