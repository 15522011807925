import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-plus',
    template: `
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Frame">
                <path id="Vector" d="M1 8H13" [attr.stroke]="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Vector_2" d="M7 2V14" [attr.stroke]="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
        </svg>
    `,
    styles: [`
        :host {
            --plus-color: #FBFBFB;
        }
        svg g path {
            fill: var(--plus-color);
        }
    `]
})
export class PlusComponent {
    @Input() stroke: string = 'var(--plus-color)';
}
